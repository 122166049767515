import React from 'react'
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import PartnerJobs from './PartnerJobs';
import Jobs from './Jobs';

export const UserJobs: React.FC = () => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { supplierPortalEnabled } = userState.currentOrganisation;
  // TODO: Disable this for now
  // return supplierPortalEnabled ? <PartnerJobs /> : <Jobs />;
  return <Jobs />;
}

export default UserJobs;