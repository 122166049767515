import { gql } from "@apollo/client";

export const CREATE_JOB = gql`
  mutation createUserJob($userJob: CreateUserJobInput) {
    createUserJob(userJob: $userJob) {
      id
      subCategory
      jobDate
      dateCreated
      description
      partner {
          id
          partnerName
        }
      assignedTo {
        id
        firstName
        lastName
      }
      actionsLinked {
        id
      }
      otherTaskItems
      vehicle {
        id
      }
      booking {
        id
        referenceNumber
        customer {
          id
          firstName
          lastName
          email
          phoneNumber {
            phone
          }
        }
        businessCustomer {
          id
          businessName
          contact {
            firstName
            lastName
            phoneNumber {
              phone
              country
            }
            email
          }
        }
      }
      driverType
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateUserJob($id: String!, $userJob: UpdateUserJobInput) {
    updateUserJob(id: $id, userJob: $userJob) {
      id
      subCategory
      jobDate
      dateCreated
      description
      partner {
          id
          partnerName
        }
      assignedTo {
        id
        firstName
        lastName
        email
        phoneNumber {
          phone
          country
        }
      }
      actionsLinked {
        id
      }
      otherTaskItems
      driverType
    }
  }
`;

export const CHANGE_JOB_STATUS = gql`
  mutation changeJobStatus($id: String!, $status: String!) {
    changeJobStatus(id: $id, status: $status) {
      status
    }
  }
`;

export const ADD_USER_JOB_NOTE = gql`
  mutation addJobNote($id: String!, $note: AddUserJobNote) {
    addJobNote(id: $id, note: $note) {
      id
      notes {
        note
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const CANCEL_JOB = gql`
  mutation cancelUserJob($id: String!) {
    cancelUserJob(id: $id) {
      status
    }
  }
`;
