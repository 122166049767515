import { createBrowserHistory } from "history";
import * as React from "react";
import ReactGA from "react-ga";
import { Route, Routes } from "react-router-dom";
import {
  AccessDenied,
} from "../components/hoc/Authorization";
import CustomerManagement from "../components/views/CustomerManagement";
import { BusinessCustomers } from "../components/views/CustomerManagement/Business/BusinessCustomers";
import { NewBusinessCustomer } from "../components/views/CustomerManagement/Business/NewBusinessCustomer/NewBusinessCustomer";
import { UpdateBusinessCustomer } from "../components/views/CustomerManagement/Business/UpdateBusinessCustomer/UpdateBusinessCustomer";
import { Customers } from "../components/views/CustomerManagement/Customer/Customers";
import { CustomerDetails } from "../components/views/CustomerManagement/CustomerDetails/CustomerDetails";
import Customer from "../components/views/CustomerManagement/NewCustomer/Customer";
import Dashboard from "../components/views/DashboardNew";
import FleetDashboard from "../components/views/Fleet/VehicleTracking";
import NewDamage from "../components/views/Fleet/Issues&Damages/NewDamage";
import { VehicleDamages } from "../components/views/Fleet/Issues&Damages/VehicleDamages";
import Renewals from "../components/views/Fleet/Renewals";
import NewServicesAndRepair from "../components/views/Fleet/ServicesAndRepair/NewServicesAndRepair";
import ServicesAndRepair from "../components/views/Fleet/ServicesAndRepair/ServicesAndRepair";
import VehicleInventory from "../components/views/Fleet/VehicleInventory/VehicleInventory";
import Vehicle from "../components/views/Fleet/VehicleInventory/ViewVehicle/Vehicle";
import { VehiclePriceGroup } from "../components/views/Fleet/VehiclePriceGroups/VehiclePriceGroup";
import { VehiclePriceGroups } from "../components/views/Fleet/VehiclePriceGroups/VehiclePriceGroups";
import VehicleScheduler from "../components/views/Fleet/VehicleScheduler";
import VehicleStatus from "../components/views/Fleet/VehicleStatus";
import Invoices from "../components/views/Invoices/Invoices";
import NewSundryInvoice from "../components/views/Invoices/SundryInvoice/NewSundryInvoice";
import UpdateSundryInvoice from "../components/views/Invoices/SundryInvoice/UpdateSundryInvoice";
import UpdateInvoice from "../components/views/Invoices/UpdateInvoice/UpdateInvoice";
import Reports from "../components/views/Reports";
import { Bookings } from "../components/views/ReservationManagement/Booking/Bookings";
import NewBooking from "../components/views/ReservationManagement/NewBooking/NewBooking";
import ViewBooking from "../components/views/ReservationManagement/Summary/ViewBooking";
import { CollectionChecks } from "../components/views/ReservationManagement/VehicleTransaction/VehicleChecks/CollectionChecks";
import { DeliveryChecks } from "../components/views/ReservationManagement/VehicleTransaction/VehicleChecks/DeliveryChecks";
import { AccountSettings } from "../components/views/Settings/GeneralSettings/AccountSettings/AccountSettings";
import Addons from "../components/views/Settings/GeneralSettings/Addons/Addons";
import AddonsTable from "../components/views/Settings/GeneralSettings/Addons/AddonsTable";
import { AdminUsers } from "../components/views/Settings/GeneralSettings/AdminUsers/AdminUsers";
import { NewAdminUser } from "../components/views/Settings/GeneralSettings/AdminUsers/NewAdminUser/NewAdminUser";
import { ChargebeeSubscriptions } from "../components/views/Settings/GeneralSettings/ChargebeeSubscriptions/ChargebeeSubscriptions";
import { GeneralSettings } from "../components/views/Settings/GeneralSettings/GeneralSettings";
import InsuranceSettings from "../components/views/Settings/GeneralSettings/InsuranceSettings/InsuranceSettings";
import NewInsurance from "../components/views/Settings/GeneralSettings/InsuranceSettings/NewInsurance";
import NewOrganisationStructureTab from "../components/views/Settings/GeneralSettings/OrganisationStructure/NewOrganisationStructureTab";
import { PaymentConfiguration } from "../components/views/Settings/GeneralSettings/PaymentConfiguration/PaymentConfiguration";
import { Integrations } from "../components/views/Settings/GeneralSettings/Integrations/Integration";
import { PriceRules } from "../components/views/Settings/Pricing/PriceRuleCreation/PriceRules";
import WebsiteSetup from "../components/views/AppSettings/NewAppSetting/AppSetting";
import ConsumerWebsites from "../components/views/AppSettings/Apps";
import NewPriceRule from "../components/views/Settings/Pricing/PriceRuleCreation/CreateNewPriceRule";
import RentalAgreementSettings from "../components/views/Settings/GeneralSettings/RentalAgreementSettings/RentalAgreementSettings";
import OrganisationStructure from "../components/views/Settings/GeneralSettings/OrganisationStructure/OrganisationStructure";
import { NewVehicleCheckList } from "../components/views/Fleet/VehicleCheckList/NewVehicleCheckList";
import VehicleMovement from "../components/views/VehicleMovement"
import BranchConnection from '../components/views/VehicleMovement/BranchConnection';
import MovementLifecycle from '../components/views/VehicleMovement/MovementLifecycle';
import { ShareFleetVehicleState } from "../components/common/VehicleState/VehicleState";
import { FleetTranser } from "../components/views/FleetTransfer/FleetTranser";
import StripePayment from "../components/views/ReservationManagement/NewBooking/PaymentView/Stripe/StripePayment";
import DocumentTypes from '../components/views/Settings/GeneralSettings/Documents';
import { Procurements } from "../components/views/Procurements/Procurements";
import { CreateNewProcurement } from "../components/views/Procurements/NewProcurement";
import { ReviewProcurementRequestTab } from "../components/views/Procurements/ReviewProcurementRequestTab";
import { Partners } from "../components/views/Partners/Partners";
import { Partner } from "../components/views/Partners/Partner";
import { SmartCarOnboarding } from "../components/views/Fleet/Smartcar";
import NewSubscriptionPlan from "../components/views/Settings/Pricing/SubscriptionPlans/NewSubscriptionPlan";
import Subscriptions from "../components/views/Settings/Pricing/SubscriptionPlans/Subscriptions";
import GhostVehicle from "../components/views/Fleet/VehicleInventory/ViewVehicle/GhostVehicle";
import { FuelExpenses } from "../components/views/Expenses/Expenses";
import NewFuelExpense from "../components/views/Expenses/NewExpense";
import ServiceTaskItems from "../components/views/Settings/GeneralSettings/ServiceTaskItems/ServiceTaskItems";
import { Roles } from "../components/views/Settings/GeneralSettings/RolesAndPermission/Roles";
import { NewRole } from "../components/views/Settings/GeneralSettings/RolesAndPermission/NewRole";
import { Policies } from "../components/views/Settings/GeneralSettings/RolesAndPermission/Policies";
import { NewPolicy } from "../components/views/Settings/GeneralSettings/RolesAndPermission/NewPolicy";
import CreditNotes from "../components/views/Invoices/CreditNotes";
import CreditNote from "../components/views/Invoices/CreditNote";
import { EmailSettings } from "../components/views/Settings/GeneralSettings/EmailSettings/EmailSettings";
import { EditEmailTemplate } from "../components/views/Settings/GeneralSettings/EmailSettings/EditEmailTemplate";
import { MarketPlace } from "../components/views/Settings/GeneralSettings/MarketPlace/MarketPlace";
import { UserJob } from "../components/views/JobSchedular/UserJob";
import Jobs from "../components/views/JobSchedular/Jobs";
import SubscriptionAgreementSettings from "../components/views/Settings/GeneralSettings/SubscriptionAgreementSettings/SubscriptionAgreementSettings";
import PenaltyChargeNotice from "../components/views/Invoices/PenaltyChargeNotice";
import NoticeSummary from "../components/views/Invoices/NoticeSummary";
import PremiumReports from "../components/views/Reports/PremiumReports";
import Deposits from "../components/views/Invoices/Deposits";
import RateCards from "../components/views/Settings/Pricing/RateCards/RateCards";
import RateCard from "../components/views/Settings/Pricing/RateCards/RateCard";
import AddonRateCards from "../components/views/Settings/Pricing/AddonRateCards/AddonRateCards";
import AddonRateCard from "../components/views/Settings/Pricing/AddonRateCards/AddonRateCard";
import ConsolidatedInvoices from "../components/views/Invoices/ConsolidatedInvoices";
import { Assets } from "../components/views/Assets/AssetInventory/Assets";
import { NewAsset } from "../components/views/Assets/AssetInventory/NewAsset";
import { ViewAsset } from "../components/views/Assets/AssetInventory/ViewAsset/ViewAsset";
import { AssetDamages } from "../components/views/Assets/AssetDamages/AssetDamages";
import { AssetServices } from "../components/views/Assets/AssetServices/AssetServices";
import { NewAssetService } from "../components/views/Assets/AssetServices/NewAssetServices";
import { NewAssetDamages } from "../components/views/Assets/AssetDamages/NewAssetDamages";
import { Defleet } from "../components/views/AssetLifecycleManagement/Defleet";
import { DefleetVehicle } from "../components/views/AssetLifecycleManagement/Defleet/DefleetVehicle";
import { AssetTypes } from "../components/views/Settings/GeneralSettings/AssetType/AssetTypes";
import { NewAssetTypes } from "../components/views/Settings/GeneralSettings/AssetType/NewAssetType";
import DamageMatrix from "../components/views/Fleet/DamageMatrix/DamageMatrix";
import DamageMatrixComponents from "../components/views/Fleet/DamageMatrix/DamageMatrixComponents";
import { NewPartnerTabView } from "../components/views/Partners/UpdatePartnerTabView";
import AllocationRules from "../components/views/Settings/Pricing/AllocationRules/AllocationRules";
import UpdateConsolidatedInvoice from "../components/views/Invoices/UpdateInvoice/UpdateConsolidatedInvoice";
import UserJobs from "../components/views/JobSchedular/UserJobs";


export const history = createBrowserHistory();
// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "");

const CustomRoutes = () => {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // tracking user timing
    ReactGA.timing({
      category: "JS Dependencies",
      variable: "load",
      value: 20,
      label: "Google CDN"
    });
  });
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/bookings"
        element={<Bookings />}
      />
      <Route
        path="/new-booking"
        element={<NewBooking />}
      />
      <Route
        path="/update-booking"
        element={<NewBooking />}
      />
      <Route
        path="/view-booking"
        element={<ViewBooking />}
      />
      <Route
        path="/customers"
        element={<CustomerManagement />}
      />
      <Route
        path="/individual-customer"
        element={<Customers />}
      />
      <Route
        path="/new-customer"
        element={<Customer />}
      />
      <Route
        path="/update-customer"
        element={<Customer />}
      />
      <Route
        path="/customer-details"
        element={<CustomerDetails />}
      />
      <Route
        path="/business-customer"
        element={<BusinessCustomers />}
      />
      <Route
        path="/new-business-customer"
        element={<NewBusinessCustomer />}
      />
      <Route
        path="/update-business-customer"
        element={<UpdateBusinessCustomer />}
      />
      <Route
        path="/vehicle-inventory"
        element={<VehicleInventory />}
      />
      <Route
        path="/smartcar"
        element={<SmartCarOnboarding />}
      />
      <Route path="/vehicle-scheduler" element={<VehicleScheduler />} />
      <Route
        path="/vehicle-dashboard"
        element={<FleetDashboard />}
      />
      <Route path="/renewals" element={<Renewals />} />
      <Route
        path="/vehicle-status"
        element={<VehicleStatus />}
      />
      <Route
        path="/new-ghostvehicle"
        element={<GhostVehicle />}
      />
      <Route
        path="/new-vehicle"
        element={<Vehicle />}
      />
      <Route
        path="/update-vehicle"
        element={<Vehicle />}
      />
      <Route
        path="/asset-inventory"
        element={<Assets />}
      />
      <Route
        path="/new-asset"
        element={<NewAsset />}
      />
      <Route
        path="/update-asset"
        element={<ViewAsset />}
      />
      <Route
        path="/asset-damage"
        element={<AssetDamages />}
      />
      <Route
        path="/new-asset-damage"
        element={<NewAssetDamages />}
      />
      <Route
        path="/update-asset-damage"
        element={<NewAssetDamages />}
      />
      <Route
        path="/asset-services"
        element={<AssetServices />}
      />
      <Route
        path="/new-asset-services"
        element={<NewAssetService />}
      />
      <Route
        path="/update-asset-services"
        element={<NewAssetService />}
      />
      <Route path="/invoices" element={<Invoices />} />
      <Route path="/credit-notes" element={<CreditNotes />} />
      <Route path="/credit-note" element={<CreditNote />} />
      <Route path="/penalty-charge-notice" element={<PenaltyChargeNotice />} />
      <Route path="/notice-summary" element={<NoticeSummary />} />
      <Route path="/expenses" element={<FuelExpenses />} />
      <Route path="/new-expense" element={<NewFuelExpense />} />
      <Route path="/update-expense" element={<NewFuelExpense />} />
      <Route path="/deposits" element={<Deposits />} />
      <Route path="/consolidated-invoices" element={<ConsolidatedInvoices />} />
      <Route
        path="/new-organisation"
        element={<NewOrganisationStructureTab />}
      />
      <Route
        path="/update-organisation"
        element={<NewOrganisationStructureTab />}
      />
      <Route path="/reports" element={<Reports />} />
      <Route path="/management-reports" element={<PremiumReports active={true} />} />
      <Route
        path="/price-rules"
        element={<PriceRules />}
      />
      <Route
        path="/rate-cards"
        element={<RateCards />}
      />
      <Route
        path="/update-rate-card"
        element={<RateCard />}
      />
      <Route
        path="/addon-rate-cards"
        element={<AddonRateCards />}
      />
      <Route
        path="/update-addon-rate-card"
        element={<AddonRateCard />}
      />
      <Route
        path="/new-price-rule"
        element={<NewPriceRule priceRule={undefined} onSubmit={function (payload?: any | undefined): void {
          throw new Error("Function not implemented.");
        }} />}
      />
      <Route
        path="/update-price-rule"
        element={<NewPriceRule priceRule={undefined} onSubmit={function (payload?: any | undefined): void {
          throw new Error("Function not implemented.");
        }} />}
      />
      <Route
        path="/vehicle-price-groups"
        element={<VehiclePriceGroups />}
      />
      <Route
        path="/new-vehicle-price-group"
        element={<VehiclePriceGroup />}
      />
      <Route
        path="/update-vehicle-price-group"
        element={<VehiclePriceGroup />}
      />
      <Route
        path="/general-settings"
        element={<GeneralSettings />}
      />
      <Route
        path="/account-settings"
        element={<AccountSettings />}
      />
      <Route
        path="/update-billing"
        element={<UpdateInvoice />}
      />
      <Route
        path="/update-consolidated-invoice"
        element={<UpdateConsolidatedInvoice />}
      />
      <Route
        path="/new-sundry-invoice"
        element={<NewSundryInvoice />}
      />
      <Route
        path="/update-sundry-invoice"
        element={<UpdateSundryInvoice />}
      />
      <Route
        path="/integrations"
        element={<PaymentConfiguration />}
      />
      <Route
        path="/org-structure"
        element={<OrganisationStructure />}
      />
      <Route path="/addons" element={<AddonsTable />} />
      <Route
        path="/update-addon"
        element={<Addons />}
      />
      <Route path="/new-addon" element={<Addons />} />
      <Route
        path="/vehicle-damage"
        element={<VehicleDamages />}
      />
      <Route
        path="/vehicle-damage-matrix"
        element={<DamageMatrix />}
      />
      <Route
        path="/vehicle-damage-matrix-components"
        element={<DamageMatrixComponents />}
      />
      <Route
        path="/new-damage"
        element={<NewDamage />}
      />
      <Route
        path="/update-damage"
        element={<NewDamage />}
      />
      <Route
        path="/services-and-repair"
        element={<ServicesAndRepair />}
      />
      <Route
        path="/new-services-and-repair"
        element={<NewServicesAndRepair />}
      />
      <Route
        path="/protection-plan-settings"
        element={<InsuranceSettings />}
      />
      <Route
        path="/new-plan"
        element={<NewInsurance />}
      />
      <Route
        path="/update-plan"
        element={<NewInsurance />}
      />
      <Route path="/users" element={<AdminUsers />} />
      <Route
        path="/new-user"
        element={<NewAdminUser />}
      />
      <Route
        path="/update-user"
        element={<NewAdminUser />}
      />
      <Route path="/roles" element={<Roles />} />
      <Route
        path="/new-role"
        element={<NewRole />}
      />
      <Route
        path="/update-role"
        element={<NewRole />}
      />
      <Route path="/policies" element={<Policies />} />
      <Route
        path="/new-policy"
        element={<NewPolicy />}
      />
      <Route
        path="/update-policy"
        element={<NewPolicy />}
      />
      <Route path="/subscriptions" element={<Subscriptions />} />
      <Route path="/new-subscription" element={<NewSubscriptionPlan />} />
      <Route path="/update-subscription" element={<NewSubscriptionPlan />} />
      <Route path="/collection-checks" element={<CollectionChecks />} />
      <Route path="/delivery-checks" element={<DeliveryChecks />} />
      <Route path="/rental-agreement" element={<RentalAgreementSettings />} />
      <Route path="/update-partner" element={<NewPartnerTabView/>} />
      <Route path="/subscription-agreement" element={<SubscriptionAgreementSettings />} />
      <Route path="/apps" element={<ConsumerWebsites />} />
      <Route path="/new-app" element={<WebsiteSetup />} />
      <Route path="/update-app" element={<WebsiteSetup />} />
      <Route path="/vehicle-checklist" element={<NewVehicleCheckList />} />
      <Route path="/new-vehicle-checklist" element={<NewVehicleCheckList />} />
      <Route path="/update-vehicle-checklist" element={<NewVehicleCheckList />} />
      <Route path="/movements" element={<VehicleMovement />} />
      <Route path="/branch-connection" element={<BranchConnection />} />
      <Route path="/movement-lifecycle" element={<MovementLifecycle />} />
      <Route path="/vehicle-state" element={<ShareFleetVehicleState />} />
      <Route path="/fleet-transfer" element={<FleetTranser />} />
      <Route path="/payment" element={<StripePayment />} />
      <Route path="/document-types" element={<DocumentTypes />} />
      <Route path="/procurements" element={<Procurements />} />
      <Route path="/new-procurement" element={<CreateNewProcurement />} />
      <Route path="/review-procurement-request" element={<ReviewProcurementRequestTab />} />
      <Route path="/update-procurement-request" element={<CreateNewProcurement />} />
      <Route path="/partners" element={<Partners />} />
      <Route path="/new-partner" element={<Partner />} />
      <Route path="/update-partner" element={<Partner />} />
      <Route path="/service-task-items" element={<ServiceTaskItems />} />
      <Route path="/access-denied" element={<AccessDenied />} />
      <Route path="/email-settings" element={<EmailSettings />} />
      <Route path="/edit-email" element={<EditEmailTemplate />} />
      <Route path="/marketplace" element={<MarketPlace />} />
      <Route path="/user-job" element={<UserJob />} />
      <Route path="/user-jobs" element={<UserJobs />} />
      <Route path="/vehicle-defleet" element={<Defleet />} />
      <Route path="/update-vehicle-defleet" element={<DefleetVehicle />} />

      {/*TODO: Bring back once we gave RO to define custom assets types and option to add custom fields for assets
      <Route path="/asset-types" element={<AssetTypes />} />
      <Route path="/new-asset-type" element={<NewAssetTypes />} />
      <Route path="/update-asset-type" element={<NewAssetTypes />} /> 
      */}
      <Route path="/defleeted-vehicles" element={<VehicleInventory />} />
      <Route path="/update-defleeted-vehicle" element={<Vehicle />} />
      <Route path="/allocation-rules" element={<AllocationRules />} />
    </Routes>
  );
};
export default (CustomRoutes);
