import { useLazyQuery } from '@apollo/client';
import { CircularProgress, Divider, Grid, Theme, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { GET_VEHICLE_DASHBOARD_DATA } from '../../../../../../graphql/fleet/getVehicleExpenseQuery';
import { DATE_TYPE, toCurrency } from '../../../../../common/utils';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../../store';
import { IVehicle } from '../../../../../../reducers/fleet/types';
import { DateTime as d } from 'luxon';
import { createStyles, makeStyles } from '@mui/styles';
import { getLocalizedDateFormat } from '../../../../../../utils/localized.syntex';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3)
    },
    cardContainer: {
      height: 140,
      maxWidth: 220,
      padding: 12,
      border: '1px solid #fafafa',
      borderRadius: 4,
      boxShadow: '0px 2px 2px 2px #e7e4e4'
    },
    cardPara: {
      fontWeight: 'bold',
      marginTop: 16,
      marginBottom: 16,
      fontSize: 28,
      color: '#3d3c3c'
    }
  })
);

interface IVehicleDashboardProps {
  vehicle: IVehicle;
}

export interface IVehicleDashboardData {
  acquisitionCost: number;
  acquisitionSource: string;
  vehicleExpenses: VehicleExpenses
  vehicleRevenue: VehicleRevenue
  renewalAndReminders: RenewalAndReminders
}

export interface VehicleExpenses {
  fuelCost: number;
  fineCost: number;
  serviceCost: number;
  damageCost: number;
  insuranceCost: number;
  maintenanceCost: number;
}

export interface VehicleRevenue {
  totalEarnings: number;
  totalRevenueLoss: number;
  residualValue: number;
  depreciationPercentage: number;
  idleTotalLoss: number;
  realizedRevenue: number;
  totalVORDays: number;
  vorLossAmount: number;
  futureBookingRevenue: number;
}

export interface RenewalAndReminders {
  motRenewalDate: string;
  taxRenewalDate: string;
  insuranceRenewalDate: string;
  nextServiceDate: string;
}

const vehicleDashbordDataInitialValue: IVehicleDashboardData = {
  acquisitionCost: 0,
  acquisitionSource: "",
  vehicleExpenses: {
    fuelCost: 0,
    fineCost: 0,
    insuranceCost: 0,
    maintenanceCost: 0,
    serviceCost: 0,
    damageCost: 0
  },
  vehicleRevenue: {
    totalEarnings: 0,
    totalRevenueLoss: 0,
    residualValue: 0,
    depreciationPercentage: 0,
    idleTotalLoss: 0,
    realizedRevenue: 0,
    totalVORDays: 0,
    vorLossAmount: 0,
    futureBookingRevenue: 0
  },
  renewalAndReminders: {
    motRenewalDate: "",
    taxRenewalDate: "",
    insuranceRenewalDate: "",
    nextServiceDate: ""
  },
}

export const VehicleDashboard: FC<IVehicleDashboardProps> = ({ vehicle }) => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [dashbordData, setDashboardData] = useState<IVehicleDashboardData>(vehicleDashbordDataInitialValue);
  const [vehicleCreationDate, setVehicleCreationDate] = useState("");
  const [byDateWidth, setByDateWidth] = useState(0);
  const [byMileageWidth, setByMileageWidth] = useState(0);
  const [remainingMonth, setRemainingMonth] = useState(0);
  const [remainingMilleage, setRemainingMilleage] = useState(0);


  const [getVehicleDashboardData, { data, loading }] = useLazyQuery(GET_VEHICLE_DASHBOARD_DATA, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (vehicle && vehicle.id) {
      getVehicleDashboardData({
        variables: {
          vehicleId: vehicle.id
        }
      });
      if (vehicle.deFleetParameters && vehicle.deFleetParameters.byDate) {
        let vehicleCreatedDate;
        if (vehicle.registrationDate) {
          vehicleCreatedDate = vehicle.registrationDate;
        } else if (!vehicle.registrationDate && vehicle.dateCreated) {
          vehicleCreatedDate = vehicle.dateCreated;
        }
        if (vehicleCreatedDate) {
          setVehicleCreationDate(vehicleCreatedDate);
          const currentDate = d.now().toISO();
          const vehicleEndDate = vehicle.deFleetParameters.byDate;
          const monthRemaining = Math.round(d.fromISO(vehicleEndDate).diff(d.fromISO(currentDate), 'month').months);
          const totalLifeSpanInMonths = Math.round(d.fromISO(vehicleEndDate).diff(d.fromISO(vehicleCreatedDate), 'month').months)
          const percentCompletedByDate = 100 - Math.round(((monthRemaining) / totalLifeSpanInMonths) * 100);
          const mileageRemaining = vehicle.deFleetParameters.byDistanceDriven - vehicle.vehicleState.odometerReading;
          const percentCompletedByMileage = Math.round((vehicle.vehicleState.odometerReading / vehicle.deFleetParameters.byDistanceDriven) * 100);

          setRemainingMonth(monthRemaining > 0 ? monthRemaining : 0);
          if (percentCompletedByDate > 100) {
            setByDateWidth(100);
          } else if (percentCompletedByDate > 0 && percentCompletedByDate < 100) {
            setByDateWidth(percentCompletedByDate);
          }
          if (mileageRemaining < 0) {
            setRemainingMilleage(0);
          } else {
            setRemainingMilleage(mileageRemaining);
          }
          if (percentCompletedByMileage > 100) {
            setByMileageWidth(100)
          } else if (percentCompletedByMileage > 0 && percentCompletedByMileage < 100) {
            setByMileageWidth(percentCompletedByMileage);
          }
        }
      }
    }
  }, [vehicle]);

  useEffect(() => {
    if (data && data.getVehicleDashboardData) {
      setDashboardData(_.merge({}, vehicleDashbordDataInitialValue, data.getVehicleDashboardData));
    }
  }, [data]);

  const getMotandTaxRenewalDate = (date: string) => {
    let formattedDate = date;
    if (formattedDate && !d.fromISO(formattedDate).isValid) {
      formattedDate = getLocalizedDateFormat(country, d.fromFormat(formattedDate, 'd MMMM yyyy').toUTC().toISO(), DATE_TYPE.CONDENSED);
    } else if (formattedDate && d.fromISO(formattedDate).isValid) {
      formattedDate = getLocalizedDateFormat(country, formattedDate, DATE_TYPE.CONDENSED)
    } else if (!formattedDate) {
      formattedDate = "N/A";
    }
    return formattedDate;
  }

  if (loading) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3">DASHBOARD</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3} style={{ marginTop: 20 }}>
          <div style={{
            height: 150,
            width: 240,
            padding: 20,
            border: '1px solid #fafafa',
            borderRadius: 4,
            boxShadow: '0px 2px 2px 2px #e7e4e4'
          }}>
            <Typography variant='body1'>
              VEHICLE ACQUISITION COST
            </Typography>
            <p className={classes.cardPara}> {toCurrency(dashbordData.acquisitionCost, currency, locale)}</p>
            <p style={{ marginTop: 0, opacity: 0.8 }}>{dashbordData.acquisitionSource}</p>
          </div>
          {["Financed Vehicle", "Owned Vehicle"].includes(dashbordData.acquisitionSource) && (
            <>
              <Grid item xs={12} md={3} lg={3} style={{ marginTop: 20 }}>
                <div
                  style={{
                    height: 150,
                    width: 240,
                    padding: 20,
                    border: '1px solid #fafafa',
                    borderRadius: 4,
                    boxShadow: '0px 2px 2px 2px #e7e4e4'
                  }}
                >
                  <Typography variant='body1'>RESIDUAL VALUE</Typography>
                  <p className={classes.cardPara}>{toCurrency(dashbordData.vehicleRevenue.residualValue, currency, locale)} </p>
                  <p style={{ marginTop: 0, opacity: 0.8 }}>Vehicle end life {vehicle.deFleetParameters?.byDate ? d.fromISO(vehicle.deFleetParameters.byDate).toFormat('dd/MM/yyyy') : "NA"}</p>
                </div>
              </Grid>

              <Grid item xs={12} md={3} lg={3} style={{ marginTop: 20 }}>
                <div
                  style={{
                    height: 150,
                    width: 240,
                    padding: 20,
                    border: '1px solid #fafafa',
                    borderRadius: 4,
                    boxShadow: '0px 2px 2px 2px #e7e4e4',
                  }}
                >
                  <Typography variant="body1">DEPRECIATION</Typography>
                  <p className={classes.cardPara}>
                    {`${dashbordData.vehicleRevenue.depreciationPercentage?.toFixed(2)}%`}
                  </p>
                </div>
              </Grid>
            </>
          )}
        </Grid>
        <Divider orientation='vertical' flexItem style={{ marginTop: 20 }} />
        <Grid item xs={12} md={4} lg={4} style={{ marginLeft: 30, marginTop: 20, marginRight: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  FUEL COST
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleExpenses.fuelCost, currency, locale)} </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  MAINTENANCE & VALET
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleExpenses.maintenanceCost, currency, locale)} </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  SERVICE COST
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleExpenses.serviceCost, currency, locale)} </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  INSURANCE COST
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleExpenses.insuranceCost, currency, locale)} </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  FINE
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleExpenses.fineCost, currency, locale)} </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  DAMAGE COST
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleExpenses.damageCost, currency, locale)} </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation='vertical' flexItem style={{ marginTop: 20 }} />
        <Grid item xs={12} md={4} lg={4} style={{ marginLeft: 40, marginTop: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  TOTAL EARNINGS
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleRevenue.totalEarnings, currency, locale)} </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  REVENUE REALIZED
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleRevenue.realizedRevenue, currency, locale)} </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  LOSS OF REVENUE DUE TO IDLE PERIOD
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleRevenue.idleTotalLoss, currency, locale)} </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  VEHICLE OFF-ROAD DAYS
                </Typography>
                <p className={classes.cardPara}> {dashbordData.vehicleRevenue.totalVORDays} </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  LOSS DUE TO VEHICLE OFF-ROAD
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleRevenue.vorLossAmount, currency, locale)} </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  FUTURE BOOKING REVENUE
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleRevenue.futureBookingRevenue, currency, locale)} </p>
              </div>
            </Grid>
            {/* Comenting this card for now since the backend changes not implemented yet.
            
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.cardContainer}>
                <Typography variant='body1'>
                  LOSS OF REVENUE
                </Typography>
                <p className={classes.cardPara}> {toCurrency(dashbordData.vehicleRevenue.totalRevenueLoss, currency, locale)} </p>
              </div>
            </Grid>
            */}
          </Grid>
        </Grid>
      </Grid>
      <Divider orientation='horizontal' style={{ marginTop: 30 }} />
      <Grid container spacing={2} style={{ marginTop: 30 }}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3">Estimated Vehicle Service Life</Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                <div>
                  <span>By Date</span>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span>({remainingMonth} month remaining)</span>
                </div>
                <div style={{
                  backgroundColor: '#c8c8c8',
                  borderRadius: 13,
                  padding: 3
                }}>
                  <div
                    style={{
                      backgroundColor: 'blue',
                      width: `${byDateWidth}%`,
                      height: 8,
                      borderRadius: 10
                    }}
                  >
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ marginTop: 5 }}> {vehicleCreationDate ? d.fromISO(vehicleCreationDate).toFormat('dd/MM/yyyy') : "NA"} </p>
                  <p style={{ marginTop: 5 }}>{vehicle.deFleetParameters && vehicle.deFleetParameters.byDate ? d.fromISO(vehicle.deFleetParameters.byDate).toFormat('dd/MM/yyyy') : "NA"}</p>
                </div>
              </div>
              <div style={{ marginTop: 30 }}>
                <div>
                  <span>By {userState.currentBranch.distanceUnit === "mile" ? "Miles" : "Km"} Driven</span>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span>({remainingMilleage} {userState.currentBranch.distanceUnit === "mile" ? "miles" : "km"} remaining)</span>
                </div>
                <div style={{
                  backgroundColor: '#c8c8c8',
                  borderRadius: 13,
                  padding: 3
                }}>
                  <div
                    style={{
                      backgroundColor: 'blue',
                      width: `${byMileageWidth}%`,
                      height: 8,
                      borderRadius: 10
                    }}
                  >
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ marginTop: 5 }}>0 {userState.currentBranch.distanceUnit === "mile" ? "miles" : "km"}</p>
                  <p style={{ marginTop: 5 }}>{vehicle.deFleetParameters && vehicle.deFleetParameters.byDistanceDriven ? `${vehicle.deFleetParameters.byDistanceDriven} ${userState.currentBranch.distanceUnit === "mile" ? "miles" : "km"}` : `0 ${userState.currentBranch.distanceUnit === "mile" ? "miles" : "km"}`}</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation='vertical' flexItem />
        <Grid item xs={12} md={5} lg={5} style={{ marginLeft: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3">Renewal & Reminders</Typography>
            </Grid>
            <Grid item xs={12}>
              {country !== "United States" && (
                <>
                  <p> MOT renewal date : {" "}
                    {getMotandTaxRenewalDate(dashbordData.renewalAndReminders.motRenewalDate)}
                  </p>
                  <Divider />
                  <p> TAX renewal date : {" "}
                    {getMotandTaxRenewalDate(dashbordData.renewalAndReminders.taxRenewalDate)}
                  </p>
                  <Divider />
                </>
              )}
              <p> Insurance renewal date : {
                dashbordData.renewalAndReminders.insuranceRenewalDate ?
                  getLocalizedDateFormat(country, dashbordData.renewalAndReminders.insuranceRenewalDate, DATE_TYPE.CONDENSED) : "NA"}
              </p>
              <Divider />
              <p>Next Service : {dashbordData.renewalAndReminders.nextServiceDate ?
                getLocalizedDateFormat(country, dashbordData.renewalAndReminders.nextServiceDate, DATE_TYPE.CONDENSED) : "NA"}
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}