import { gql } from "@apollo/client";

export const GET_VEHICLES_BY_STATUS = gql`
  query getVehiclesByStatus($status: String!) {
    getVehiclesByStatus(status: $status) {
      insurerCode
      airConditioner
      assetTag
      bodyType
      colour
      fuelType
      licencePlate
      vin
      make
      model
      year
      imageUrl
      numberOfDoors
      numberOfPreviousOwners
      numberOfSeats
      grossVehicleWeight
      satNav
      sixMonthRate
      status
      id
      taxDetails
      taxed
      taxStatus
      pcoExpiry
      bookingSchedules {
        booking {
          id
        }
        endDate
        id
        startDate
        status
      }
      vehicleTest
      vehicleTestDetails
      vehicleTestDueDate
      vehicleTestReports {
        testDate
        expiryDate
        testResult
        odometerReading
        odometerUnit
        motTestNumber
        advisoryItems
        minorItems
        failureItems
      }
      vehicleDocuments {
        title
        documentName
        documentType
        expiryDate
        url
        createdAt
      }
    }
  }
`;
