import { useLazyQuery, ApolloError, useMutation } from "@apollo/client";
import { CircularProgress, Grid, CssBaseline, Typography, Fab, Box } from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IPartner } from "../../../../reducers/Procurement/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { assetCsvHeaders, CsvUploadStatusType, formatGraphQLErrorMessage, ICsvUploadError, possibleCsvMimeTypes } from "../../../common/utils";
import { GET_ASSET_COUNT, GET_ASSETS, UPLOAD_ASSET } from "../Graphql/assetQueries";
import { AssetTypes, AssetTypesValue } from "../asset.types";
import { BulkCsvUploadDialog } from "../../../common/BulkCsvUploadDialog/BulkCsvUploadDialog";
import papa from "papaparse";
import { captureErrorException } from "../../../../utils/sentry";

export const Assets: FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(10);
  const [assetCount, setAssetCount] = useState<number>(0);  
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = useState<ICsvUploadError[]>([]);
  const organisation = userState.currentOrganisation;
  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  );
  const [fileName, setFileName] = useState<string>("");
  const [fileValue, setFileValue] = useState<File | undefined>();

  const [
    loadAssets,
    { loading, data }
  ] = useLazyQuery(GET_ASSETS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [uploadAssets] = useMutation(UPLOAD_ASSET, {
    onError: (error: ApolloError) => {
      setFileName("");
      setFileValue(undefined);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      if (
        data.uploadAssets &&
        data.uploadAssets.errors &&
        data.uploadAssets.errors.length
      ) {
        setFileName("");
        setFileValue(undefined);
        setUploadErrors(data.uploadAssets.errors);
        return setUploadStatus(CsvUploadStatusType.ERROR);
      }
      getAssetCount()
      loadAssets({
        variables: {
          limit,
          offset: 0
        }
      })
      snackbar({
        message: "Assets uploaded successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setUploadModalOpen(false);
      loadAssets({
        variables: {
          offset: 0,
          limit
        }
      });
      setUploadStatus(CsvUploadStatusType.DEFAULT);
      setFileName("");
      setFileValue(undefined);
    }
  });

  const [getAssetCount] = useLazyQuery(
    GET_ASSET_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (assetCountData) => { setAssetCount(assetCountData.getAssetCount) }
    }
  );

  function onSelectDocument(event: any) {
    const file: File = event.target.files[0];
    setUploadErrors([]);
    setUploadStatus(CsvUploadStatusType.DEFAULT);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        return snackbar({
          message: "Invalid File Type. Allowed type: csv",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  useEffect(() => {
    if (userState.tenancy) {
      getAssetCount()
      loadAssets({
        variables: {
          limit,
          offset: 0
        }
      })
    }
  }, [userState.currentOrganisation])

  useEffect(() => {
    if (data && data.assets) {
      const reShapeRows = data.assets.map((asset: any) => {
        const reshapeAsset = {
          ...asset
        }
        return reshapeAsset;
      })
      setRows(reShapeRows)
    }
  }, [data]);

  useEffect(() => {
    if (!uploadModalOpen) {
      setUploadErrors([]);
    }
  }, [uploadModalOpen]);

  function uploadCsv() {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        papa.parse(fileValue, {
          skipEmptyLines: true,
          complete(results: any) {
            const assets = [];
            if (results.data.length) {
              for (let index = 0; index < results.data[0].length; index++) {
                const header = results.data[0][index];                
                if (header !== assetCsvHeaders[index]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid File Header.",
                    variant: SnackBarVariant.ERROR
                  });
                }
              }
              if (results.data.length === 2 && !results.data[1][0]) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
              for (let index = 1; index < results.data.length; index++) {
                const element: any = results.data[index];                
                if (element.length < 4) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid file",
                    variant: SnackBarVariant.ERROR
                  });
                }

                const data: any = {
                  assetType: element[0],
                  assetTag: element[1],
                  registrationNumber: element[2],
                  serialNumber: element[3].trim(),
                  licencePlate: element[4] ? element[4] : "",
                  make: element[5] ? element[5] : "",
                  model: element[6] ? element[6] : "",
                  color: element[7] ? element[7] : "",
                };
                
                assets.push(data);
              }
              if (assets.length > 0) {
                uploadAssets({
                  variables: {
                    assets
                  }
                });
              } else {
                setUploadStatus(CsvUploadStatusType.ERROR);
                snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
            }
          }
        });
      }
    } catch (error) {
      captureErrorException(error)
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  const options: MUIDataTableOptions = {
    count: assetCount,
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IPartner = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/update-asset?asset=${row.id}`);
      }
    },
    rowsPerPage: 10,

    // onChangeRowsPerPage: (numberOfRows: number) => {
    //   dispatch(
    //     addTableState({
    //       tableName: TableNames.PARTNERS,
    //       rowsPerPage: numberOfRows
    //     })
    //   );
    // },
    // rowsPerPage:
    //   partnersTable && partnersTable.rowsPerPage
    //     ? partnersTable.rowsPerPage
    //     : 10,
    // onColumnSortChange: (changedColumn: string, direction: string) => {
    //   dispatch(
    //     addTableState({
    //       tableName: TableNames.PARTNERS,
    //       columnName: changedColumn,
    //       direction
    //     })
    //   );
    // },
    // onTableInit: () => {
    //   if (partnersTable && sortOrder) {
    //     setTableSortOrder(partnerTableColumn, partnersTable, sortOrder);
    //   }
    // }
  };

  // const columns: any = [
  //   {
  //     label: "Asset Tag",
  //     name: "assetTag"
  //   },
  //   {
  //     label: "Asset Type",
  //     name: "assetType",
  //     options: {
  //       customBodyRender: (value: AssetTypes) => AssetTypesValue[value]
  //     }
  //   },
  //   {
  //     label: "Vehicle",
  //     name: "vehicle.licencePlate",
  //   },
  //   {
  //     label: "Created Date",
  //     name: "dateCreated",
  //     options: {
  //       customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
  //     }
  //   },
  //   {
  //     label: "Status",
  //     name: "isActive",
  //     options: {
  //       customBodyRender: (value: boolean) => value === true ? "Active" : "Deactivated"
  //     }
  //   },
  // ];

  
  const columns: any = [
    {
      label: "Asset Tag",
      name: "assetTag"
    },
    {
      label: "Registration Number",
      name: "registrationNumber"
    },
    {
      label: "Serial Number",
      name: "serialNumber"
    },
    {
      label: "Asset Type",
      name: "assetType",
      options: {
        customBodyRender: (value: AssetTypes) => AssetTypesValue[value]
      }
    },
    {
      label: "Make",
      name: "make"
    },
    {
      label: "Model",
      name: "model"
    },
    {
      label: "Color",
      name: "color"
    },
    {
      label: "Status",
      name: "isActive",
      options: {
        customBodyRender: (value: boolean) => value === true ? "Active" : "Deactivated"
      }
    },
  ];

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Assets
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          className="createButton"
          onClick={() => {
            navigate("/new-asset");
          }}
        >
          Create
        </Fab>
        <Box color="white" sx={{ pr: 2 }}></Box>
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create Multiple"
          onClick={() => setUploadModalOpen(true)}
        >
          Create Multiple
        </Fab>
      </Grid>
      {uploadModalOpen && (
      <BulkCsvUploadDialog
        title={"Create Multiple Assets"}
        subTitle={"Assets"}
        csvTemplateLink={
          "https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Coastr-Asset-Upload-CC.csv" 
        }
        uploadErrors={uploadErrors}
        uploadStatus={uploadStatus}
        onDocumentSelect={onSelectDocument}
        fileName={fileName}
        uploadCsv={uploadCsv}
        handleClose={() => setUploadModalOpen(false)}
      />
      )}
      <Grid container item xs={12}>
        <NuvvenTable
          title=""
          rows={rows}
          columns={columns}
          options={options}
          setSelection={() => { }}
        />
      </Grid>
    </Grid>
  )
}
