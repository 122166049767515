import {
    CircularProgress,
    Grid,
    Hidden,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography
  } from '@mui/material';
  import { createStyles, makeStyles } from "@mui/styles";
  import React from 'react';
  import { useSelector } from 'react-redux';
  import { IBooking } from '../../../../../reducers/bookings/types';
  import { IAppState } from '../../../../../store';
  import { toCurrency } from '../../../../common/utils';
  
  interface IInsuranceInfoProps {
    booking: IBooking;
  }
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      tableCellBorder: {
        borderRight: "1px solid rgba(224, 224, 224, 1)"
      },
      tableCellPadding: {
        padding: 8
      },
      summaryLeftMargin: {
        marginLeft: 5
      },
      paymentInfoTitle: {
        margin: 0,
        fontSize: 13,
        fontWeight: "bolder"
      },
      paymentInfoAmount: {
        margin: "0px",
        fontSize: "20px",
        fontWeight: "bold",
        marginTop: 3
      }
    })
  );
  
  export const Insurance: React.FC<IInsuranceInfoProps> = (props) => {
    const { booking } = props;
    const classes = useStyles();
    const userState = useSelector((state: IAppState) => state.userReducer);
    const { locale, currency } = userState.currentOrganisation;
    const { country } = userState.currentOrganisation.address;
  
    return (
      <>
        <Hidden xsDown>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h3">
                Protection Plan
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              {props.booking.updateInProgress ? 
                <Grid container>
                  <CircularProgress size={14} style={{ color: "white", margin: "0 10px" }} />
                  <Typography variant='body1'>Fetching...</Typography>
                </Grid>
                :<TableContainer>
                  <Table aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className={classes.tableCellPadding}>
                          <Typography>Plan Name</Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCellPadding}>
                          <Typography>Description</Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCellPadding}>
                          <Typography >Price</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                <TableBody>
                    <TableRow>
                      <TableCell>{booking?.insurancePolicy?.name}</TableCell>
                      <TableCell>{booking?.insurancePolicy?.description}</TableCell>
                      <TableCell>{toCurrency(booking?.insuranceRate, currency, locale)}</TableCell>
                      </TableRow>
                      </TableBody>
                  </Table>
                </TableContainer>     
              }
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp>
          {!props.booking.updateInProgress && <Grid container xs={12} spacing={1}>
            {booking  && (
                  <Grid item xs={12}>
                    <Grid container
                      style={{
                        background: "#f5f5f5",
                        borderRadius: 5,
                        padding: 20
                      }}>
                      <Grid container item xs={12}>
                        <Grid item>
                          <Typography variant="h6">
                            Plan Name:
                          </Typography>
                        </Grid>
                        <Grid item className={classes.summaryLeftMargin}>
                          <Typography variant="body1">
                            {booking?.insurancePolicy?.name}
                          </Typography>
                        </Grid>
                        
                      </Grid>
                    </Grid>
                  </Grid>
            )}
          </Grid>}
        </Hidden>
      </>
    )
  }
  
  