import { Value } from "react-phone-number-input";

import { IBookingQuestion } from "../../components/views/AppSettings/types";
import { BOOKING_CATEGORY } from "../bookings/types";
import { IConvergeCredentials, IConvergeUSCredentials } from "../user/types";

export interface IOrganisationState {
  organisation: IOrganisation;
  branch: IBranch;
  serviceLocation: IServiceLocation;
  serviceLocations: IServiceLocation[];
}

export interface IBranchState {
  branch: IBranch;
}

export interface IServiceLocationState {
  serviceLocation: IServiceLocation;
}

export type OrganisationPayloadType = IOrganisation | IOrganisationId;

export type BranchPayloadType = IBranch | IBranchId;

export type ServiceLocationPayloadType = IServiceLocation | IServiceLocationId;

export interface IB2b2cSettings {
  active: boolean;
  carousels: string[];
  vehicles: string[];
  description: string;
  privacyPolicyURL: string;
  websiteTitle: string;
  websiteBackgroundColor: string;
  websiteButtonBackgroundColor: string;
  favicon: string;
}

export interface holidayType {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

export interface IOrganisation {
  id: string;
  address: IAddress;
  locale: string;
  owner: string;
  branches: IBranch[];
  emailId: string;
  name: string;
  phoneNumber: IPhoneNumber;
  termsAndConditionsUrl: any;
  currency: string;
  logoUrl: any;
  rate: any;
  stripeAccountId?: string;
  orgVatNumber: string;
  orgRegNumber: string;
  insuranceDeclaration: string;
  cancellationPolicy: string;
  refundPolicy?: IRefundPolicy;
  sepaEnabled?: boolean;
  bacsEnabled?: boolean;
  cashEnabled: boolean;
  cardEnabled: boolean;
  payOnCollectionEnabled: boolean;
  requiresCustomerVerification: boolean;
  offlineBankTransferEnabled?: boolean;
  payByLinkEnabled: boolean;
  autoChargeEnabled?: boolean;
  requiresBookingApproval?: boolean;
  closeGroupSharingEnabled?: boolean;
  allowCarSharing?: boolean;
  disableMapForCarShareVehicles?: boolean;
  enableAutoCreditCheck?: boolean;
  creditLimits?: ICreditLimits[];
  enableIdentityVerification?: boolean;
  creditSafeCredentials?: ICreditSafe;
  enableCostCenter?: boolean;
  costCenters?: ICostCenter[];
  businessCustomerTags?: string[];
  allowPreSigningAgreement: boolean;
  inspectionCheckEnabled?: boolean;
  maxImagesPerDamage?: number;
  crossLocationBookingEnabled?: boolean;
  convergeEnabled?: boolean;
  convergeCredentials?: IConvergeCredentials;
  convergeUSCredentials?: IConvergeUSCredentials;
  bookingCategories: IBookingCategory[];
  supplierPortalEnabled?: boolean;
  enableDriverAllocationRules?: boolean;
  driverAllocationRules?: IDriverAllocationRule[];
  extendedTestDriveMaxHours?: number;
}

export interface IBookingCategory {
  isActive: boolean;
  type: BOOKING_CATEGORY;
  default: boolean;
}

export interface ICostCenter {
  name: string;
  projectIds: string[];
}

export interface IOrganisationWrite {
  id?: string;
  address: IAddress;
  locale: string;
  owner: string;
  branches?: IBranch[];
  emailId: string;
  name: string;
  phoneNumber: IPhoneNumber;
  termsAndConditionsUrl: any;
  currency: string;
  logoUrl: any;
  rate?: any;
  stripeAccountId?: string;
  orgVatNumber: string;
  orgRegNumber: string;
  insuranceDeclaration: string;
  cancellationPolicy: string;
  refundPolicy?: IRefundPolicy;
}

export interface ICreditLimits {
  type: string;
  lowerLimit: number;
  upperLimit: number;
}

export interface IRefundPolicy {
  flatCharge: number;
  refundRules: IRefundRule[];
}

export interface IRefundRule {
  charge: number;
  timeFrame: number;
  timeUnit: string;
}

export interface IOrganisationId {
  id: string;
}

export interface IBranch {
  id?: string;
  address: IAddress;
  owner: {
    id: string;
    email: string;
  };
  emailId: string;
  timeZone: string;
  name: string;
  phoneNumber: IPhoneNumber;
  operatingHours: IOperatingHours[];
  minBookingDuration?: number; // Duration will be in hours
  minimumBookingAmount: IMinimumBookingAmount;
  overbookingAllowed: boolean;
  minBookingDurationOptIn: boolean;
  minBookingAmountOptIn: boolean;
  // minExtensionDurationOptIn?: boolean;
  // minimumExtensionDuration?: number; // Duration will be in hours
  preBookingQuestions: IBookingQuestion[];
  additionalDriverQuestions: IBookingQuestion[];
  isAdditionalDriverQuestionsEnabled: boolean;
  deliveryQuestions: IBookingQuestion[];
  rentalAgreementSettings?: IRentalAgreementSettings;
  subscriptionAgreementSettings?: ISubscriptionAgreementSettings;
  distanceUnit: string;
  netPaymentTerms?: number;
  dailyBillingCycle: number;
  weeklyBillingCycle: number;
  monthlyBillingCycle: number;
  vehiclePreparationTime: number;
  carShareGracePeriodMins?: number;
  useThresholds?: boolean;
  offenceThreshold?: number;
  pointThreshold?: number;
  signatureUrl?: string;
  holidays: IHoliday[];
  addTaxOptIn: boolean;
  taxes: ITaxInput[];
  depositCollection?: string;
  durationBasedPricingEnabled?: boolean;
  epyxAccountNumber?: string;
  epyxSchemeReferenceNumber?: string;
  corporateRateCardEnabled?: boolean;
  rentalAgreementSettingsMap?: IAgreementSettingsMap;
  subscriptionAgreementSettingsMap?: IAgreementSettingsMap;
  dashboardReminder?: number;
  showPaymentMethodInvoice?: boolean;
  paymentMethodString?: string;
  subscriptionSettings: ISubscriptionSettings;
  autoPayEnabled?: boolean;
  autoPayDays?: number[];
  autoColorEnabled?: boolean;
  eventsColor: IEventColor[];
}
export interface IEventColor {
  reason: string;
  color: string;
}
export interface IAgreementSettingsMap {
  individual: any;
  business: any;
}

export interface ITaxInput {
  title: string;
  type: string;
  value: number;
  rate: string;
  subdivisionOptIn: boolean;
  subdivisionType: string;
  subdivisionValue: number;
  subdivisionRate: string;
  isVehicleGroupSpecific?: boolean;
  vehicleGroups?: string[];
}

export enum TaxValueType {
  FIXED = "FIXED",
  VARIABLE = "VARIABLE",
  PERCENTAGE = "PERCENTAGE"
}
export interface IHoliday {
  id?: string;
  name: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
}

export interface IRentalAgreementSettings {
  individual: ICustomerSetting;
  business: ICustomerSetting;
}

export interface ISubscriptionAgreementSettings {
  individual: ISubscriptionCustomerSettings;
  business: ISubscriptionCustomerSettings;
}

export interface ICustomerSetting {
  rentalCompanyDetails: string[];
  customerDetails: string[];
  bookingDetails: string[];
  vehicleDetails: string[];
  driverDetails: string[];
  addonDetails: string[];
  insuranceDetails: string[];
  paymentInfo: string[];
  bookingExtension: string[];
  insuranceDeclaration: string;
  cancellationPolicy: string;
  preBookingQuestions: boolean;
  additionalDriverQuestions: boolean;
  deliveryQuestions: boolean;
  additionalInfo: boolean;
  securityDeposit: boolean;
  customerDeclaration?: boolean;
  tnc: string;
  additonalTnc?: boolean;
  oldVehicleDetails: string[];
  subscriptionDetails: string[];
}

export interface ISubscriptionCustomerSettings {
  rentalCompanyDetails: string[];
  customerDetails: string[];
  vehicleDetails: string[];
  subscriptionDetails: string[];
  addonDetails: string[];
  insuranceDetails: string[];
  paymentInfo: string[];
  bookingExtension: string[];
  insuranceDeclaration: string;
  cancellationPolicy: string;
  tnc: string;
  securityDeposit: boolean;
  preBookingQuestions: boolean;
  additionalDriverQuestions: boolean;
  deliveryQuestions: boolean;
  additionalInfo: boolean;
  customerDeclaration: boolean;
  additonalTnc: boolean;
  oldVehicleDetails: string[];
}

export enum BOOKING_TYPE {
  RENTAL = "RENTAL",
  SUBSCRIPTION = "SUBSCRIPTION"
}
export interface IMinimumBookingAmount {
  type: string;
  value: number;
}

export interface IOperatingHours {
  day: string;
  dayIndex: number;
  workingFromTime?: string;
  workingToTime?: string;
  pickupFromTime?: string;
  pickupToTime?: string;
  returnFromTime?: string;
  returnToTime?: string;
  closed: boolean;
}

interface IPhoneNumber {
  phone: Value;
  country: string;
}
export interface IBranchId {
  id: string;
}

export interface IServiceLocation {
  id?: string;
  address: IAddress;
  name: string;
  branch: string;
  addSurchargesOptIn: boolean;
  surcharges: ILocationSurchargeInput[];
  isDisabled: boolean;
}

export interface ILocationSurchargeInput {
  id?: string;
  title: string;
  rate: string;
  type: string;
  value: number;
  taxable?: boolean;
  tax: ILocationSurchargeTaxInput;
}

export interface ILocationSurchargeTaxInput {
  title: string;
  value: number;
  type?: string;
}

export interface ILocationTaxSurcharge {
  id: string;
  name: string;
  value: number;
  rate: string;
  type: string;
  taxable?: boolean;
  tax: ILocationSurchargeTaxInput;
}
export interface IServiceLocationId {
  id: string;
}

export interface ITax {
  name: string;
  rate: number;
  type: string;
}

export interface IAddress {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zipcode?: string;
  fullAddress?: string;
}

export interface ICreditSafe {
  username?: string;
  password?: string;
}

export interface ISubscriptionSettings {
  idvEnabled: boolean;
  creditCheckEnabled: boolean;
  dvlaVerificationEnabled: boolean;
}
export enum RENTAL_COMPANY_DETAILS {
  LOGO = "logoUrl",
  COMPANY_NAME = "name",
  ADDRESS = "address",
  PHONE = "phoneNumber",
  EMAIL = "emailId",
  VAT_NUMBER = "orgVatNumber",
  COMPANY_REG_NUMBER = "orgRegNumber"
}

export enum CUSTOMER_INFO {
  ACCOUNT_NUMBER = "registrationNumber",
  NAME = "name",
  PHONE = "phoneNumber",
  EMAIL = "email",
  BUSINESS_NAME = "businessName",
  NATIONAL_INSURANCE_NUMBER = "insuranceNumber",
  DATE_OF_BIRTH = "dateOfBirth",
  DRIVING_LICENSE_NUMBER = "licenseNumber",
  DRIVING_LICENSE_EXPIRY = "licenseExpiry",
  LICENSE_ISSUED_BY = "licenseIssuedBy",
  ADDRESS = "address"
}

export enum BOOKING_DETAILS {
  PICKUP_DETAILS = "pickup",
  DROPOFF_DETAILS = "dropoff",
  ADDITIONAL_DRIVERS = "additionalDrivers",
  PO_NUMBER = "poNumber",
  MILEAGE_LIMIT = "mileageLimit"
}

export enum VEHICLE_DETAILS {
  MAKE_MODEL = "make/model",
  FEATURES = "features",
  REGISTRATION_NUMBER = "registrationNumber",
  VIN_NUMBER = "vin",
  PCO_NUMBER = "pcoNumber",
  ADDON_DETAILS = "addons",
  COLOR = "color",
  YEAR = "year",
  TRANSMISSION_TYPE = "transmission",
  NUMBER_OF_DOORS = "numberOfDoors",
  NUMBER_OF_SEATS = "numberOfSeats"
}

export enum DRIVER_DETAILS {
  NAME = "name",
  DATE_OF_BIRTH = "dateOfBirth",
  PHONE = "phoneNumber",
  ADDRESS = "address",
  EMAIL = "email",
  DRIVING_LICENSE_NUMBER = "licenseNumber",
  DRIVING_LICENSE_EXPIRY = "licenseExpiry",
  LICENSE_ISSUED_BY = "licenseIssuedBy",
  PCO_NUMBER = "customerPCONumber"
}

export enum PAYMENT_INFO {
  TOTAL_RENTAL_AMOUNT = "rentalAmount",
  ADDON_AMOUNT = "addonAmount",
  DEPOSIT = "deposit",
  SUBTOTAL = "subtotal",
  VAT_TAX = "taxAmount",
  GRAND_TOTAL = "totalPayableAmount",
  AMOUNT_PAID = "amountPaid",
  AMOUNT_DUE = "amountDue",
  DISCOUNT = "discountAmount"
}

export enum BOOKING_EXTENSION_DETAILS {
  EXTENSION_NUMBER = "extensionNumber",
  ID = "referenceNumber",
  EXTENSION_PERIOD = "extensionPeriod",
  INVOICE_NUMBER = "invoiceRef",
  NEW_DROPOFF_LOCATION = "dropOffLocation"
}

export enum ADDON_DETAILS {
  ADDON_NAME = "addonName",
  DESCRIPTION = "description",
  QUANTITY = "quantity"
}
export interface IOptionsMenu {
  label: string;
  value: string | number;
}

export interface IDriverAllocationRule {
  ruleName: string;
  value?: string;
}