import { Value } from "react-phone-number-input";

import { IBookingQuestion } from "../../components/views/AppSettings/types";
import { IUserAccessPolicy } from "../../components/views/Settings/GeneralSettings/RolesAndPermission/NewPolicy";
import {
  IBookingCategory,
  ICostCenter,
  IDriverAllocationRule,
  IHoliday,
  ITaxInput
} from "../organisation/types";
import { IPartner } from "../partners/types";
import { PendingActionSubCategories } from "../../components/views/DashboardNew/UpcomingEvents";

export interface IUserState {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  phoneNumber: IPhoneNumber;
  active: boolean;
  tenancy: ITenancy;
  organisation: IOrganisation;
  branch: IBranch;
  currentTenancy: ITenancy;
  currentOrganisation: IOrganisation;
  currentBranch: IBranch;
  userRole: IUserRole;
  partner: IPartner;
}

export interface IUserRole {
  id: string;
  name: string;
  key: string;
  policies: Partial<IUserAccessPolicy>[];
}

export interface IUser {
  id?: string;
  email?: string;
  email_verified?: string;
  sub?: string;
  tenantId?: string;
  organisationId?: string;
  branchId?: string;
  organisation?: IOrganisation;
  branch?: IBranch;
  currentTenancy?: ITenancy;
  currentOrganisation?: IOrganisation;
  currentBranch?: IBranch;
}

export interface ITenancy {
  id: string;
  owner?: IOwner;
  name: string;
  address: IAddress;
  logoUri: string;
  emailId: string;
  phoneNumber: IPhoneNumber;
  billing: IBilling;
  organisations: string[];
  chargebeeSubscriptionId: string;
  chargebeePlanId: string;
  chargebeeCustomerId: string;
  chargebeePlanType: ChargebeePlanType;
  chargebeeSubscriptionExpiry: string;
  appLogo?: string;
  themeColor?: string;
  carSubscriptionEnabled?: boolean;
  loginEnabled?: boolean;
  epyxAccountNumber: string;
  epyxSchemeReferenceNumber: string;
  enableEpyxIntegration: boolean;
  idvEnabled?: boolean;
  creditCheckEnabled?: boolean;
  transfer360Enabled?: boolean;
  transfer360Integrated?: boolean;
  taxEnabledCreditNotes?: boolean;
  rateCardsEnabled?: boolean;
  trailerCSVUploadEnabled?: boolean;
}

export enum ChargebeePlanType {
  TRIAL = "TRIAL",
  PAID = "PAID"
}
interface IPhoneNumber {
  phone: string;
  country: string;
}

export interface IBilling {
  name: string;
  billingAddressSame: boolean;
  address: IAddress;
  emailId: string;
  phoneNumber: IPhoneNumber;
}

export interface IOrganisation {
  id: string;
  owner?: IOwner;
  address: IAddress;
  locale: string;
  emailId: string;
  phoneNumber: IPhoneNumber;
  name: string;
  termsAndConditionsUrl: string;
  currency: string;
  branches: IBranch[];
  orgVatNumber: string;
  orgRegNumber: string;
  stripeAccountId?: string;
  taxes: ITax[];
  logoUrl: string;
  insuranceDeclaration?: string;
  cancellationPolicy?: string;
  sageAccounting: ISageAccounting;
  sepaEnabled?: boolean;
  bacsEnabled?: boolean;
  cashEnabled: boolean;
  cardEnabled: boolean;
  offlineBankTransferEnabled: boolean;
  payOnCollectionEnabled: boolean;
  payByLinkEnabled: boolean;
  autoChargeEnabled?: boolean;
  requiresCustomerVerification: boolean;
  allowCarSharing: boolean;
  disableMapForCarShareVehicles?: boolean;
  enableIdentityVerification?: boolean;
  enableAutoCreditCheck?: boolean;
  creditLimits?: ICreditLimits[];
  creditSafeCredentials?: ICreditSafe;
  xeroEnabled?: boolean;
  enableCostCenter?: boolean;
  costCenters?: ICostCenter[];
  businessCustomerTags?: string[];
  inspectionCheckEnabled?: boolean;
  maxImagesPerDamage?: number;
  crossLocationBookingEnabled?: boolean;
  convergeEnabled?: boolean;
  convergeAutoChargeAvailable?: boolean;
  convergeCredentials?: IConvergeCredentials;
  convergeUSCredentials?: IConvergeUSCredentials;
  sendServiceBillsToXero?: boolean;
  bookingCategories: IBookingCategory[];
  supplierPortalEnabled?: boolean;
  enableDriverAllocationRules?: boolean;
  driverAllocationRules?: IDriverAllocationRule[];
  closeGroupSharingEnabled?: boolean;
}
export interface IConvergeCredentials {
  merchantAccountId: string;
  merchantAlias: string;
  merchantSecret: string;
}

export interface IConvergeUSCredentials {
  ssl_account_id: string;
  ssl_user_id: string;
  ssl_pin: string;
  ssl_vendor_id: string;
}

export interface ICreditLimits {
  type: string;
  lowerLimit: number;
  upperLimit: number;
}

export interface ICreditSafe {
  username: string;
  password: string;
}

export interface ISageAccounting {
  active: boolean;
}
export interface ITax {
  name: string;
  rate: number;
  type: string;
}

export interface IRegion {
  branches: [IBranch];
  id: string;
  name: string;
}

export interface IBranch {
  id: string;
  groupName: string;
  timeZone: string;
  locations: IServiceLocation[];
  name: string;
  distanceUnit: string;
  preBookingQuestions: IBookingQuestion[];
  deliveryQuestions: IBookingQuestion[];
  vehiclePreparationTime: number;
  useThresholds?: boolean;
  offenceThreshold?: number;
  overbookingAllowed?: boolean;
  pointThreshold?: number;
  downloadHistorySettings?: IDownloadHistory;
  operatingHours?: IOperatingHours[];
  holidays: IHoliday[];
  taxes: IBranchTax[];
  dailyBillingCycle?: number;
  weeklyBillingCycle?: number;
  monthlyBillingCycle?: number;
  depositCollection?: string;
  carShareGracePeriodMins?: number;
  durationBasedPricingEnabled?: boolean;
  isAdditionalDriverQuestionsEnabled: boolean;
  additionalDriverQuestions: IBookingQuestion[];
  rentalAgreementSettingsMap?: IAgreementSettingsMap;
  subscriptionAgreementSettingsMap?: IAgreementSettingsMap;
  dashboardReminder?: number;
  autoPayEnabled?: boolean;
  autoPayDays?: number[];
  eventsColor: IEventColor[];
  autoColorEnabled: boolean;
}
export interface IEventColor {
  reason: string;
  color: string;
}
export interface IAgreementSettingsMap {
  individual: any;
  business: any;
}

export interface IBranchTax extends ITaxInput {
  id: string;
}

export interface IOperatingHours {
  day: string;
  dayIndex: number;
  pickupFromTime?: string;
  pickupToTime?: string;
  returnFromTime?: string;
  returnToTime?: string;
  closed: boolean;
}

export interface IDownloadHistory {
  invoiceHistory: string[];
}
export interface IServiceLocation {
  id: string;
  name: string;
  address: IAddress;
  branch?: Partial<IBranch>;
  isDisabled?: boolean;
}

export interface IAddress {
  street: string;
  city: string;
  fullAddress: string;
  state: string;
  country: string;
  zipcode: string;
  geometry?: IGeometry;
}

export interface IGeometry {
  type: string;
  latitude: number;
  longitude: number;
}
export interface IDailyMileageLimitState {
  dailyMileageLimit: number;
  pricePerExtraMile: number;
}

export interface IOwner {
  id: string;
}

export const BillingCycle: { [key: string]: number } = {
  MONTHLY: 30,
  WEEKLY: 7
};

export interface IDriverRow {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  license: {
    authority: string;
    licenseNumber: string;
    dateOfExpiry: string;
    images: (string | object | undefined)[];
  };
  phoneNumber: {
    phone: Value;
    country: string;
  };
  location: IAddress;
  nationalInsuranceNumber?: string;
  profession?: string;
  verificationMethod?: string;
}

export const pendingActionDescriptions: Record<
  PendingActionSubCategories,
  string
> = {
  [PendingActionSubCategories.APPROVE_REJECT_BOOKING]:
    'Approve or reject booking',
  [PendingActionSubCategories.VEHICLE_SWAP]: 'Vehicle swap',
  [PendingActionSubCategories.BOOKING_EXTENSION]: 'Booking extension',
  [PendingActionSubCategories.APPROVE_REJECT_CUSTOMER]:
    'Approve or reject customer',
  [PendingActionSubCategories.VEHICLE_PICK_UP]: 'Vehicle pick up',
  [PendingActionSubCategories.VEHICLE_DROP_OFF]: 'Vehicle drop off',
  [PendingActionSubCategories.VEHICLE_SERVICE_PICK_UP]:
    'Vehicle service pick up',
  [PendingActionSubCategories.VEHICLE_SERVICE_DROP_OFF]:
    'Vehicle service drop off',
  [PendingActionSubCategories.COURTSEY_BOOKING]:
    'Courtsey car booking',
};

export enum TaskStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN_PROGRESS'
}