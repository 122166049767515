import { DriverType, IUserJobWrite } from "./types";

export const jobInitialState: IUserJobWrite = {
  subCategory: "",
  jobDate: "",
  assignedTo: "",
  description: "",
  actionsLinked: [],
  otherTaskItems: [],
  status: "",
  driverType: DriverType.INTERNAL
}