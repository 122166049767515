import { INewVehicleDamage } from "../../components/views/Fleet/Issues&Damages/NewDamage";
import { RATE_TYPES, TestDriveExperiences, TestDriveTypes } from "../../components/views/ReservationManagement/utils";
import { IOneWayRentalFee } from "../../components/views/VehicleMovement/types";
import { IAddonType } from "../addonType/types";
import { IAdminUser } from "../adminUser/types";
import {
  IBusinessCustomer,
  IBusinessCustomerBillingInput,
  IBusinessCustomerCommonInput,
  IBusinessCustomerCommonInputWithId,
  IBusinessCustomerDocumentInput,
  ICustomer,
  ILocation
} from "../customer/types";
import { IVehicle, IVehicleState } from "../fleet/types";
import { IInvoice, IPayment, XeroStatus } from "../invoices/types";
import { IPartner } from "../partners/types";
import { IPriceRule } from "../priceRule/types";
import { ISubscription, ISubscriptionPricing } from "../Subscription/types";
import { IBranch, IServiceLocation, IUserState } from "../user/types";
import { IBookingVehiclePriceGroup } from "../vehiclePriceGroups/types";

export interface IBookingState {
  checkInfo: CheckInfo;
  booking: IBooking;
  invoiceId?: string;
  vehicleSelectionView: VehicleSelectionView;
  createBookingInput?: IBookingCreateInput;
}

export enum VehicleSelectionView {
  REGISTRATION,
  CATEGORY
}

export interface IBooking {
  id: string;
  bookingId?: string;
  dateReturned?: string;
  dateStarted?: string;
  firstInvoice?: IInvoice;
  currentInvoice?: IInvoice;
  finalInvoice?: IInvoice;
  pickupCheck?: any;
  dropoffCheck?: any;
  vehicle?: any;
  rentalAgreementUrl?: string;
  vehicleConditionReportUrls?: string[];
  referenceNumber: string;
  dateCreated: string;
  dateUpdated: string;
  createdBy: IBookingUsers;
  updatedBy: IAdminUser;
  pickupServiceLocation: IServiceLocation;
  pickupOtherLocation?: IAddressLocation;
  pickupDateTime: string;
  dropoffServiceLocation: IServiceLocation;
  dropoffOtherLocation?: IAddressLocation;
  dropoffDateTime?: string;
  extendedDropoffDateTime?: string;
  initialDropoffDateTime?: string;
  initialDropoffServiceLocation?: string;
  initialPickupDateTime?: string;
  initialPickupServiceLocation?: string;
  longTermBooking: boolean;
  flightNumber: string;
  insuranceRate: number;
  excess: number;
  insurancePolicy?: IInsurancePolicyBooking;
  customerType: string;
  discount: IDiscount;
  tax?: ITax[];
  rateTypeName: RATE_TYPES;
  rateTypeDuration: number;
  billingCycleName?: string;
  billingCycleDuration?: number;
  status: BookingStatus;
  organisationId: string;
  vehicleGroups: IBookingVehicleGroup[];
  addonRequirements: IBookingAddonRequirement[];
  customer?: ICustomerDetails;
  invoices: IInvoice[];
  activeInvoices?: IInvoice[];
  businessCustomer?: IBookingBusinessCustomer;
  authorizedSignatory?: string;
  approvedDrivers?: any;
  paymentDetails: IPaymentDetails;
  depositDetails?: IDepositDetails;
  currentBookingSchedules: IBookingSchedule[];
  previousBookingSchedules: IBookingSchedule[];
  quoteUrl?: string;
  bookingConfirmationUrl?: string;
  notes: IBookingNote[];
  pcoNumber?: boolean;
  extensions?: [];
  latestExtension?: any;
  smartCarVehicle?: boolean;
  additionalInformation: IBookingAdditionalInformation;
  noteFromWebsite: INoteFromWebsite;
  creditNotes?: ICreditNote[];
  preBookingQuestions: IBookingQuestionInput[];
  deliveryQuestions: IBookingQuestionInput[];
  source?: BOOKING_SOURCE;
  additionalTnc?: string;
  locationSurcharges: ILocationSurchargeInput[];
  poNumber?: string;
  branchId?: string;
  vehicleReplacement?: IBookingVehicleGroup[];
  events: IBookingEvent[];
  lastEventTime: string;
  lastEventType: string;
  lastEventBy?: IUserState;
  isRecurringBilling?: boolean;
  billingFrequency?: number;
  taxExempted?: boolean;
  zeroCostBooking?: boolean;
  zeroCostBookingNote?: string;
  invoiceNote?: string;
  tba?: boolean;
  autoRenewal?: boolean;
  isCoi?: boolean;
  coiDetails: IcoiDetails;
  isSubscription?: boolean;
  // activeSubscription?: IBookingSubscription;
  activeSubscription?: any;
  swapRequests?: ISwapRequest[];
  consumedSwaps?: IVehicleSwapInfo[];
  isCarShare?: boolean;
  bookingType?: string;
  updateInProgress?: boolean;
  autoChargeLater?: boolean;
  stripePaymentMethodId?: boolean;
  costCenter?: string;
  projectId?: string;
  oneWayRentalFee: IOneWayRentalFee;
  convergeTokenizedCard?: string;
  bookingCategory: string;
  deliveryAddress?: IAddressLocation;
  autoIncrementRatesBy?: number;
  increaseRatesYearly?: boolean;
  branch?: IBranch
}

export interface ISubcriptionVehicleReplacement {
  vehicle: IVehicle;
  reason: string;
  description: string;
  prevVehicle: IVehicle;
  replacedAt: string;
}

export interface IcoiDetails {
  policyName: string;
  policyNumber: string;
  provider: string;
  expiryDate: string;
  documentUrl: string;
}

export interface IVehicleSwapInfo {
  previousVehicle: IVehicle;
  newVehicle: IVehicle;
  swappedOn: string;
}

export interface ISwapRequest {
  id?: string;
  isActive: Boolean;
  status: string;
  dateForSwap: string;
  createdAt: string;
  events: ISwapRequestEvent[];
  vehicleAllocated?: IVehicle;
  vehicle?: IVehicle;
}

export enum SwapRequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  COMPLETED = "COMPLETED",
  RJECTED = "REJECTED"
}
export interface ISwapRequestEvent {
  eventTime: string;
  eventBy: string;
  userType: string;
  notes: string;
}
export interface INotesInput {
  bookingId: string;
  swapRequestId: string;
  notes: string;
}
export interface IGenericSwapInput {
  bookingId: string;
  swapRequestId: string;
  vehicleId?: string;
}
export interface IDepositDetails {
  amount: number;
  payment: IPayment;
  adjustedAgainst: IInvoice;
  refund: IRefund;
  amountAdjusted: number;
}

export interface IBookingEvent {
  eventType: string;
  eventTime: string;
  user: string;
  previousEndDate: string;
  newEndDate: string;
}

export interface ICreditNote {
  id: string;
  creditNoteRef: string;
  booking?: IBooking;
  invoice: IInvoice;
  creditNoteType: string;
  creditNoteItems: ICreditNoteItem[];
  allocations: ICreditNoteAllocation[];
  totalAmount: number;
  refundedAmount: number;
  availableAmount: number;
  refunds: IRefund[];
  createdAt: string;
  customer?: ICustomer;
  businessCustomer?: IBusinessCustomer;
  paymentsLinked: string[];
  creditNoteUrl?: string;
  sentToXero?: string;
  xeroCreditNoteStatus?: XeroStatus;
}

export interface IBookingCreateInput {
  id?: string;
  pickupServiceLocation: string;
  pickupOtherLocation?: IAddressLocation;
  pickupDateTime: string;
  dropoffServiceLocation: string;
  dropoffOtherLocation?: IAddressLocation;
  dropoffDateTime?: string;
  longTermBooking: boolean;
  flightNumber: string;
  vehicleGroups: IBookingVehicleGroup[];
  insuranceRate: number;
  excess: number;
  insurancePolicy?: string;
  addonRequirements: IAddonRequirementInput[];
  customer?: string;
  approvedDrivers: string[];
  customerType: string;
  rateTypeName: string;
  rateTypeDuration: number;
  billingCycleName?: string;
  billingCycleDuration?: number;
  businessCustomer?: string;
  authorizedSignatory?: string;
  pcoNumber?: boolean;
  smartCarVehicle?: boolean;
  quoteUrl?: string;
  preBookingQuestions?: IBookingQuestionInput[];
  deliveryQuestions?: IBookingQuestionInput[];
  source?: BOOKING_SOURCE;
  locationSurcharges: ILocationSurchargeInput[];
  poNumber?: string;
  setBookingData?: boolean;
  status?: string;
  bookingId?: string;
  isRecurringBilling?: boolean;
  billingFrequency?: number;
  zeroCostBooking?: boolean;
  autoRenewal?: boolean;
  tba?: boolean;
  isCoi?: boolean;
  coiDetails?: IcoiDetails;
  isSubscription?: boolean;
  // activeSubscription?: IBookingSubscriptionCreateInput;
  activeSubscription?: any;
  activeSubscriptionExpanded?: IBookingSubscription;
  isCarShare?: boolean;
  costCenter?: string;
  projectId?: string;
  oneWayRentalFee?: IOneWayRentalFee;
  retainDepositDetails?: boolean;
  bookingCategory: string;
  autoIncrementRatesBy?: number;
  increaseRatesYearly?: boolean;
  testDriveBookingDetails?: ITestDriveBookingDetails;
  driverId?: string;
  slotId?: string;
}

export interface ITestDriveBookingDetails {
  experience?: TestDriveExperiences;
  type?: TestDriveTypes;
  extendedTestDriveHours?: number;
}

export interface IBookingSubscriptionCreateInput {
  startDate: string;
  endDate: string;
  subscription: string;
  vehicle: string;
  duration: number;
  subscriptionAmount: number;
  enrollmentAmount: number;
  unlimitedMileage?: boolean;
  mileageLimit?: number;
  pricePerExtraMile?: number;
}
export interface IBookingSubscription {
  startDate: string;
  endDate: string;
  subscription: ISubscription;
  vehicle: IVehicle;
  duration: number;
  subscriptionAmount: number;
  enrollmentAmount: number;
  unlimitedMileage?: boolean;
  mileageLimit?: number;
  pricePerExtraMile?: number;
  swapsAllowed: number;
}

export interface ISubscriptionArgs {
  subscription: ISubscription;
  pricing: ISubscriptionPricing;
  deposit: number;
}

export interface IBookingQuestionInput {
  question: string;
  answer?: boolean | string;
  answerType: ANSWER_TYPES;
}

export interface ILocationSurchargeInput {
  id: string;
  name: string;
  value: number;
  rate: string;
  type: string;
  tax: {
    title: string;
    value: number;
  };
  payableAmount?: number;
  payableTaxAmount?: number;
}

export interface IBookingExtension {
  id: string;
  dropoffServiceLocation: IServiceLocation;
  startDate: string;
  endDate: string;
  status: string;
  insuranceRate?: number;
  insurancePolicy?: IInsurancePolicyBooking;
  referenceNumber: string;
  extensionNumber: string;
  booking: IBooking;
  invoice: IInvoice;
  addonRequirements: IBookingAddonRequirement[];
  vehicleDetails: IExtensionVehicleDetail[];
  otherCharges: IExtensionOtherCharge[];
  paymentDetails: IPaymentDetails;
}

export interface IExtensionVehicleDetail {
  vehicleIds: string[];
  vehicleGroupId: string;
  quantity: number;
  basePrice: number;
  priceRuleAmount?: number;
  priceRuleOperator?: PriceRuleOperator;
  priceRuleValueType?: ValueType;
  priceRuleTriggerType?: string;
  modifiedPrice: number;
  description: string;
}

export interface IExtensionOtherCharge {
  quantity: number;
  unitPrice: number;
  description: string;
}

export interface IBookingExtensionCreateInput {
  dropoffServiceLocation: string;
  startDate: string;
  endDate: string;
  bookingId: string;
  insuranceDetails?: IExtensionInsuranceDetailsInput;
  vehicleDetails: IExtensionVehicleDetailsInput[];
  addonDetails: IExtensionAddonDetailsInput[];
  otherChargesDetails: IExtensionOtherChargesDetailsInput[];
  subscriptionDetails: ISubscriptionDetailsInput;
}

export interface ISubscriptionDetailsInput {
  subscriptionAmount: number;
  modifiedSubscriptionAmount: number;
  vehicleId: string;
}
export interface IExtensionInsuranceDetailsInput {
  insurancePolicy: string;
  unitPrice: number;
  modifiedPrice: number;
  description: string;
}

export interface IExtensionVehicleDetailsInput {
  vehicleIds: string[];
  vehicleGroupId: string;
  quantity: number;
  basePrice: number;
  priceRuleAmount?: number;
  priceRuleOperator?: PriceRuleOperator;
  priceRuleValueType?: ValueType;
  priceRuleTriggerType?: string;
  modifiedPrice: number;
  description: string;
}

export interface IExtensionAddonDetailsInput {
  quantity: number;
  addonId: string;
  unitPrice: number;
  modifiedPrice: number;
  description: string;
}

export interface IExtensionOtherChargesDetailsInput {
  quantity: number;
  unitPrice: number;
  description: string;
}
export interface IBookingNoteInput {
  description: string;
  createdBy: string;
  createdDate: string;
}

export interface ICurrentBookingSchedule {
  vehicle: IVehicle;
  booking: IBooking;
}

export interface IBookingNoteInput {
  id?: string;
  description: string;
  createdBy: string;
  createdDate: string;
}

export interface IBookingNote {
  id?: string;
  description: string;
  createdBy: IAdminUser;
  createdDate: string;
}

export interface IBookingAdditionalInformation {
  description: string;
}

export interface INoteFromWebsite {
  description: string;
  createdAt: string;
}

export interface IVehicleBooking {
  licencePlate: string;
}
export interface IPaymentDetails {
  startDate: string;
  endDate: string;
  taxAmount: number;
  rentalAmount: number;
  insuranceAmount: number;
  discountAmount: number;
  addonsAmount: number;
  totalPayableAmount: number;
  rentalTime: number;
  isProrated?: boolean;
  standardBillingDays?: number;
  proratedBillingDays?: number;
}

export interface IInsurancePolicyBooking {
  id: string;
  name: string;
  description: string;
  excess?: number;
}

export interface ITariffPriceRule {
  id: string;
  name: string;
}

export interface IDiscountInput {
  isPercent: boolean;
  code?: string;
  amount: number;
}

export interface IDiscount extends IDiscountInput {
  appliedBy: string;
  appliedDate: string;
}

export interface IAddressLocation {
  street: string;
  city: string;
  fullAddress: string;
  state: string;
  country: string;
  zipcode: string;
  location: IGeoLocation;
}

export interface IGeoLocation {
  type: string;
  latitude: number;
  longitude: number;
}

export interface ITax extends ITaxInput {
  appliedBy: string;
  appliedDate: string;
  payableAmount: number;
}

export interface ITaxInput {
  amount: number;
  code: string;
  rate: string;
  type: string;
  isVehicleGroupSpecific: boolean;
  vehicleGroups?: string[];
  subdivisionOptIn: boolean;
  subdivisionValue: number;
  subdivisionType: string;
  subdivisionRate: string;
}

export type BookingPayloadType =
  | IBaseBookingDetails
  | IVehicleRequirementsPayload
  | IBookingCustomerDetails
  | IDiscountPayloadType
  | IQuoteUpdatePayloadType
  | IVehicleGroup
  | IAddonPayloadType
  | IBookingState
  | IInvoicesPayloadType
  | IInsuranceBooking;

export interface IInsuranceBooking {
  insuranceRate: number;
  excess: number;
  insurancePolicy?: IInsurancePolicyBooking;
}
export interface IBookingSchedule {
  tba: boolean;
  id: string;
  startDate: string;
  endDate: string;
  vehicle: IVehicle;
  status: number;
  vehicleStatus: string;
  dropoffCheck?: IVehicleCheckInfo;
  pickupCheck?: IVehicleCheckInfo;
  activeVehicleDamages?: [INewVehicleDamage];
  driver?: IAssignedDriver;
  linkedActions?: ILinkedAction[]
}

export interface IAssignedDriver {
  id: string;
  note?: string;
}

export interface IVehicleCheckInfo {
  vehicleState: IVehicleState;
  customerInfo: VehicleCheckCustomerInfo;
  vehicleStatePdfUrl: string;
}

export interface IAddonRequirement {
  addon: IAddonType;
  quantity: number;
  name: string;
  hasFixedRate: boolean;
  rate: number;
  payableAmount: number;
  displayName?: string;
  taxable?: boolean;
  tax: {
    title: string;
    value: number;
  };
}

export interface IBookingAddonType extends IAddonType {
  id: string;
}

export interface IBookingAddonRequirement extends IAddonRequirement {
  addon: IBookingAddonType;
}

export interface IAddonRequirementInput {
  addon: string;
  quantity: number;
  name: string;
  hasFixedRate: boolean;
  rate: number;
  displayName?: string;
  taxable?: boolean;
  tax?: {
    title: string | undefined;
    value: number | undefined;
  };
}

export interface IBookingVehicleGroup {
  vehicleGroup: string | IBookingVehiclePriceGroup;
  name: string;
  vehicleIds: string[];
  vehicles?: [IVehicle];
  baseRate: number;
  priceRuleOperator?: PriceRuleOperator;
  priceRuleValueType?: ValueType;
  priceRuleTriggerType?: string;
  priceRuleAmount?: number;
  priceRuleName?: string;
  priceRule?: string;
  deposit: number;
  mileageLimit: number;
  pricePerExtraMile: number;
  unlimitedMileage: boolean;
  count: number;
  activeVehicleSchedules?: IBookingSchedule[];
  locationSurcharges?: IPriceRule[];
  branchId?: string;
}

export enum CreditNoteTypes {
  REFUNDABLE = "REFUNDABLE",
  ADJUSTABLE = "ADJUSTABLE"
}

export enum PriceRuleOperator {
  "SURGE" = "SURGE",
  "DISCOUNT" = "DISCOUNT"
}

export enum ValueType {
  FIXED = "FIXED",
  VARIABLE = "VARIABLE"
}
export interface IVehicleGroup {
  id?: string;
  bodyType: string;
  make: string;
  model: string;
  year: number;
  colour: string;
  fuelType: string;
  vehicleId?: string;
  licencePlate?: string;
  numberOfDoors: number;
  numberOfSeats: number;
  transmission: string;
  baseRate: IBaseRate[];
  deposit: number;
  mileageLimit: number;
  pricePerExtraMile: number;
  features: string[];
  imageUrl?: string;
}

export interface IBaseRate {
  baseRate: number;
  startDate: string;
  endDate?: string;
  priceRuleOperator?: PriceRuleOperator;
  priceRuleValueType?: ValueType;
  priceRuleTriggerType?: string;
  priceRuleAmount?: number;
  priceRuleName?: string;
}

export interface IAddonPayloadType {
  addonRequirements: IAddonRequirement[];
}

export interface IInvoicesPayloadType {
  invoices: IInvoice[];
}

export interface IQuoteUpdatePayloadType {
  referenceNumber: string;
  dateCreated: string;
}

export interface IDiscountPayloadType {
  discount: IDiscount;
}

export enum BookingPayloadTypes {
  BASE_DETAILS = "BASE_DETAILS",
  VEHICLE_SELECTION = "VEHICLE_SELECTION"
}

export interface IBaseBookingDetails {
  __type: BookingPayloadTypes;
  pickupServiceLocation: IServiceLocation;
  pickupDateTime: string;
  dropoffServiceLocation: IServiceLocation;
  dropoffDateTime?: string;
  flightNumber: string;
  longTermBooking: boolean;
  customerType: string;
  rateTypeName: string;
  rateTypeDuration: number;
  billingCycleName?: string;
  billingCycleDuration?: number;
  businessCustomer?: IBookingBusinessCustomer;
  authorizedSignatory?: string;
}

export interface IVehicleRequirementsPayload {
  __type: BookingPayloadTypes;
  vehicleRequirements: IVehicleGroup[];
}

export interface IDefinition {
  name: string;
  id: string;
}

export interface IFeature {
  name: string;
}

export interface IBookingCustomerDetails {
  customer: ICustomerDetails;
}

export interface ICustomerDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  customerPCONumber?: string;
  gender?: string;
  phoneNumber: IPhoneNumber;
  dateOfBirth?: string;
  location: ILocation;
  license?: IlicenseDetails;
  approvedDrivers?: ICustomer[] | string[];
  address?: string;
  verificationMethod?: string;
  nationalInsuranceNumber?: string;
}

export interface IBookingUsers {
  id: string;
  firstName: string;
  lastName: string;
}

interface IPhoneNumber {
  phone: string;
  country: string;
}

export interface IlicenseDetails {
  licenseNumber?: string;
  dateOfExpiry?: string;
  authority?: string;
  endorsement?: string;
  images?: string[];
}

export interface IInsuranceAddon {
  policyId: string;
  policyName: string;
  description: string;
  rateName: string;
  rateDuration: number;
  rate: number;
  longTerm: boolean;
  excess: number;
}

export enum BookingStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMATION_IN_PROCESS = "CONFIRMATION_IN_PROCESS",
  CONFIRMED = "CONFIRMED",
  IN_PROGRESS = "IN_PROGRESS",
  QUOTE = "QUOTE",
  DRAFT = "DRAFT",
  REQUIRES_APPROVAL = "REQUIRES_APPROVAL"
}

export enum BookingStatusText {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMATION_IN_PROCESS = "CONFIRMATION IN PROCESS",
  CONFIRMED = "CONFIRMED",
  IN_PROGRESS = "IN PROGRESS",
  QUOTE = "QUOTE",
  DRAFT = "DRAFT",
  REQUIRES_APPROVAL = "REQUIRES APPROVAL"
}

export enum BookingExtensionStatus {
  CONFIRMED = "CONFIRMED",
  QUOTE = "QUOTE"
}

export interface IAccountManager {
  id: string;
  firstName: string;
  lastName: string;
}

export interface IBookingBusinessCustomer {
  id: string;
  businessName: string;
  accountManager: IAccountManager | string;
  registrationNumber: string;
  VAT: string;
  registeredAddress: ILocation;
  tradingAddressSame: boolean;
  tradingAddress: ILocation;
  approvedDrivers: IBusinessCustomerCommonInputWithId[];
  authorizedSignatories: IBusinessCustomerCommonInputWithId[];
  billing: IBusinessCustomerBillingInput;
  contact: IBusinessCustomerCommonInput;
  documents: IBusinessCustomerDocumentInput[];
  overrideNetPaymentTerms?: boolean;
  netPaymentTerms?: number;
  dailyBillingCycle?: number;
  weeklyBillingCycle?: number;
  monthlyBillingCycle?: number;
  status: string;
  declinedReason: string;
}
export interface CheckInfo {
  vehicleState: IVehicleState;
  customerInfo: VehicleCheckCustomerInfo;
  paymentInfo: IVehicleCheckoutPaymentInfo;
}
export interface VehicleCheckCustomerInfo {
  tncCheck: boolean;
  name: string;
  signature: string;
}

export interface IVehicleCheckoutPaymentInfo {
  paymentMethod: string;
  amount: number;
}

export interface ICreateCreditNoteInput {
  booking: string;
  invoice: string;
  creditNoteInput: ICreditNoteInput;
}
export interface ICreditNoteInput {
  invoiceRef: string;
  invoiceId: string;
  paidAmount: number;
  totalAmount: number;
  creditNoteType: string;
  creditNoteItems: ICreditNoteItem[];
}

export interface ICreditNoteItem {
  type: string;
  description: string;
  originalPrice: number;
  originalTaxAmount?: number;
  totalAmount: number;
  quantity: number;
  unitPrice: number;
  subTotal?: number;
  duration?: number;
  taxRate?: number;
  taxAmount?: number;
  taxDescription?: string[];
}

export interface IRefund {
  mode: string;
  amount: number;
  refundRef: string;
  status: string;
  currency?: string;
  id: string;
  paymentIntent?: string;
  paymentGateway: string;
  dateCreated?: string;
}

export enum ANSWER_TYPES {
  YES_NO = "YES_NO",
  DROP_DOWN = "DROP_DOWN",
  TEXT = "TEXT",
  CHECKBOX = "CHECKBOX"
}

export enum BOOKING_SOURCE {
  B2B = "B2B",
  B2B2C = "B2B2C",
  B2B_MOBILE = "B2B_MOBILE",
  B2B2C_MOBILE = "B2B2C_MOBILE"
}
export interface ICreditNoteAllocation {
  invoice: IInvoice;
  amount: number;
  allocatedAt: string;
}

export enum IDownloadHistoryType {
  INVOICE_FINANCE_HISTORY = "invoicefinancehistory"
}

export enum BookingEventTypes {
  EXTENSION = "EXTENSION",
  EARLY_TERMINATION = "EARLY_TERMINATION"
}

export const BILLING_CYCLE_NAME = {
  HOURLY: "hourly",
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly"
};

export enum BOOKING_CATEGORY {
  RENTAL = "RENTAL",
  SUBSCRIPTION = "SUBSCRIPTION",
  TEST_DRIVE = "TEST_DRIVE",
  COURTESY_CAR = "COURTESY_CAR"
}

export const bookingCategoryLabels = {
  [BOOKING_CATEGORY.RENTAL]: "Rental",
  [BOOKING_CATEGORY.SUBSCRIPTION]: "Subscription",
  [BOOKING_CATEGORY.TEST_DRIVE]: "Test Drive",
  [BOOKING_CATEGORY.COURTESY_CAR]: "Courtesy Car"
};

export enum RuleItems {
  TYPE_OF_BOOKING = "Type of Booking",
  DISTANCE_FROM_LOCATION = "Distance From Location",
  SUPPLIER_RATING = "Supplier Rating",
  SKILLS = "Skills"
}

export enum Driverkills {
  DEMO_CAR_DELIVERY = "Demo Car Delivery",
  NEW_CAR_DELIVERY = "New Car Delivery",
  COURTESY_CAR_DELIVERY = "Courtesy Car Delivery",
}

export interface IDeliveryDriver {
  name: string;
  logisticsPartner: string;
  email: string;
  phoneNumber: string;
  licenceNumber: string;
  licenceExpiryDate: string;
  licenceImage: string[];
};

export interface IAssignedTo {
  active: boolean;
  customerId: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  role: string;
  partner: IPartner;
  partnerProfile: {
    license?: IlicenseDetails
  };
}

export interface ILinkedAction {
  jobLinked: {
    id: string;
    assignedTo: IAssignedTo
  };
}

