import { gql } from "@apollo/client";

export const CREATE_ORGANISATION = gql`
  mutation createOrganisation(
    $tenancyId: ID!
    $organisation: OrganisationInput!
  ) {
    createOrganisation(tenancyId: $tenancyId, organisation: $organisation) {
      id
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      enableCostCenter
      costCenters {
        name
        projectIds
      }
      owner {
        email
        id
      }
      branches {
        id
        name
        depositCollection
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        emailId
      }
      sageAccounting {
        active
      }
      locale
      name
      emailId
      phoneNumber {
        phone
        country
      }
      termsAndConditionsUrl
      logoUrl
      currency
      orgVatNumber
      orgRegNumber
      insuranceDeclaration
      cancellationPolicy
      maxImagesPerDamage
      bookingCategories {
        isActive
        type
        default
      }
      supplierPortalEnabled
      enableDriverAllocationRules
      driverAllocationRules {
        ruleName
        value
      }
    }
  }
`;

export const CREATE_ORGANIZATION_SIMPLE = gql`
  mutation createOrganization($organization: OrganizationInput!) {
    createOrganization(organization: $organization) {
      id
      country
      location
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      email
      secondaryphone {
        phone
        country
      }
      phonenumber {
        phone
        country
      }
      siteID
      createdBy
      modifiedBy
    }
  }
`;

export const CREATE_ACCOUNTING_CODE_MAPPING = gql`
  mutation createAccountCodeMapping(
    $accountCodeMapping: AccountCodeMappingInput!
  ) {
    createAccountCodeMapping(accountCodeMapping: $accountCodeMapping) {
      id
      accountingName
      codeMapping {
        line_item
        code
      }
      tenancyId
      organisationId
    }
  }
`;
