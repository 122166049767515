import { gql } from "@apollo/client";

export const GET_VEHICLE_ASSETS = gql`
  query getVehicleAssets($vehicleId: String!) {
    getVehicleAssets(vehicleId: $vehicleId) {
      id
      registrationNumber
      serialNumber
      assetTag
      assetType
    }
  }
`;

export const GET_VEHICLE_ASSETS_DAMAGES = gql`
  query getVehicleAssetsDamages($vehicleId: String!) {
    getVehicleAssetsDamages(vehicleId: $vehicleId) {
      id
      referenceNumber
      asset {
        id
        assetTag
      }
      circumstance
      incidentDate
      estimatedRepairDate
      totalExpense
    }
  }
`;
