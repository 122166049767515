import { gql } from "@apollo/client";

export const GET_VEHICLE_SERVICE = gql`
  query vehicleService($id: String!) {
    vehicleService(id: $id) {
      id
      referenceNumber
      vehicleId
      branchId
      serviceType
      scheduleType{
        byMonth,
        byMileage
      }
      serviceProviderId
      serviceProviderName
      serviceProviderAddress{
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      appointmentDate
      startDate
      endDate
      completionDate
      serviceAnniversaryByMileage
      description
      totalExpenses
      taxPercent
      odometerReading
      driverName
      driverEmail
      status
      jobSheetStatus
      jobSheetEmailLastSentOn
      notes {
        id
        description
        createdDate
        createdBy{
          id
          firstName
          lastName
        }
      }
      documents {
        title
        documentName
        documentType
        documentUrl
        expiryDate
        createdAt
      }
      damages {
        id
      }
      jobSheet{
        id
        name
        description
        cost
      }
      insuranceInformation{
        providerName
        policyNumber
        expiryDate
        claimAmount
        documentUrl
      }
      purchaseOrderInformation{
        poNumber
        documentUrl
      }
      epyxReferenceNumber
      vehicleAssets {
        id
        registrationNumber
        serialNumber
        identificationNumber
        assetTag
        make
        model
        color
        frogDiagram
        assetType
        isActive
      }
      vehicleAssetsDamages {
        id
        referenceNumber
        asset{
        id
        assetTag
        }
        bookingRef
        circumstance
        incidentDate
        estimatedRepairDate
        reportDate
        totalExpense
        status
        damages{
          title
          damageType
          damageKind
          damageArea
          images
          descriptions
        }
      }
    }
  }
`;
