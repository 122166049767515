import { gql } from "@apollo/client";

export const GET_BRANCH_CONNECTIONS = gql`
  query getFleetShares {
    getFleetShares {
      id
      externalBranches {
        branchId
        branch {
          name
        }
        rentalCostType
        fuelCost
        driverCost
        transitTime
      }
      sharedFleetData {
        vehicleGroup {
          id
          name
        }
        externalVehicleGroup {
          id
          name
        }
        vehicles {
          id
          licencePlate
        }
      }
      vehicleIds
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const GET_BRANCH_CONNECTION = gql`
  query getFleetShareById($fleetShareId: ID!) {
    getFleetShareById(fleetShareId: $fleetShareId) {
      id
      externalBranches {
        branchId
        branch {
          name
        }
        rentalCostType
        fuelCost
        driverCost
        transitTime
      }
      sharedFleetData {
        vehicleGroup {
          id
          name
        }
        externalVehicleGroup {
          id
          name
        }
        vehicles {
          id
          licencePlate
        }
      }
      oneWayRentalFee {
        amount
        taxable
        tax {
          title
          type
          value
        }
      }
      vehicleIds
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const GET_FLEET_MOVEMENTS = gql`
  query getFleetMovements($limit: Int!, $offset: Int!) {
    getFleetMovements(limit: $limit, offset: $offset) {
      id
      referenceNumber
      vehicleId
      vehicle {
        licencePlate
        id
      }
      status
      movementSource
      eventStart
      estimatedDelivery
      estimatedCheckout
      bookingId
      externalBranchId
      jobType
      taskDate
      externalBranch {
        id
        name
      }
      branchId
      branch {
        id
        name
      }
      vehicleServiceId
      driver {
        id
        firstName
        lastName
      }
      source {
        id
        name
      }
      destination {
        id
        name
      }
      partner {
        id
        partnerName
      }
    }
  }
`;

export const GET_FlEET_MOVEMENT_COUNT = gql`
  {
    getFleetMovementsCount
  }
`;

export const SEARCH_FLEET_MOVEMENT = gql`
  query searchFleetMovements($q: String!) {
    searchFleetMovements(q: $q) {
      id
      referenceNumber
      vehicleId
      vehicle {
        licencePlate
        id
      }
      status
      movementSource
      eventStart
      estimatedDelivery
      estimatedCheckout
      bookingId
      externalBranchId
      externalBranch {
        id
        name
      }
      branchId
      branch {
        id
        name
      }
      vehicleServiceId
      driver
      source {
        id
        name
      }
      destination {
        id
        name
      }
    }
  }
`;

export const GET_FLEET_MOVEMENT = gql`
  query getFleetMovementById($movementId: ID!) {
    getFleetMovementById(movementId: $movementId) {
      id
      referenceNumber
      vehicleId
      vehicle {
        id
        licencePlate
        make
        model
        year
        status
      }
      status
      transitTime
      movementSource
      eventStart
      estimatedDelivery
      estimatedCheckout
      bookingId
      booking {
        id
        referenceNumber
        customerType
        customer {
          firstName
          lastName
        }
        businessCustomer {
          businessName
        }
        pickupDateTime
        status
      }
      externalBranchId
      externalBranch {
        id
        name
      }
      branchId
      branch {
        id
        name
      }
      vehicleServiceId
      driver {
        id
        firstName
        lastName
      }
      source {
        id
        name
      }
      destination {
        id
        name
      }
      partner {
        id
        partnerName
      }
    }
  }
`;

export const GET_BRANCHES = gql`
  query branches($tenancyId: ID!, $organisationId: ID!) {
    branches(tenancyId: $tenancyId, organisationId: $organisationId) {
      id
      name
    }
  }
`;

export const GET_SERVICE_LOCATIONS_IN_ORG = gql`
  query getServiceLocations($organisationId: String!) {
    getServiceLocations(organisationId: $organisationId) {
      id
      name
      address {
        fullAddress
      }
      branch {
        id
        name
      }
    }
  }
`;

export const GET_VEHICLES = gql`
  {
    vehicles {
      licencePlate
      make
      model
      year
      id
      status
      canTransfer
      bodyType
      status
      holds {
        id
        startDate
        endDate
      }
      activeTransferMovement
      canRemoveFromFleetshare
    }
  }
`;

export const GET_VEHICLE_GROUPS = gql`
  query vehicleGroups {
    vehicleGroups {
      id
      name
      isActivated
      vehicles {
        licencePlate
        make
        model
        year
        id
        status
        isGhostVehicle
      }
    }
  }
`;

export const BRANCH_SHARING_VEHICLES = gql`
  query getActiveVehiclesForBranch($q: String!) {
    getActiveVehiclesForBranch(q: $q) {
      licencePlate
      vehicleId
      vehicleGroups {
        name
        vehicleGroupId
      }
    }
  }
`;

export const GET_USER_DRIVERS = gql`
  query getDriverUsersInBranch {
    getDriverUsersInBranch {
      id
      firstName
      lastName
    }
  }
`;