import { gql } from "@apollo/client";

export const GET_ORGANISATIONS = gql`
  query Organisations {
    organisations {
      id
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      locale
      sepaEnabled
      bacsEnabled
      cashEnabled
      cardEnabled
      offlineBankTransferEnabled
      payByLinkEnabled
      payOnCollectionEnabled
      autoChargeEnabled
      crossLocationBookingEnabled
      name
      emailId
      phoneNumber {
        phone
        country
      }
      logoUrl
      currency
      orgVatNumber
      orgRegNumber
      branches {
        id
        timeZone
        emailId
        depositCollection
        netPaymentTerms
        dailyBillingCycle
        weeklyBillingCycle
        monthlyBillingCycle
        owner {
          id
          email
        }
        useThresholds
        offenceThreshold
        pointThreshold
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        rentalAgreementSettings {
          individual {
            rentalCompanyDetails
            bookingDetails
            vehicleDetails
            driverDetails
            bookingExtension
            addonDetails
            insuranceDetails
            paymentInfo
            customerDetails
            insuranceDeclaration
            cancellationPolicy
            customerDeclaration
            preBookingQuestions
            additionalDriverQuestions
            deliveryQuestions
            additionalInfo
            tnc
            additonalTnc
            securityDeposit
            oldVehicleDetails
            subscriptionDetails
          }
          business {
            rentalCompanyDetails
            bookingDetails
            vehicleDetails
            driverDetails
            bookingExtension
            addonDetails
            insuranceDetails
            paymentInfo
            customerDetails
            insuranceDeclaration
            cancellationPolicy
            customerDeclaration
            preBookingQuestions
            additionalDriverQuestions
            deliveryQuestions
            additionalInfo
            tnc
            additonalTnc
            securityDeposit
            subscriptionDetails
            oldVehicleDetails
          }
        }
        subscriptionAgreementSettings {
          individual {
            rentalCompanyDetails
            vehicleDetails
            bookingExtension
            addonDetails
            insuranceDetails
            paymentInfo
            customerDetails
            insuranceDeclaration
            cancellationPolicy
            customerDeclaration
            preBookingQuestions
            additionalDriverQuestions
            deliveryQuestions
            additionalInfo
            tnc
            additonalTnc
            securityDeposit
            oldVehicleDetails
            subscriptionDetails
          }
          business {
            rentalCompanyDetails
            vehicleDetails
            bookingExtension
            addonDetails
            insuranceDetails
            paymentInfo
            customerDetails
            insuranceDeclaration
            cancellationPolicy
            customerDeclaration
            preBookingQuestions
            additionalDriverQuestions
            deliveryQuestions
            additionalInfo
            tnc
            additonalTnc
            securityDeposit
            subscriptionDetails
            oldVehicleDetails
          }
        }
        rentalAgreementSettingsMap {
          individual {
            customerDetails {
              registrationNumber
              name
              address
              phoneNumber
              email
              businessName
              insuranceNumber
              dateOfBirth
              licenseNumber
              licenseExpiry
              licenseIssue
              licenseIssuedBy
              profession
            }
            bookingDetails {
              pickup
              dropoff
              additionalDrivers
              poNumber
              referenceNumber
              tba
            }
            vehicleDetails {
              makeModel
              features
              registrationNumber
              vin
              pcoNumber
              mileageLimit
              addons
              color
              year
              transmission
              numberOfDoors
              numberOfSeats
              assetTag
              vehicleGroup
              vehiclePickup
              vehicleDropoff
            }
            oldVehicleDetails {
              makeModel
              features
              registrationNumber
              vin
              pcoNumber
              mileageLimit
              addons
              color
              year
              transmission
              numberOfDoors
              numberOfSeats
              assetTag
              vehicleGroup
              vehiclePickup
              vehicleDropoff
            }
            driverDetails {
              name
              dateOfBirth
              phoneNumber
              address
              email
              licenseNumber
              licenseExpiry
              licenseIssue
              licenseIssuedBy
              customerPCONumber
            }
            bookingExtension {
              extensionNumber
              referenceNumber
              extensionPeriod
              invoiceRef
              dropOffLocation
            }
            paymentInfo {
              rentalAmount
              addonAmount
              taxAmount
              totalPayableAmount
              amountPaid
              amountDue
              discountAmount
              securityDeposit
              insuranceAmount
            }
            subscriptionDetails {
              subscription
              pickup
              subscriptionDate
              dropoff
              poNumber
              subscriptionPrice
              duration
            }
            addonDetails {
              addonName
              description
              quantity
            }
            insuranceDetails {
              insuranceName
              description
              price
            }
          }
          business {
            customerDetails {
              registrationNumber
              name
              address
              phoneNumber
              email
              businessName
              insuranceNumber
              dateOfBirth
              licenseNumber
              licenseExpiry
              licenseIssue
              licenseIssuedBy
              profession
            }
            bookingDetails {
              pickup
              dropoff
              additionalDrivers
              poNumber
              referenceNumber
              tba
            }
            vehicleDetails {
              makeModel
              features
              registrationNumber
              vin
              pcoNumber
              mileageLimit
              addons
              color
              year
              transmission
              numberOfDoors
              numberOfSeats
              assetTag
              vehicleGroup
              vehiclePickup
              vehicleDropoff
            }
            oldVehicleDetails {
              makeModel
              features
              registrationNumber
              vin
              pcoNumber
              mileageLimit
              addons
              color
              year
              transmission
              numberOfDoors
              numberOfSeats
              assetTag
              vehicleGroup
              vehiclePickup
              vehicleDropoff
            }
            driverDetails {
              name
              dateOfBirth
              phoneNumber
              address
              email
              licenseNumber
              licenseExpiry
              licenseIssue
              licenseIssuedBy
              customerPCONumber
            }
            bookingExtension {
              extensionNumber
              referenceNumber
              extensionPeriod
              invoiceRef
              dropOffLocation
            }
            paymentInfo {
              rentalAmount
              addonAmount
              taxAmount
              totalPayableAmount
              amountPaid
              amountDue
              discountAmount
              securityDeposit
              insuranceAmount
            }
            subscriptionDetails {
              subscription
              pickup
              subscriptionDate
              dropoff
              poNumber
              subscriptionPrice
              duration
            }
            addonDetails {
              addonName
              description
              quantity
            }
            insuranceDetails {
              insuranceName
              description
              price
            }
          }
        }
        subscriptionAgreementSettingsMap {
          individual {
            customerDetails {
              registrationNumber
              name
              address
              phoneNumber
              email
              businessName
              insuranceNumber
              dateOfBirth
              licenseNumber
              licenseExpiry
              licenseIssue
              licenseIssuedBy
              profession
            }
            bookingDetails {
              pickup
              dropoff
              additionalDrivers
              poNumber
              referenceNumber
              tba
            }
            vehicleDetails {
              makeModel
              features
              registrationNumber
              vin
              pcoNumber
              mileageLimit
              addons
              color
              year
              transmission
              numberOfDoors
              numberOfSeats
              assetTag
              vehicleGroup
              vehiclePickup
              vehicleDropoff
            }
            oldVehicleDetails {
              makeModel
              features
              registrationNumber
              vin
              pcoNumber
              mileageLimit
              addons
              color
              year
              transmission
              numberOfDoors
              numberOfSeats
              assetTag
              vehicleGroup
              vehiclePickup
              vehicleDropoff
            }
            driverDetails {
              name
              dateOfBirth
              phoneNumber
              address
              email
              licenseNumber
              licenseExpiry
              licenseIssue
              licenseIssuedBy
              customerPCONumber
            }
            bookingExtension {
              extensionNumber
              referenceNumber
              extensionPeriod
              invoiceRef
              dropOffLocation
            }
            paymentInfo {
              rentalAmount
              addonAmount
              taxAmount
              totalPayableAmount
              amountPaid
              amountDue
              discountAmount
              securityDeposit
              insuranceAmount
            }
            subscriptionDetails {
              subscription
              pickup
              subscriptionDate
              dropoff
              poNumber
              subscriptionPrice
              duration
            }
            addonDetails {
              addonName
              description
              quantity
            }
            insuranceDetails {
              insuranceName
              description
              price
            }
          }
          business {
            customerDetails {
              registrationNumber
              name
              address
              phoneNumber
              email
              businessName
              insuranceNumber
              dateOfBirth
              licenseNumber
              licenseExpiry
              licenseIssue
              licenseIssuedBy
              profession
            }
            bookingDetails {
              pickup
              dropoff
              additionalDrivers
              poNumber
              referenceNumber
              tba
            }
            vehicleDetails {
              makeModel
              features
              registrationNumber
              vin
              pcoNumber
              mileageLimit
              addons
              color
              year
              transmission
              numberOfDoors
              numberOfSeats
              assetTag
              vehicleGroup
              vehiclePickup
              vehicleDropoff
            }
            oldVehicleDetails {
              makeModel
              features
              registrationNumber
              vin
              pcoNumber
              mileageLimit
              addons
              color
              year
              transmission
              numberOfDoors
              numberOfSeats
              assetTag
              vehicleGroup
              vehiclePickup
              vehicleDropoff
            }
            driverDetails {
              name
              dateOfBirth
              phoneNumber
              address
              email
              licenseNumber
              licenseExpiry
              licenseIssue
              licenseIssuedBy
              customerPCONumber
            }
            bookingExtension {
              extensionNumber
              referenceNumber
              extensionPeriod
              invoiceRef
              dropOffLocation
            }
            paymentInfo {
              rentalAmount
              addonAmount
              taxAmount
              totalPayableAmount
              amountPaid
              amountDue
              discountAmount
              securityDeposit
              insuranceAmount
            }
            subscriptionDetails {
              subscription
              pickup
              subscriptionDate
              dropoff
              poNumber
              subscriptionPrice
              duration
            }
            addonDetails {
              addonName
              description
              quantity
            }
            insuranceDetails {
              insuranceName
              description
              price
            }
          }
        }
      }
      supplierPortalEnabled
      enableDriverAllocationRules
      driverAllocationRules {
        ruleName
        value
      }
    }
  }
`;

export const GET_ORGANISATION = gql`
  query Organisation($organisationId: ID!) {
    organisation(organisationId: $organisationId) {
      id
      allowPreSigningAgreement
      enableAutoCreditCheck
      creditLimits {
        type
        lowerLimit
        upperLimit
      }
      enableCostCenter
      costCenters {
        name
        projectIds
      }
      crossLocationBookingEnabled
      requiresBookingApproval
      closeGroupSharingEnabled
      enableIdentityVerification
      allowCarSharing
      disableMapForCarShareVehicles
      inspectionCheckEnabled
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      locale
      name
      emailId
      phoneNumber {
        phone
        country
      }
      termsAndConditionsUrl
      logoUrl
      owner {
        id
        email
      }
      currency
      sepaEnabled
      bacsEnabled
      cashEnabled
      cardEnabled
      offlineBankTransferEnabled
      payByLinkEnabled
      payOnCollectionEnabled
      autoChargeEnabled
      requiresCustomerVerification
      enableIdentityVerification
      orgVatNumber
      orgRegNumber
      stripeAccountId
      refundPolicy {
        flatCharge
        refundRules {
          charge
          timeUnit
          timeFrame
        }
      }
      creditSafeCredentials {
        username
        password
      }
      branches {
        id
        timeZone
        name
        corporateRateCardEnabled
        autoColorEnabled
        netPaymentTerms
        dailyBillingCycle
        weeklyBillingCycle
        monthlyBillingCycle
        useThresholds
        offenceThreshold
        pointThreshold
        phoneNumber {
          phone
          country
        }
        emailId
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        owner {
          id
          email
        }
      }
      # taxes moved to branch level
      # taxes {
      #   name
      #   rate
      #   type
      # }
      sageAccounting {
        active
      }
      convergeCredentials {
        merchantAccountId
        merchantAlias
        merchantSecret
      }
      convergeUSCredentials {
        ssl_account_id
        ssl_user_id
        ssl_pin
        ssl_vendor_id
      }
      insuranceDeclaration
      cancellationPolicy
      businessCustomerTags
      maxImagesPerDamage
      convergeEnabled
      bookingCategories {
        isActive
        type
        default
      }
      supplierPortalEnabled
      enableDriverAllocationRules
      driverAllocationRules {
        ruleName
        value
      }
      extendedTestDriveMaxHours
    }
  }
`;

export const GET_ORGANISATIONS_FOR_XERO_CODES_MAPPING = gql`
  query organisations {
    organisations {
      id
      name
    }
  }
`;

export const GET_ORGANISATIONS_XERO_ACCOUNTS_CODES = gql`
  query getXeroAccounts($tenancyId: String!, $organisationId: String!) {
    getXeroAccounts(tenancyId: $tenancyId, organisationId: $organisationId) {
      id
      accounts {
        AccountID
        Class
        Code
        Name
        Description
      }
    }
  }
`;

export const GET_ACCOUNTING_CODE_MAPPING = gql`
  query getAccountcodesMapping($tenancyId: String!, $organisationId: String!) {
    getAccountcodesMapping(
      tenancyId: $tenancyId
      organisationId: $organisationId
    ) {
      id
      accountingName
      codeMapping {
        line_item
        code
      }
      tenancyId
      organisationId
    }
  }
`;