import React, { useEffect, useRef, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import styles from "../index.module.css";
import Typography from '@mui/material/Typography';
import { useLazyQuery } from '@apollo/client';
import { GET_VEHICLES_UTILISATION_REPORT } from '../../../../../graphql/reports/getVehiclesUtilizatonReportQuery';
import { GET_VEHICLE_GROUPS_PRICE } from '../../../../../graphql/priceRules/getVehiclesGroupsQuery';
import { IVehiclePriceGroup } from '../../../../../reducers/vehiclePriceGroups/types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DOWNLOAD_GROUP_VEHICLE_UTITLIZATION_REPORT } from '../../../../../graphql/reports/downloadGroupVehicleUtilizationReport';
import { ApolloError } from "@apollo/client";
import { DATE_TYPE, formatGraphQLErrorMessage, useContainerDimensions } from "../../../../common/utils";
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { DateTime as d } from "luxon";
import { getQuartersList } from '../../utils';
import Flatpickr from "react-flatpickr";
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
interface IProps {
  licensePlate: string
}

const StyledInput = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#f0f0f0',
      fontSize: 13,
      padding: "8px !important",
      border: 0,
      borderRadius: 6,
    },
    '& fieldset': {
      border: 0
    }
  }
})(TextField);
interface IChartData {
  "LicencePlate": string,
  "OnHire": number,
  "OffHire": number,
  "OnHold": number,
  "InService": number
}

export const UtilisationChart: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const ref = useRef(null);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<string>("");
  const [chartData, setChartData] = useState<any[]>([]);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [filters, setfilters] = useState<{
    vehicleGroupId: string;
    startDate: string;
    endDate: string;
    quarter: string;
  }>({
    vehicleGroupId: "",
    startDate: d.now().minus({ months: 1 }).minus({ days: 1 }).toUTC().toISO(),
    endDate: d.now().minus({ days: 1 }).toUTC().toISO(),
    quarter: ""
  });
  const [searchedInputValue, setSearchedInputValue] = useState<string>("");

  const [getVehiclesUtilizationData, { data: vehiclesUtilizationReportData, loading: loadingVehicleUtilizationData }] = useLazyQuery(GET_VEHICLES_UTILISATION_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setAnchorEl(null);
    }
  });

  const [loadVehicleGroups,
    { data: vehicleGroupsData }
  ] = useLazyQuery(
    GET_VEHICLE_GROUPS_PRICE, {
    fetchPolicy: "network-only"
  });

  const [downloadUtilizationReport] = useLazyQuery(DOWNLOAD_GROUP_VEHICLE_UTITLIZATION_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.downloadVehicleUtilizationReport) {
        const { success, key } = data.downloadVehicleUtilizationReport;
        const { startDate: qStart, endDate: qEnd } = getStartEndDateForQuater(filters.quarter);
        if (success && key) {
          const fileName = `Fleet-utilisation-report-${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
          const file = await getSignedUrl(key);
          const url: any = file;
          fetch(url, {
            method: "GET"
          })
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              a.remove();
              setDownloadLoading(false);
            });
        }
      } else {
        setDownloadLoading(false);
        snackbar({
          message: "Something went wrong!",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setDownloadLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    loadVehicleGroups();
  }, []);

  useEffect(() => {
    if (vehiclesUtilizationReportData && vehiclesUtilizationReportData.getVehiclesUtilizationReport) {
      let uniqueChartData: IChartData[] = [];
      vehiclesUtilizationReportData.getVehiclesUtilizationReport.filter((item: any) => {
        var i = uniqueChartData.findIndex((x: any) => (x.licencePlate == item.licencePlate));
        if (i <= -1) {
          uniqueChartData.push(item);
        }
        return null;
      });
      const formatedData: any = uniqueChartData.map((data: any) => {
        return {
          "LicencePlate": data.licencePlate,
          "OnHire": data.onHire,
          "OffHire": data.offHire,
          "OnHold": data.onHold,
          "InService": data.inService
        }
      })
      setChartData(formatedData);
    }
  }, [vehiclesUtilizationReportData]);

  useEffect(() => {
    if (vehicleGroupsData && vehicleGroupsData.vehicleGroups) {
      const sortedGroups = [...vehicleGroupsData.vehicleGroups].sort((firstGroup: IVehiclePriceGroup, secondGroup: IVehiclePriceGroup) => {
        return firstGroup.name.toLowerCase().localeCompare(secondGroup.name.toLowerCase());
      })
      if (sortedGroups.length > 0) {
        setVehicleGroups(sortedGroups);
        setfilters({
          ...filters,
          vehicleGroupId: sortedGroups[0].id
        });
        setSearchedInputValue(sortedGroups[0].name);
      } else {
        setVehicleGroups([]);
        setfilters({ ...filters, vehicleGroupId: '' });
        setSearchedInputValue('');
      }
    }
  }, [vehicleGroupsData]);

  useEffect(() => {
    if (vehicleGroupsData && vehicleGroupsData.vehicleGroups && filters && filters.vehicleGroupId) {
      getVehiclesUtilizationData({
        variables: {
          vehicleGroupId: filters.vehicleGroupId,
          startDate: filters.quarter ? "" : filters.startDate,
          endDate: filters.quarter ? "" : filters.endDate,
          quarter: filters.quarter
        }
      });
    }
  }, [filters])

  const handleAutoCompleteInputChange = _.debounce((value: string, reason: any) => {
    if (reason === "selectOption") {
      const resourcesToFilter = [...vehicleGroups];
      if (value) {
        const matchedVehicleGroup = resourcesToFilter.find((vg) => vg.name === value);
        if (matchedVehicleGroup && matchedVehicleGroup.id) {
          setfilters({
            ...filters,
            vehicleGroupId: matchedVehicleGroup.id
          });
        }
      }
    }
  }, 200);

  const getStartEndDateForQuater = (q?: string) => {
    const currentDate = d.now().toISODate();
    let startDate = "", endDate = "";
    let year = d.now().get("year");
    if (q) {
      let quarter = parseInt(q.slice(-1));
      if (quarter === 1 && currentDate >= `${d.now().get("year")}-01-01` && currentDate <= `${d.now().get("year")}-03-31`) {
        year = year - 1;
      } else if (quarter === 2 && (
        currentDate >= `${d.now().get("year")}-01-01` && currentDate <= `${d.now().get("year")}-03-31` ||
        currentDate >= `${d.now().get("year")}-04-01` && currentDate <= `${d.now().get("year")}-06-30`
      )
      ) {
        year = year - 1;
      } else if (quarter === 3 && (
        currentDate >= `${d.now().get("year")}-01-01` && currentDate <= `${d.now().get("year")}-03-31` ||
        currentDate >= `${d.now().get("year")}-04-01` && currentDate <= `${d.now().get("year")}-06-30` ||
        currentDate >= `${d.now().get("year")}-07-01` && currentDate <= `${d.now().get("year")}-09-30`
      )) {
        year = year - 1;
      } else if (quarter === 4 && (
        currentDate >= `${d.now().get("year")}-01-01` && currentDate <= `${d.now().get("year")}-03-31` ||
        currentDate >= `${d.now().get("year")}-04-01` && currentDate <= `${d.now().get("year")}-06-30` ||
        currentDate >= `${d.now().get("year")}-07-01` && currentDate <= `${d.now().get("year")}-09-30` ||
        currentDate >= `${d.now().get("year")}-07-01` && currentDate <= `${d.now().get("year")}-09-30`
      )) {
        year = year - 1;
      }
      endDate = d.fromObject({ year: year, month: quarter * 3 }).endOf("month").toUTC().toISO();
      startDate = d.fromObject({ year: year, month: (quarter * 3) - 2 }).startOf("month").toUTC().toISO();
    }
    return { startDate, endDate }
  }

  const { width } = useContainerDimensions(ref);

  return (
    <div className={`${styles.module} ${styles.outline}`}>
      <div className={styles.chartHeadline}>
        <span className="font-medium bold">Utilisation By</span>
        <div style={{ width: 190, padding: "0 10px" }}>
          <Autocomplete
            id="combo-box-demo"
            freeSolo
            disableClearable
            noOptionsText="No Groups"
            options={vehicleGroups}
            getOptionLabel={(option: any) =>
              option.name
            }
            fullWidth
            onChange={(event: any, newVal: any | null, reason: string) => {
              if (newVal && newVal.name) {
                handleAutoCompleteInputChange(newVal.name, reason);
                setSelectedVehicleGroup(newVal.name);
                setSearchedInputValue(newVal.name)
              } else {
                setSelectedVehicleGroup("");
                setSearchedInputValue("")
              }
            }}
            inputValue={searchedInputValue}
            onInputChange={() => {
              setSearchedInputValue("");
            }}
            renderInput={(params) => (
              <StyledInput
                {...params}
                value={selectedVehicleGroup}
                variant="outlined"
                required
                placeholder={"Select vehicle group"}
                fullWidth
                onChange={(e) => setSearchedInputValue(e.target.value)}
              />
            )}
          />
        </div>
        <span className="font-medium margin-left">For Period</span>
        <div style={{ position: "relative" }}>
          <label
            htmlFor="VEHICLE_UTILISATION_DATE_RANGE"
            style={{ minWidth: 140 }}
            className={`${styles.filterInput} ${styles.large}`}
          >
            <span>
              {filters.startDate
                ? getLocalizedDateFormat(country, filters.startDate, DATE_TYPE.CONDENSED)
                : "From date"}
            </span>{" "}
            -{" "}
            <span>
              {filters.endDate
                ? getLocalizedDateFormat(country, filters.endDate, DATE_TYPE.CONDENSED)
                : "To date"}
            </span>
          </label>
          <div style={{ position: "absolute", left: 0, right: 0 }}>
            <Flatpickr
              options={{
                static: true,
                minuteIncrement: 1,
                dateFormat: country === "United States" ? "m/d/Y" : "d/m/Y",
                maxDate: d.now().minus({ months: 1 }).minus({ days: 1 }).toUTC().toISO()
              }}
              onChange={(dates) => {
                setfilters({
                  ...filters,
                  startDate: d.fromFormat(`${dates[0].getDate()}/${dates[0].getMonth() + 1}/${dates[0].getFullYear()}`, "d/M/yyyy").toUTC().toISO(),
                  endDate: d.fromFormat(`${dates[0].getDate()}/${dates[0].getMonth() + 1}/${dates[0].getFullYear()}`, "d/M/yyyy").plus({ months: 1 }).toUTC().toISO(),
                  quarter: ""
                })
              }}
              render={(
                { defaultValue, value, ...props }: any,
                ref: any
              ) => (
                <input
                  id="VEHICLE_UTILISATION_DATE_RANGE"
                  {...props}
                  defaultValue={defaultValue}
                  ref={ref}
                  style={{
                    height: 0,
                    width: 0,
                    overflow: "hidden",
                    opacity: 0,
                    position: "absolute",
                    left: -100,
                    top: 30
                  }}
                />
              )}
            />
          </div>
        </div>
        <span className='margin-left margin-right font-medium'>Or</span>
        {getQuartersList().map(value => (
          <div
            className={`${styles.filterInput} ${styles.large} ${value === filters.quarter ? styles.selected : ""}`}
            onClick={() => {
              const { startDate, endDate } = getStartEndDateForQuater(value)
              setfilters({
                ...filters,
                quarter: value,
                startDate,
                endDate
              })
            }}
          >
            {value}
          </div>
        ))}
        <div className='flex fill main-end'>
          {downloadLoading ? (
            <div style={{ marginTop: 12 }}>
              <CircularProgress size={20} thickness={5} />
            </div>
          ) : (
            <React.Fragment>
              <IconButton
                edge="end"
                aria-label="download"
                disabled={!chartData.length}
                size="small"
                onClick={() => {
                  setDownloadLoading(true)
                  const { vehicleGroupId, ...rest } = filters;
                  downloadUtilizationReport({
                    variables: {
                      ...rest
                    }
                  })
                }}
              >
                <CloudDownloadIcon />
              </IconButton>
            </React.Fragment>
          )}
          <span className='margin-left'></span>
          <IconButton
            edge="end"
            aria-label="delete"
            size="small"
          >
            <Tooltip
              title={
                <div style={{ fontSize: 12 }}>
                  <p>
                    Fleet Utilisation is one way to measure the supply and demand balance and in turn the market price. It is a measure of the actual use of an asset
                  </p>
                  <p>
                    Fleet Utilisation report shows the extent of the actual use of an asset. The filters available in this report are
                    <ol>
                      <li>Vehicle group</li>
                      <li>Date range or quarter</li>
                    </ol>
                  </p>
                  <p>
                    Based on the filter selection the report will be generated for each group. The report considers the following four vehicle state
                    <ol>
                      <li>On Hire</li>
                      <li>Off Hire</li>
                      <li>In Service</li>
                      <li>On Hold</li>
                    </ol>
                  </p>
                </div>
              }
              arrow
              placement={"left"}
            >
              <InfoIcon />
            </Tooltip>
          </IconButton>
        </div>
      </div>
      <div ref={ref} style={{ width: "100%" }}>
        {loadingVehicleUtilizationData ? (
          <div style={{ marginTop: 100 }}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <React.Fragment>
            {chartData.length ?
              <div style={{ width: width < (chartData.length * 50 + 100) ? "100%" : chartData.length * 50 + 100, height: "100%" }}>
                <ResponsiveBar
                  data={chartData}
                  keys={['OnHire', 'OffHire', "OnHold", "InService"]}
                  indexBy="LicencePlate"
                  margin={{ top: 60, right: 80, bottom: 50, left: 20 }}
                  padding={0.3}
                  valueScale={{ type: 'linear' }}
                  // tooltipFormat={(v: string) => `${v}%`}
                  indexScale={{ type: 'band', round: true }}
                  colors={{ scheme: 'dark2' }}
                  labelFormat={v => `${v}%`}
                  label={d => `${parseFloat(`${d.value}`)}`}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'top-right',
                      direction: 'column',
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemWidth: 90,
                      itemHeight: 21,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 15,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1
                          }
                        }
                      ]
                    }
                  ]}
                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -30,
                    legendOffset: 32
                  }}
                  borderRadius={2}
                  innerPadding={1}
                  enableGridY={false}
                  axisLeft={null}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor="#fff"
                  animate={true}
                // motionStiffness={90}
                // motionDamping={15}
                />
              </div> : props.licensePlate ? <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography style={{ padding: "10px 30px", borderRadius: 6, border: "1px solid #f5f5f5", textAlign: "center", margin: "0 20px" }} variant="body1">Data not available for selected vehicle</Typography>
              </div> : null}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}