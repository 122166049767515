import { DateTime as d } from "luxon";

import { IBooking } from "../../../reducers/bookings/types";
import { captureErrorException } from "../../../utils/sentry";
import { VEHICLE_STATUS } from "./Summary/const";

export function capitalizeFirstLetter(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export enum FleetTypes {
  OWN = "OWN",
  SHARED = "SHARED"
}

export const rateTypes = [
  {
    rateTypeName: "hourly",
    rateTypeDuration: 60
  },
  {
    rateTypeName: "daily",
    rateTypeDuration: 1440
  },
  {
    rateTypeName: "weekly",
    rateTypeDuration: 10080
  },
  {
    rateTypeName: "monthly",
    rateTypeDuration: 43200
  }
];

export const billingCycles = [
  {
    billingCycleName: "none"
  },
  {
    billingCycleName: "weekly",
    billingCycleDuration: 10080
  },
  {
    billingCycleName: "monthly",
    billingCycleDuration: 43200
  }
];

export const customerTypes: any = [
  {
    value: "individual",
    label: "Individual"
  },
  {
    value: "business",
    label: "Business"
  }
];

export const valetTypes: string[] = [
  "Delivery",
  "Collection",
  "Delivery and Collection"
];

export enum ValetTypes {
  DELIVERY = "Delivery",
  COLLECTION = "Collection",
  DELIVERY_AND_COLLECTION = "Delivery and Collection"
}

export enum LocationTypes {
  PICKUP_LOCATION = "pickupOtherLocation",
  DROP_OFF_LOCATION = "dropoffOtherLocation"
}
export function getRentalPeriod(
  startDate: string,
  endDate: string,
  rateTypeDuration: number,
  rateTypeName: string
): string {
  let rentalPeriod = "";
  const timeDiff: number = d
    .fromISO(endDate)
    .diff(d.fromISO(startDate))
    .valueOf();
  const rentalTime: number = Math.ceil(timeDiff / (rateTypeDuration * 60000));
  rentalPeriod = rentalTime + " ";
  switch (rateTypeName) {
    case "hourly":
      rentalPeriod += "hour(s)";
      break;
    case "daily":
      rentalPeriod += "day(s)";
      break;
    case "weekly":
      rentalPeriod += "week(s)";
      break;
    case "monthly":
      rentalPeriod += "month(s)";
      break;
  }
  return rentalPeriod;
}

export const getPhoneNumber = (phoneNumber: string | undefined): string => {
  let modifiedPhoneNumber: string = "";
  if (phoneNumber) {
    if (!phoneNumber.includes("+") && phoneNumber[0] !== "0") {
      modifiedPhoneNumber = `+${phoneNumber}`;
    } else {
      modifiedPhoneNumber = phoneNumber;
    }
  }
  return modifiedPhoneNumber;
};

export const getEndDateForBilling = (
  startDate: string,
  billingCycleName: string
) => {
  if (startDate) {
    if (billingCycleName === RATE_TYPES.WEEKLY) {
      return d
        .fromISO(startDate)
        .plus({ days: 7 })
        .toUTC()
        .toISO();
    }
    if (billingCycleName === RATE_TYPES.MONTHLY) {
      return d
        .fromISO(startDate)
        .plus({ month: 1 })
        .toUTC()
        .toISO();
    }
    if (billingCycleName === RATE_TYPES.DAILY) {
      return d
        .fromISO(startDate)
        .plus({ day: 1 })
        .toUTC()
        .toISO();
    }
    return d
      .fromISO(startDate)
      .plus({ hour: 1 })
      .toUTC()
      .toISO();
  }
  return d
    .now()
    .plus({ hour: 1 })
    .toUTC()
    .toISO();
};

export const getMileageLimitText = (
  mileageLimit: number,
  rateType: string,
  distanceUnit: any
) => {
  switch (rateType) {
    case "hourly":
      return `${mileageLimit} ${distanceUnit}s per hour`;
    case "daily":
      return `${mileageLimit} ${distanceUnit}s per day`;
    case "weekly":
      return `${mileageLimit} ${distanceUnit}s per week`;
    case "monthly":
      return `${mileageLimit} ${distanceUnit}s per month`;
  }
};

export enum Constants {
  MINUTE_TO_MILLISECOND = 60000,
  BOOKING_ADVANCE_START_TIME = 5 // in minutes
}

export const canExtend = (booking: IBooking) => {
  let flag = false;
  if (booking.isSubscription) {
    if (
      booking.currentBookingSchedules.some((schedule) =>
        [VEHICLE_STATUS.IN_PROGRESS, VEHICLE_STATUS.CONFIRMED].includes(
          schedule.vehicleStatus
        )
      )
    ) {
      flag = true;
    }
  } else {
    booking.vehicleGroups.forEach((vg) => {
      if (
        vg.activeVehicleSchedules?.some(
          (schedule) => schedule.vehicleStatus === VEHICLE_STATUS.IN_PROGRESS
        )
      ) {
        flag = true;
      }
    });
  }
  return flag;
};

export const canTerminate = (booking: IBooking) => {
  let flag = false;
  if (
    booking &&
    booking.dropoffDateTime &&
    booking.initialDropoffDateTime &&
    booking.status === BookingStatus.IN_PROGRESS
  ) {
    flag = true;
  }
  return flag;
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
  } catch (e) {
    captureErrorException(e);
    return false;
  }
  return true;
};

export enum BookingFlowStage {
  BASE_DETAILS,
  ADDONS_SELECTION,
  PERSONAL_DETAILS,
  QUOTE_VIEW,
  PAYMENT_VIEW
}

export enum SubscriptionFlowStage {
  CATALOGUE_DETAILS,
  VEHICLE_DETAILS,
  PERSONAL_DETAILS,
  VERIFICATION
}

export const addonTypes = ["INSURANCE", "CUSTOM", "VALET"];

export enum BookingStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED",
  QUOTE = "QUOTE",
  DRAFT = "DRAFT"
}

export enum CustomerType {
  INDIVIDUAL = "individual",
  BUSINESS = "business",
  DRIVER = "driver"
}

export enum RATE_TYPES {
  HOURLY = "hourly",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly"
}

export enum SOCKET_EVENTS {
  REQUEST_VIEW_BOOKING = "REQUEST_VIEW_BOOKING",
  GET_UPDATED_BOOKING = "GET_UPDATED_BOOKING",
  RENTAL_AGGREMENT_GENERATED = "RENTAL_AGGREMENT_GENERATED",
  REQUEST_VIEW_INVOICE = "REQUEST_VIEW_INVOICE",
  GET_UPDATED_INVOICE = "GET_UPDATED_INVOICE",
  REQUEST_VIEW_CUSTOMER = "REQUEST_VIEW_CUSTOMER",
  GET_UPDATED_CUSTOMER = "GET_UPDATED_CUSTOMER",
  REQUEST_VIEW_CREDIT_NOTE = "REQUEST_VIEW_CREDIT_NOTE",
  GET_UPDATED_CREDIT_NOTE = "GET_UPDATED_CREDIT_NOTE",
  REQUEST_VIEW_RATE_CARD = "REQUEST_VIEW_RATE_CARD",
  GET_UPDATED_RATE_CARD = "GET_UPDATED_RATE_CARD",
  REQUEST_VIEW_DAMAGE_MATRIX = "REQUEST_VIEW_DAMAGE_MATRIX",
  GET_UPDATED_DAMAGE_MATRIX = "GET_UPDATED_DAMAGE_MATRIX"
}

export enum TestDriveTypes {
  STANDARD = "STANDARD",
  EXTENDED = "EXTENDED",
}

export enum TestDriveExperiences {
  CONSULTATION = "CONSULTATION",
}

export const testDriveTypesOptions = [
  { value: TestDriveTypes.STANDARD, label: "Standard Test Drive" },
  { value: TestDriveTypes.EXTENDED, label: "Extended Test Drive" },
];

export const testDriveExperienceOptions = [
  {
    value: TestDriveExperiences.CONSULTATION,
    label: "Consultation during Test Drive",
  },
];
