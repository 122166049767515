import {
  Grid,
  Theme,
  Typography,
  Hidden,
  Box,
  Paper
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IVehicle, IVehicleAssets} from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../../../../common/NuvvenTable/types";

interface IProps {
  vehicle: IVehicle;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      color: "#00ff00"
    },
    header: {
      color: "rgb(45, 157, 184)",
      fontSize: 20
    },
    label: {
      color: "grey",
      fontSize: 12,
      fontWeight: 100,
      marginLeft: 20
    },
    paper: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      textAlign: "center"
    },
    red: {
      color: "#ff0000"
    },
    root: {
      padding: theme.spacing(3)
    }
  })
);


export const VehicleAssets = (props: IProps) => {
  const classes = useStyles();
  const [vehicleAssets, setvehicleAssets] = useState<IVehicleAssets[]>([]);

  useEffect(() => {
    if (props.vehicle && props.vehicle.assets && props.vehicle.assets.length) {
      setvehicleAssets(props.vehicle.assets);
    }
  }, [props.vehicle]);

  const columns = [
    {
      label: "Asset Tag",
      name: "assetTag"
    },
    {
      label: "Registartion No.",
      name: "registrationNumber"
    },
    {
      label: "Serial Number",
      name: "serialNumber"
    },
    {
      label: "Asset Type",
      name: "assetType"
    },
    {
      label: "Make",
      name: "make"
    },
    {
        label: "Model",
        name: "model"
    },
    {
        label: "Color",
        name: "color"
    },
   
  ];

  function Spacer() {
    return (
      <Grid item xs={12}>
        <Box mt={1} />
      </Grid>
    )
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    elevation: 0,
    onRowClick: (rowData: string[], rowMeta: { dataIndex: number }) => {
    //   if (rowData && rowData.length && props?.vehicle?.assets) {
        
    //   }
    },
  }

  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          <Typography variant="subtitle1">Assets</Typography>
        </Grid>
        <Spacer />
        <Grid item xs={12}>
          <Hidden smDown>
            {vehicleAssets && vehicleAssets.length > 0 ? (
              <Paper variant="outlined">
                <NuvvenTable
                  title=""
                  rows={vehicleAssets}
                  columns={columns}
                  options={options}
                />
              </Paper>
            ) : (
              <Grid xs={12} item>
                <Typography variant="h4">
                  No Asset Data
                </Typography>
              </Grid>
            )}
          </Hidden>
          <Hidden smUp>
            {(vehicleAssets.length === 0) && (
              <Grid xs={12} item>
                <Typography variant="h4">
                  No Asset Data
                </Typography>
              </Grid>
            )}
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};
