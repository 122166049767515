import React, { useEffect, useState } from "react";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { Grid, Paper, Theme, Typography } from "@mui/material";
import { AssetTypes, AssetTypesValue, IAsset, IAssetDamage } from "../../../Assets/asset.types";
import { MUIDataTableOptions } from "mui-datatables";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { VehicleDamageCircumstance } from "../../Issues&Damages/constants";
import { VehicleDamageCircumstancesValue } from "../../../../../reducers/damageVehicle/types";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { DATE_TYPE, toCurrency } from "../../../../common/utils";
import { makeStyles } from "@mui/styles";

interface IColumns {
  label: string;
  name: string;
  options?: object;
}

interface IRowSelect {
  index: number;
  dataIndex: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
}));

interface IProps {
  vehicleAssets: IAsset[];
  vehicleAssetsDamages: IAssetDamage[];
  assetsSelectionHandler: (selectedIndex: number[]) => void;
  assetsDamagesHandler: (selectedIndex: number[]) => void;
  selectedAssets: any[];
  selectedVehicleAssetsDamages: any[];
}

export const AssetDamagesTable = (props: IProps) => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { locale, currency } = userState.currentOrganisation;
  const [assetsRows, setAssetsRows] = useState<IAsset[]>([]);
  const [selectedAssetsRows, setSelectedAssetsRows] = useState<number[]>([])

  const [damageRows, setDamageRows] = useState<any[]>([]);
  const [selectedDamageRows, setSelectedDamageRows] = useState<number[]>([])
  const selectedDamageIndex: number[] = [];
  const selectedAssetIndex: number[] = [];


  useEffect(() => {
    if (props.vehicleAssets.length) {
      setAssetsRows(props.vehicleAssets)

    }
    props?.vehicleAssets?.map((asset, index) => {
      if (asset && asset.id) {
        props.selectedAssets.forEach((selectedAsset) => {
          if (typeof selectedAsset === "object" && selectedAsset && selectedAsset.id) {
            selectedAssetIndex.push(index)
          }
        })
      }
    })
    setSelectedAssetsRows(selectedAssetIndex)
  }, [props.vehicleAssets, props.selectedAssets])

  useEffect(() => {
    if (props.vehicleAssetsDamages.length) {
      setDamageRows(props.vehicleAssetsDamages)
    }
    props?.selectedVehicleAssetsDamages?.map((damage, idx) => {
      if (damage && damage.id) {
        props.selectedVehicleAssetsDamages.forEach((selectedDamage) => {
          if (typeof selectedDamage === "object" && selectedDamage && selectedDamage.id) {
            selectedDamageIndex.push(idx)
          }
        })
      }
    })
    setSelectedDamageRows(selectedDamageIndex)
  }, [props.vehicleAssetsDamages, props.selectedVehicleAssetsDamages])




  const assetColumns: IColumns[] = [
    {
      label: "Asset Tag ",
      name: "assetTag"
    },
    {
      label: "Registration Number",
      name: "registrationNumber"
    },
    {
      label: "Serial Number",
      name: "serialNumber"
    },
    {
      label: "Asset Type",
      name: "assetType",
      options: {
        customBodyRender: (value: AssetTypes) => AssetTypesValue[value]
      }
    }
  ];

  const damageColumns: IColumns[] = [
    {
      label: "Reference Number",
      name: "referenceNumber"
    },
    {
      label: "Asset",
      name: "asset.assetTag"
    },
    {
      label: "Circumstance",
      name: "circumstance",
      options: {
        customBodyRender: (value: VehicleDamageCircumstance) => VehicleDamageCircumstancesValue[value]
      }
    },
    {
      label: "Incident Date",
      name: "incidentDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Est. Repair Date",
      name: "estimatedRepairDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED) : "NA"
      }
    },
    {
      label: "Total Expense",
      name: "totalExpense",
      options: {
        customBodyRender: (value: string) => toCurrency(parseInt(value), currency, locale)
      }
    }
  ];

  const assetOptions: MUIDataTableOptions = {
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row = assetsRows[rowMeta.rowIndex];
    },
    elevation: 0,
    onRowSelectionChange: (currentRowsSelected: IRowSelect[], allRows: IRowSelect[]) => {
      setSelectedAssetsRows(allRows.map(row => row.dataIndex));
      let selectedRowIndex = currentRowsSelected.map((row) => row.index);
      props.assetsSelectionHandler(selectedRowIndex);
    },
    selectableRows: "multiple",
    rowsSelected: selectedAssetsRows,
    selectableRowsOnClick: true
  };

  const damageOptions: MUIDataTableOptions = {
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row = damageRows[rowMeta.rowIndex];
    },
    elevation: 0,
    onRowSelectionChange: (currentRowsSelected: IRowSelect[], allRows: IRowSelect[]) => {
      setSelectedDamageRows(allRows.map(row => row.dataIndex));
      let selectedRowIndex = currentRowsSelected.map((row) => row.index);
      props.assetsDamagesHandler(selectedRowIndex);
    },
    selectableRows: "multiple",
    rowsSelected: selectedDamageRows,
    selectableRowsOnClick: true
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid item container xs={12}>
        <Typography variant="h3" className={classes.heading}>
          Assets
        </Typography>
      </Grid>
      <Paper variant='outlined' className={classes.paper}>
        <NuvvenTable
          title=""
          rows={assetsRows}
          columns={assetColumns}
          options={assetOptions}
          setSelection={() => { }}
        />
      </Paper>
      <Grid item container xs={12}>
        <Typography variant="h3" className={classes.heading}>
          Asset Damages
        </Typography>
      </Grid>
      <Paper variant='outlined' className={classes.paper}>
        <NuvvenTable
          title=""
          rows={damageRows}
          columns={damageColumns}
          options={damageOptions}
          setSelection={() => { }}
        />
      </Paper>
    </div>
  );

}