import { gql } from "@apollo/client";

export const GET_ALLOCATION_RULES = gql`
  query allocationRules {
    allocationRules {
    	id
    	tenantId
    	organisationId
    	ruleCategory
    	rules {
    	  ruleName
    	  sequence
				value
    	}
    	priority
    	createdAt
    	updatedAt
  	}
  }
`;

export const SAVE_ALLOCATION_RULES = gql`
  mutation saveAllocationRules($allocationRules: [AllocationRuleInput]!) {
    saveAllocationRules(allocationRules: $allocationRules) {
      id
    	tenantId
    	organisationId
    	ruleCategory
    	rules {
    	  ruleName
    	  sequence
				value
    	}
    	priority
    	createdAt
    	updatedAt
    }
  }
`;
