import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Typography,
  Hidden,
  Radio,
  RadioGroup
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  IInsuranceAddon,
} from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { getAddonIcon, toCurrency } from "../../../../common/utils";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      flexGrow: 1,
      maxWidth: "100%"
    },
    addonIcon: {
      width: "100%",
      height: "75px"
    }
  })
);

interface IInsuranceProps {
  insuranceAddOns: IInsuranceAddon[];
  selectedAddon?: string;
  onSelect(insuranceAddOn?: string): void;
  isCoi: boolean | undefined;
  onSelectCoi(flag: boolean): void;
}
export const InsuranceCard: React.FC<IInsuranceProps> = (props) => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [selected, setSelected] = useState<boolean>(!!props.selectedAddon);
  const [values, setValues] = useState<Partial<IInsuranceAddon>>({ policyName: "", policyId: "" });
  const [insuranceType, setInsuranceType] = useState<string>("HOI")

  const { locale, currency } = userState.currentOrganisation;

  useEffect(() => {
    if (props.selectedAddon) {
      const insurance = props.insuranceAddOns.
        find((insuranceAddOn) => insuranceAddOn.policyId === props.selectedAddon);
      if (insurance) {
        setValues({ ...insurance });
      }
      setSelected(true)
    } else {
      setValues({ policyName: "", policyId: "" })
      setSelected(false)
    }
  }, [props.selectedAddon]);

  useEffect(() => {
    if (!selected && props.selectedAddon) {
      props.onSelect();
    }
  }, [selected]);

  useEffect(() => {
    if (props.isCoi) {
      setInsuranceType("COI")
      setSelected(false)
      setValues({
        policyName: "",
        policyId: ""
      })
    } else {
      setInsuranceType("HOI")
    }
  }, [props.isCoi])

  const { insuranceAddOns } = props;

  const uniqueInsuranceAddOns = Array.from(
    new Set(insuranceAddOns.map((insurance) => insurance.policyId))
  ).map((policyId) =>
    insuranceAddOns.find((insurance) => insurance.policyId === policyId)
  );

  return (
    <Grid container item xs={12}>
      <Hidden smDown>
        <Grid container item xs={1} alignItems="center">
          <img
            className={classes.addonIcon}
            src={getAddonIcon("insurance")}
            alt=""
          ></img>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={8}>
        <Grid item xs={12}>
          <Typography variant="h2">PROTECTION PLAN</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box mt={0.5}></Box>
        </Grid>
        <Grid item xs={12}>
          {props.selectedAddon && (
            <Typography variant="body1">
              {values?.description}
            </Typography>
          )}
        </Grid>
        {/* <Grid item xs={12} >
          <FormControl>
            <RadioGroup
              row
              name="insuranceType"
              value={insuranceType}
              onChange={(event) => {
                if (event.target.value === "COI") {
                  props.onSelectCoi(true)
                } else {
                  props.onSelectCoi(false)
                }
              }}
            >
              <FormControlLabel
                value="HOI"
                control={<Radio />}
                label={<Typography variant="body1">{"HOI"}</Typography>}
              />
              <FormControlLabel
                value="COI"
                control={<Radio />}
                label={<Typography variant="body1">{"COI"}</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid> */}
      </Grid>
      <Hidden smUp>
        <Grid container item xs={4} alignItems="center">
          <img
            className={classes.addonIcon}
            src={getAddonIcon("insurance")}
            alt=""
          ></img>
        </Grid>
      </Hidden>
      <Grid container item xs={8} sm={3}>
        <Formik enableReinitialize initialValues={values} onSubmit={() => { }}>
          {() => (
            <Form className={classes.form}>
              <Grid container item xs={12}>
                <Grid container item xs={12}><Box mt={1}></Box></Grid>
                <Grid container item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      name={"insurance.policyId"}
                      fullWidth
                      type="text"
                      select
                      label="Protection Plan"
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled={insuranceType === "COI"}
                      inputProps={{
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          props.onSelect(e.target.value);
                          setValues({
                            ...values,
                            policyId: e.target.value
                          });
                        },
                        value: values.policyId
                      }}
                    >
                      {uniqueInsuranceAddOns.map(
                        (insuranceAddOn: any, index: number) => (
                          <MenuItem key={index} value={insuranceAddOn.policyId}>
                            {insuranceAddOn.policyName} - Rate{" "}
                            {toCurrency(insuranceAddOn.rate, currency, locale)}
                          </MenuItem>
                        )
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid container item xs={12}><Box mt={0.8}></Box></Grid>
                <Grid container item xs={12} justifyContent="flex-end">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={selected}
                        onChange={() => {
                          if (selected) {
                            setSelected(!selected);
                            setValues({
                              ...values,
                              policyId: ""
                            });
                          }
                        }}
                        name="Select"
                        color="primary"
                      />
                    }
                    label="Selected"
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
