export const partNerTypes = [
    {
        label: "Car Dealer",
        value: "CAR_DEALER"
    },
    {
        label: "Garage",
        value: "GARAGE"
    },
    {
        label: "3rd Party logistics partner",
        value: "THIRD_PARTY_LOGISTICS_PARTNER"
    },
    {
        label: "Vehicle OEM",
        value: "VEHICLE_OEM"
    },

]

export enum PartnerType {
    CAR_DEALER = "CAR_DEALER",
    GARAGE = "GARAGE",
    THIRD_PARTY_LOGISTICS_PARTNER = "THIRD_PARTY_LOGISTICS_PARTNER",
    VEHICLE_OEM = "VEHICLE_OEM",
    THIRD_PARTY_DRIVER_AGENCY = "THIRD_PARTY_DRIVER_AGENCY"
    

}

export const PartnerTypeValue: {
    [key in PartnerType]: string;
} = {
    CAR_DEALER: "Car Dealer",
    GARAGE: "Garage",
    THIRD_PARTY_LOGISTICS_PARTNER: "3rd Party logistics partner",
    VEHICLE_OEM : "Vehicle OEM",
    THIRD_PARTY_DRIVER_AGENCY: "3rd Party Driver Agency"
};