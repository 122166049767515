import { IBranch } from "../../../reducers/organisation/types";
import { IOrganisation } from "../../../reducers/user/types";

export interface IWebsite {
  id?: string;
  websiteName: string;
  domain: string;
  websiteTitle: string;
  websiteIcon: string;
  websiteLogo: string;
  branding: IBranding;
  organisationId: string;
  branchIds: string[];
  websiteDescription: string;
  hasCarousel: boolean;
  carousel: string[];
  hasGallery: boolean;
  gallery: string[];
  privacyPolicyURL: string;
  termsAndConditionsURL: string;
  organisation: Partial<IOrganisation>;
  brannches: Partial<IBranch>[];
}

export interface IAppSetting {
  id?: string;
  appName: string;
  organisationId: string;
  branchIds: string[];
  privacyPolicyURL: string;
  provider?: string;
  callbackUris?: string;
  clientId?: string;
  clientSecret?: string;
  firstParty?: boolean;
  companyWebsite: string;
  termsAndConditionsURL: string;
  branding: IBranding;
  isActive: boolean;
  webAppSetting: IWebSetting;
  partnerAppSetting: IWebSetting;
  organisation?: Partial<IOrganisation>;
  mobileAppSetting: IMobileAppSetting;
  allowedRateTypes?: string[];
  allowedBillingFrequencies?: string[];
  bookingTypes: string[];
  subscriptionSettings: ISubscriptionSettings;
  pcoRentalOnly?: boolean;
  disableSelfRegistration: boolean;
}

export interface IWebSetting {
  websiteTitle: string;
  websiteIcon: string;
  websiteLogo: string;
  domain: string;
  websiteHeader: string;
  websiteDescription: string;
  websiteAddress: string;
  logoSize: number;
  htmlDescription: boolean;
  hasCarousel: boolean;
  carousel: string[];
  hasGallery: boolean;
  gallery: string[];
  enableBusinessCustomerSignup: boolean;
  paymentGateway: string;
}

export interface IMobileAppSetting {
  appIcon: string;
  companyIcon: string;
  straplineIcon: string;
  landingPageIcon: string;
}

export interface IBranding {
  brandColors: boolean;
  buttonColor: string;
  accentColor: string;
}

export interface IBookingQuestion {
  question: string;
  answerType: ANSWER_TYPES;
  required: boolean;
  expectedAnswer?: string;
  options?: string[];
  errorMessage?: string;
  B2B?: boolean;
  B2B2C?: boolean;
}

export interface ISubscriptionSettings {
  idvEnabled: boolean;
  creditCheckEnabled: boolean;
  dvlaVerificationEnabled: boolean;
}

export enum ANSWER_TYPES {
  YES_NO = "YES_NO",
  DROP_DOWN = "DROP_DOWN",
  TEXT = "TEXT",
  CHECKBOX = "CHECKBOX"
}
