import { ApolloError, useLazyQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Skeleton,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime as d } from "luxon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { GET_VEHICLE_AVAILABILITY } from "../../../../../graphql/bookings/getTestDriveVehicleAvailability";
import { ITestDriveBookingDetails } from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { TestDriveExperiences, TestDriveTypes } from "../../utils";

interface IVehicleAvailability {
  timeSlot: string;
  slotId: string;
  driverId: string;
}

interface IVehicleSlotSelection {
  testDriveType: TestDriveTypes;
  testDriveExperience: TestDriveExperiences;
  selectedSlot: string;
  selectedDate: string;
  vehicleId: string;
  calendarInitialSlot: number;
  extendedTestDriveDuration: number;
  driverId: string;
  slotId: string;
}

interface IProps {
  vehicleId: string;
  pickupDate: string;
  testDriveBookingDetails?: ITestDriveBookingDetails;
  setPickupDateTime?: (
    timeSlot: string,
    driverId: string,
    slotId: string
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnContainer: {
      minWidth: "120px"
    },
    timeSlot: {
      marginBottom: theme.spacing(0.5)
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark
      }
    },
    slotsContainer: {
      minWidth: "400px"
    }
  })
);

const initialVehicleSelection: IVehicleSlotSelection = {
  testDriveType: TestDriveTypes.STANDARD,
  testDriveExperience: TestDriveExperiences.CONSULTATION,
  selectedSlot: "",
  vehicleId: "",
  selectedDate: d
    .now()
    .toUTC()
    .toISO(),
  calendarInitialSlot: 0,
  extendedTestDriveDuration: 1,
  driverId: "",
  slotId: ""
};

export const TestDriveSlotSelector: React.FC<IProps> = ({
  pickupDate,
  vehicleId,
  testDriveBookingDetails,
  setPickupDateTime
}) => {
  const snackbar = useSnackBar();
  const classes = useStyles();
  const [vehicleSlotSelection, setVehicleSelection] = useState<
    IVehicleSlotSelection
  >(initialVehicleSelection);
  const [selectedSlot, setSelectedSlot] = useState<number>(
    initialVehicleSelection.calendarInitialSlot
  );
  const [vehicleAvailability, setVehicleAvailability] = useState<
    IVehicleAvailability[]
  >([]);

  const userState = useSelector((state: IAppState) => state.userReducer);

  const [
    getTestDriveAvailablilty,
    { loading: availabilityLoading }
  ] = useLazyQuery(GET_VEHICLE_AVAILABILITY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getTestDriveVehicleAvailability) {
        const vehicleAvailability =
          data.getTestDriveVehicleAvailability.timeSlots;
        let initialSlot = "",
          initialDriverId = "",
          initialSlotId = "";
        if (vehicleAvailability.length) {
          initialSlot = vehicleAvailability[0].timeSlot;
          initialDriverId = vehicleAvailability[0].driverId;
          initialSlotId = vehicleAvailability[0].slotId;
        }
        setVehicleSelection({
          ...vehicleSlotSelection,
          selectedSlot: initialSlot,
          slotId: initialSlotId,
          driverId: initialDriverId,
          calendarInitialSlot: 0
        });
        setVehicleAvailability(vehicleAvailability);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (vehicleId && pickupDate) {
      let extendedTestDriveDuration = 0;
      if (
        testDriveBookingDetails?.type === TestDriveTypes.EXTENDED &&
        testDriveBookingDetails.extendedTestDriveHours
      ) {
        extendedTestDriveDuration =
          testDriveBookingDetails.extendedTestDriveHours;
      }
      getTestDriveAvailablilty({
        variables: {
          queryDate: pickupDate,
          vehicleIds: [vehicleId],
          branchId: userState.branch.id,
          extendedTestDriveDuration
        }
      });
    }
  }, [vehicleId]);

  const handleSlotSelection = (
    slot: IVehicleAvailability,
    selectedSlotIndex: number
  ) => {
    if (setPickupDateTime) {
      setPickupDateTime(slot.timeSlot, slot.driverId, slot.slotId);
    }
    setSelectedSlot(selectedSlotIndex);
  };

  return (
    <Grid
      container
      xs={12}
      spacing={2}
      alignContent="center"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid container item xs={10} className={classes.slotsContainer}>
        {availabilityLoading && (
          <Grid container item xs={12} spacing={1}>
            <CircularProgress />
          </Grid>
        )}
        {!availabilityLoading && vehicleAvailability && (
          <Grid container item xs={12}>
            <Grid container xs={12} spacing={1}>
              {vehicleAvailability.map((slot, index) => (
                <Grid
                  item
                  xs={12}
                  sm={2}
                  key={index}
                  className={classes.btnContainer}
                >
                  <Button
                    variant="outlined"
                    fullWidth
                    className={`${classes.timeSlot} ${
                      selectedSlot === index ? classes.selected : ""
                    }`}
                    onClick={() => handleSlotSelection(slot, index)}
                  >
                    {slot.timeSlot}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {!availabilityLoading && !vehicleAvailability.length && (
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ marginTop: "16px" }}>
                Vehicle Not Available for Selected Date.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ marginTop: "16px" }}>
                Please Select a Different Date.
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
