import { IPendingAction } from "../../components/views/DashboardNew/UpcomingEvents"
import { IBooking } from "../bookings/types";
import { IVehicle } from "../fleet/types";

export interface IUserJob {
  id: string; 
  subCategory: string;
  jobDate: string;
  dateCreated: string;
  description: string;
  assignedTo: any;
  status: string;
  actionsLinked: IPendingAction[]; 
  otherTaskItems: string[];
  booking: IBooking;
  vehicle: IVehicle;
  notes: IUserJobNote[];
}

export interface IUserJobNote {
  id: string
  createdBy: string
  updatedBy: string
}
export interface IUserJobWrite {
  id?: string;
  subCategory: string;
  jobDate: string;
  description: string;
  assignedTo: string;
  actionsLinked: string[];
  otherTaskItems: string[];
  status?: string;
  booking?: string;
  vehicle?: string;
  notes?: IUserJobNote[];
  partnerId?: string;
  partnerProfile?: string;
  driverType: DriverType
}

export enum JobCategoryStatus {
  VEHICLE_FUELING = "VEHICLE_FUELING",
  VEHICLE_CLEANING = "VEHICLE_CLEANING",
  VEHICLE_DROP_OFF = "VEHICLE_DROP_OFF",
  VEHICLE_PICK_UP = "VEHICLE_PICK_UP",
  VEHICLE_SERVICE_PICK_UP = "VEHICLE_SERVICE_PICK_UP",
  VEHICLE_SERVICE_DROP_OFF = "VEHICLE_SERVICE_DROP_OFF", 
  VEHICLE_SWAP = "VEHICLE_SWAP",
  VEHICLE_CHECK = "VEHICLE_CHECK",
  EARLY_TERMINATION = "EARLY_TERMINATION",
  BOOKING_EXTENSION = "BOOKING_EXTENSION",
  APPROVE_REJECT_BOOKING = "APPROVE_REJECT_BOOKING",
  APPROVE_REJECT_CUSTOMER = "APPROVE_REJECT_CUSTOMER",
  OTHERS = "OTHERS",
  VEHICLE_TRANSFER_PICKUP = 'VEHICLE_TRANSFER_PICKUP',
  VEHICLE_TRANSFER_DROP_OFF = 'VEHICLE_TRANSFER_DROP_OFF',
}
  
export enum JobCategoryStatusStatusText {
  VEHICLE_FUELING = "Vehicle Fueling",
  VEHICLE_CLEANING = "Vehicle Cleaning",
  VEHICLE_DROP_OFF = "Vehicle Drop Off",
  VEHICLE_PICK_UP = "Vehicle Pickup",
  VEHICLE_SERVICE_PICK_UP = "Vehicle Service Pickup",
  VEHICLE_SERVICE_DROP_OFF = "Vehicle Service Drop Off",  
  VEHICLE_CHECK = "Vehicle Check",
  VEHICLE_SWAP = "Vehicle Swap",
  EARLY_TERMINATION = "Early Termination",
  BOOKING_EXTENSION = "Booking Extension",
  APPROVE_REJECT_BOOKING = "Approve/Reject Booking",
  APPROVE_REJECT_CUSTOMER = "Approve/Reject Customer",
  OTHERS = "Others",
  VEHICLE_TRANSFER_PICKUP = 'Vehicle Transfer Pickup',
  VEHICLE_TRANSFER_DROP_OFF = 'Vehicle Transfer Drop Off',
}

export enum JobStatusType {
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED"
}

export function JobStatus(status: any) {
  switch(status) {
    case JobStatusType.IN_PROGRESS:
      return "IN PROGRESS"
    case JobStatusType.PENDING:
      return "PENDING";
    case JobStatusType.COMPLETE:
      return "COMPLETE";
    case JobStatusType.CANCELLED:
      return "CANCELLED";
    default:
      return "NA";
  }
}

export interface IDateTime {
    date: string;
    time: number;
}

export enum DriverType {
  INTERNAL = 'internal',
  EXTERNAL = 'external'
}