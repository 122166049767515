import { gql } from "@apollo/client";

export const CREATE_BRANCH_CONNECTION = gql`
  mutation createFleetShare(
    $fleetShareInput: FleetShareInput!
  ) {
    createFleetShare (fleetShareInput: $fleetShareInput) {
      success
      message
  }
}
`;
export const UPDATE_BRANCH_CONNECTION = gql`
  mutation updateFleetShare(
    $fleetShareId:ID!,
    $fleetShareInput: FleetShareInput!
  ) {
    updateFleetShare (fleetShareId: $fleetShareId, fleetShareInput: $fleetShareInput) {
      success
      message
  }
}
`;

export const REMOVE_BRANCH_CONNECTION = gql`
  mutation deleteFleetShare($fleetShareId:ID!) {
    deleteFleetShare (fleetShareId: $fleetShareId) {
      success
      message
  }
}
`;

export const CANCEL_FLEET_MOVEMENT = gql`
  mutation cancelFleetTransferMovement($movementId:ID!) {
    cancelFleetTransferMovement (movementId: $movementId) {
      success
      message
  }
}
`;

export const UPDATE_FLEET_MOVEMENT = gql`
  mutation updateFleetMovement(
    $fleetMovementId:ID!,
    $fleetMovementInput: FleetMovementInput!
  ) {
    updateFleetMovement (fleetMovementId: $fleetMovementId, fleetMovementInput: $fleetMovementInput) {
      id
      referenceNumber
      vehicleId
      vehicle {
        id
        licencePlate
        make
        model
        year
        status
      }
      status
      transitTime
      movementSource
      eventStart
      estimatedDelivery
      estimatedCheckout
      bookingId
      booking {
        id
      }
      externalBranchId
      externalBranch {
        id
        name
      }
      branchId
      branch {
        id
        name
      }
      vehicleServiceId
      driver {
        id
        firstName
        lastName
      }
      source{
        id
        name
      }
      destination{
        id
        name
      }
      partner {
        id
        partnerName
      }
  }
}
`;
