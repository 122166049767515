import React, { useState, useCallback, useEffect, useRef } from 'react';
import styles from './index.module.css';
import Flatpickr from "react-flatpickr";
import RoundIcon from '@mui/icons-material/FiberManualRecord';
import IdleIcon from '@mui/icons-material/Brightness2';
import VehicleSelection, { ISelectedVehicle, IVehicleGroupData } from './VehicleSelection';
import { MapModule } from './MapModule';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { DateTime as d } from 'luxon';
import { formatSecondsToHour } from '../utils';
import api from '../api/Reports'
import { IReportRequest, ReportStatus, ReportType, TelematicStartStopEvent, TelematicStartStopEventJourney } from '../types';
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { IVehicle } from '../../../../reducers/fleet/types';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getLocalizedDateFormat } from '../../../../utils/localized.syntex';
import { DATE_TYPE } from '../../../common/utils';
import { captureErrorException } from '../../../../utils/sentry';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}
interface IProps {
  active?: boolean,
  vehicles: IVehicle[],
  loadingVehicleGroups: boolean,
  vehicleGroups: IVehicleGroupData[]
}

const VehicleTrips: React.FC<IProps> = ({ active, vehicles, vehicleGroups }) => {
  const snackbar = useSnackBar();
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [filters, setFilters] = useState<{
    startDate: string, endDate: string
  }>({ startDate: "", endDate: "" });

  const [selectedVehicles, setSelectedVehicles] = useState<ISelectedVehicle[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<TelematicStartStopEvent & { id: string }>();
  const [selectedTrip, setSelectedTrip] = useState<TelematicStartStopEventJourney | undefined>();
  const [reportRequests, setReportRequests] = useState<IReportRequest<TelematicStartStopEvent>[]>([]);
  const [completedReportRequests, setCompletedReportRequests] = useState<IReportRequest<TelematicStartStopEvent>[]>([]);
  const [loadingReportRequests, setLoadingReportRequests] = useState<boolean>(true);

  const makeCsv = async (event: TelematicStartStopEvent, startDate: string, endDate: string) => {
    const separator: string = ',';
    const columns = [
      "Vehicle",
      "Start time",
      "End time",
      "Start location",
      "End location",
      "Start lat-long",
      "End lat-long",
      "Running time",
      "Idle time",
      "Max speed",
      "Miles driven",
      "Odometer",
      "Harsh Braking",
      "Harsh Acceleration",
      "Hard Cornering",
      "Speeding",
      "Lost GPS",
      "Lost GSM",
    ];
    const licencePlate = vehicles.find((vehicle) => vehicle.telemetryDeviceId === event?.deviceEsn)?.licencePlate
    const csvContent = `${columns.join(separator)}\n${event?.journeys.map(journey => {
      let cells = [
        licencePlate || "",
        getLocalizedDateFormat(country, journey.sTime, DATE_TYPE.EXPANDED).replace(',', ''),
        getLocalizedDateFormat(country, journey.eTime, DATE_TYPE.EXPANDED).replace(',', ''),
        journey.startStreet,
        journey.endStreet,
        `"${journey.startLat + " , " + journey.startLong}"`,
        `"${journey.endLat + " , " + journey.endLong}"`,
        formatSecondsToHour(journey.duration - journey.idleTime),
        formatSecondsToHour(journey.idleTime),
        `${journey.maxSpeed} mph`,
        `${journey.miles} miles`,
        journey.odometer ? parseFloat(`${journey.odometer}`).toFixed(2) : "N/A",
        journey.harshBraking,
        journey.harshAcceleration,
        journey.hardCornering,
        journey.speeding,
        journey.lostGPS,
        journey.lostGSM,
      ]
      cells.join(separator);
      return cells
    }).join('\n')}`;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const fileName = `Tripsdata-${licencePlate}-${event?.deviceEsn}-${getLocalizedDateFormat(country, startDate, DATE_TYPE.CONDENSED)}-${getLocalizedDateFormat(country, endDate, DATE_TYPE.CONDENSED)}`
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const requestReport = async () => {
    try {
      const { data }: { data: IReportRequest<TelematicStartStopEvent> } = await api.createReportRequest({
        deviceEsns: selectedVehicles.map((vehicle) => vehicle.deviceEsn),
        vehicleIds: [],
        startDate: filters.startDate,
        endDate: filters.endDate,
        reportType: ReportType.TRACKING,
        branchId: userState.currentBranch.id,
        organisationId: userState.currentOrganisation.id,
        tenantId: userState.currentTenancy.id
      });
      setReportRequests([
        data,
        ...reportRequests
      ]);
      if (!timer.current) {
        getReportRequests()
      }
      setSelectedVehicles([])
    }
    catch (error: any) {
      captureErrorException(error)
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading telematics data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  const getReportRequests = async () => {
    try {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      };
      const { data: axiosData } = await api.getReportRequests({
        reportType: ReportType.TRACKING,
        branchId: userState.currentBranch.id,
        organisationId: userState.currentOrganisation.id,
        tenantId: userState.currentTenancy.id
      });
      if (axiosData) {
        let _reportRequests: IReportRequest<TelematicStartStopEvent>[] = [...reportRequests]
        axiosData.forEach((request: IReportRequest<TelematicStartStopEvent>) => {
          const findIndex = _reportRequests.findIndex(rq => rq.id === request.id)
          if (findIndex >= 0) {
            if (_reportRequests[findIndex].status !== ReportStatus.COMPLETED) {
              _reportRequests = [
                ..._reportRequests.slice(0, findIndex),
                { ..._reportRequests[findIndex], ...request },
                ..._reportRequests.slice(findIndex + 1)
              ]
            }
          } else {
            _reportRequests = [
              ..._reportRequests,
              request
            ]
          }
        });
        _reportRequests = _reportRequests.sort((a, b) => d.fromISO(b.createdAt).toUnixInteger() - d.fromISO(a.createdAt).toUnixInteger());
        setReportRequests(_reportRequests);
        if (_reportRequests.some((request: IReportRequest<TelematicStartStopEvent>) => request.status !== ReportStatus.COMPLETED)) {
          timer.current = setTimeout(() => {
            getReportRequests()
          }, 3000)
        }
      }
      return axiosData;
    } catch (error: any) {
      captureErrorException(error)
      setLoadingReportRequests(false);
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading telematics data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  const getReportRequest = async (requestId: string) => {
    try {
      const { data: axiosData } = await api.getReportRequest(requestId);
      return axiosData;
    } catch (error: any) {
      captureErrorException(error)
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading telematics data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  useEffect(() => {
    getReportRequests()
      .finally(() => setLoadingReportRequests(false))
  }, [])

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  useEffect(() => {
    const promises = [];
    for (const reportRequest of reportRequests) {
      if (reportRequest.status === ReportStatus.COMPLETED) {
        const completedReportIndex = completedReportRequests.findIndex((r) => r.id === reportRequest.id);
        if (completedReportIndex < 0) {
          promises.push(getReportRequest(reportRequest.id))
        }
      }
    }
    Promise.all(promises).then((requests: IReportRequest<TelematicStartStopEvent>[]) => {
      if (requests.length) {
        setCompletedReportRequests([
          ...completedReportRequests,
          ...requests
        ].sort((a, b) => d.fromISO(b.createdAt).toUnixInteger() - d.fromISO(a.createdAt).toUnixInteger()));
      }
    });
  }, [reportRequests]);


  const handleSetVehicles = useCallback((vehicles: ISelectedVehicle[]) => {
    setSelectedVehicles(vehicles);
    setSelectedEvent(undefined);
  }, []);

  const RenderTripCard = (request: any, event: any, completed: boolean) => {
    return (
      <div
        className={`${styles.result} ${`${selectedEvent?.id}-${selectedEvent?.deviceEsn}` === `${request.id}-${event.deviceEsn}` ? styles.selected : ""} `}
        style={{ marginRight: 2, overflowY: "auto", pointerEvents: !completed ? "none" : "auto" }}
        onClick={() => {
          setSelectedEvent({ id: request.id, ...event });
          setSelectedTrip(undefined);
        }}
        key={`${request.id}-${event.deviceEsn}`}
      >
        <Grid container xs={12} style={{ position: "relative" }}>
          {completed ? <Typography variant="body1" className="flex fill">
            <Typography variant="h4">{vehicles.find((vehicle) => vehicle.telemetryDeviceId === event.deviceEsn)?.licencePlate || ""}</Typography>
            <span style={{ position: "absolute", right: 5 }}>
              {`${event.numOfJourneys} Trips (${event.totalMiles} miles)`}
            </span>
          </Typography> : <Typography variant="body1" className="flex fill">
            <span className="flex fill bold">{vehicles.find((vehicle) => vehicle.telemetryDeviceId === event)?.licencePlate || ""}</span>
            <span style={{ textTransform: "capitalize" }}>{request.status.toLowerCase()}</span>
          </Typography>}
        </Grid>
        <Grid container xs={12} className="flex cross-center" style={{ marginTop: 5 }}>
          <Grid item xs={6}>
            <span className="flex cross-start" style={{ color: "#848484" }}>
              {`${getLocalizedDateFormat(country, request.startDate, DATE_TYPE.CONDENSED)} - ${getLocalizedDateFormat(country, request.endDate, DATE_TYPE.CONDENSED)}`}
            </span>
          </Grid>
          {completed && <Grid container xs={6} justifyContent="flex-end">
            <span className="flex cross-start" style={{ color: "#6CC38A" }}>
              <RoundIcon color="inherit" style={{ fontSize: 14, marginLeft: -3 }} />
              <span style={{ lineHeight: 1.3, color: "#848484" }}>{formatSecondsToHour(event.totalDuration - event.totalIdleTime)}</span>
            </span>
            <span className="flex cross-start" style={{ color: "#e3ab42" }}>
              <IdleIcon color="inherit" style={{ fontSize: 13, margin: "0 0px 0 10px", transform: "rotate(45deg)" }} />
              <span style={{ lineHeight: 1.15, color: "#848484" }}>{formatSecondsToHour(event.totalIdleTime)}</span>
            </span>
          </Grid>}
        </Grid>
        {
          completed && (
            <Grid item xs={12} justifyContent='space-between' alignItems='center' container style={{ marginTop: 5 }}>
              <span className="flex cross-start" style={{ color: "#848484" }}><strong>Requested at: &nbsp; </strong> {getLocalizedDateFormat(country, request.createdAt, DATE_TYPE.EXPANDED)} </span>
              {event.numOfJourneys > 0 &&
                <Tooltip title={<Typography variant='h5'>Download Report</Typography>}>
                  <IconButton size="small" style={{ marginTop: -13 }} onClick={(e) => {
                    e.stopPropagation();
                    makeCsv(event, request.startDate, request.endDate);
                  }}>
                    <CloudDownloadIcon className="flex-box" style={{ marginTop: 5 }} />

                  </IconButton>
                </Tooltip>
              }
            </Grid>
          )
        }
      </div>
    )
  }

  return (
    <div className={styles.wrapper} style={{ display: active ? 'block' : 'none' }}>
      <div style={{ display: "flex", alignItems: "center", backgroundColor: "black", color: "white", width: "auto", height: "auto", marginTop: -30, marginBottom: 40, marginLeft: 0, fontSize: 10, paddingLeft: 15, paddingTop: 5, paddingRight: 6, paddingBottom: 5 }}>
        <InfoOutlinedIcon style={{ marginRight: "5px" }} fontSize="small" />
        <Typography variant="h4" className="flex fill" style={{ paddingLeft: 10 }}>
          Report generated will be available for 24 hours before it is removed from the list below. Please note that it may take several minutes to prepare the report depending on the date range and number of vehicles selected.
        </Typography>
      </div>

      <div className="flex fill" style={{ margin: "-30px -20px 0 -20px", height: "calc(100vh - 110px)", overflow: "hidden" }}>
        <div className={styles.leftColumn}>
          {vehicleGroups.length > 0 ? <VehicleSelection selectedVehicles={selectedVehicles} vehicleGroups={vehicleGroups} setVehicles={handleSetVehicles} /> : (
            <div className='flex main-center cross-center' style={{ backgroundColor: "White", paddingLeft: 16, marginLeft: 20 }}>
              <p>Either access denied or no vehicle with telematic data found</p>
            </div>
          )}
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.vehicleResults}>
            <div className="flex space-between">
              <div style={{ position: "relative", width: 150, marginBottom: 10 }}>
                <label htmlFor={`TRIPS_DATE_RANGE`} style={{ minWidth: 140 }} className={styles.filterInput}>{filters.startDate ? getLocalizedDateFormat(country, filters.startDate, DATE_TYPE.CONDENSED) : "From date"} to {filters.endDate ? getLocalizedDateFormat(country, filters.endDate, DATE_TYPE.CONDENSED) : "To date"}</label>
                <div style={{ position: "absolute", left: 0, right: 0, }}>
                  <Flatpickr
                    options={{
                      mode: "range",
                      static: true,
                      minuteIncrement: 1,
                      dateFormat: country === "United States" ? "m/d/Y" : "d/m/Y",
                      defaultDate: [filters.startDate, filters.endDate],
                      maxDate: d.now().toUTC().toISO()
                    }}
                    onChange={(dates) => {
                      if (dates && dates[0] && dates[1]) {
                        const from = d.fromFormat(`${dates[0].getFullYear()}/${dates[0].getMonth() + 1}/${dates[0].getDate()}`, "yyyy/M/d")
                        const end = d.fromFormat(`${dates[1].getFullYear()}/${dates[1].getMonth() + 1}/${dates[1].getDate()}`, "yyyy/M/d").toUTC().toISO()
                        const dayRangeCount = d.fromISO(end).diff(from, "days").days
                        if (dayRangeCount <= 14) {
                          setFilters({
                            ...filters,
                            startDate: d.fromFormat(`${dates[0].getFullYear()}/${dates[0].getMonth() + 1}/${dates[0].getDate()}`, "yyyy/M/d").toUTC().toISO(),
                            endDate: dates[1] ? d.fromFormat(`${dates[1].getFullYear()}/${dates[1].getMonth() + 1}/${dates[1].getDate()}`, "yyyy/M/d").toUTC().toISO() : ""
                          })
                        } else {
                          setFilters({
                            startDate: "",
                            endDate: ""
                          })
                          return (
                            snackbar({
                              message: "Selection must be 15 or less than 15 days.",
                              variant: SnackBarVariant.ERROR
                            })
                          )
                        }
                      }
                    }
                    }
                    render={
                      ({ defaultValue, value, ...props }: any, ref: any) => (
                        <input id={`TRIPS_DATE_RANGE`} {...props} defaultValue={defaultValue} ref={ref} style={{ height: 0, width: 0, overflow: "hidden", opacity: 0, position: "absolute", left: -100, top: 30 }} />
                      )
                    }
                  />
                </div>
              </div>
              <Button variant="outlined" size="small" color='primary' disabled={!selectedVehicles.length} style={{ marginBottom: 10 }} onClick={() => {
                requestReport()
              }}>
                <Typography variant="body2" style={{ textTransform: "initial" }}>Generate</Typography>
              </Button>
            </div>
            {
              !loadingReportRequests ? (
                <div className={styles.scroll} style={{ display: "flex-fill" }}>
                  {reportRequests.length ? [...reportRequests, ...completedReportRequests].map((request) => {
                    if (request.status !== ReportStatus.COMPLETED) {
                      return request.deviceEsns.map(esn => {
                        return RenderTripCard(request, esn, false)
                      })
                    } else {
                      if (request.data) {
                        return request.data.map(event => {
                          return RenderTripCard(request, event, true)
                        })
                      }
                    }
                  }) :
                    <div className={`${styles.result} `}>
                      <Typography variant="body2">{selectedVehicles.length ? "No data found!" : "Please select vehicles from left panel to generate report"}</Typography>
                    </div>
                  }
                </div>
              ) : <CircularProgress size={20} thickness={5} />
            }
          </div>
          {selectedEvent && <div className={styles.rightSub}>
            <div className="flex fill">
              <MapModule trip={selectedTrip} />
            </div>
            <div className={`${styles.scroll} flex fill col-flex padding`}>
              <Typography variant="h4" className="margin-bottom padding-bottom">Trips for {selectedVehicles.find((vehicle) => vehicle.deviceEsn === selectedEvent.deviceEsn)?.licencePlate || ""}</Typography>
              {selectedEvent.journeys.length ? <table className={styles.tripTable}>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Running time</th>
                  <th>Idle time</th>
                  <th>Max speed</th>
                </tr>
                {selectedEvent.journeys.map((journey) => {
                  const selected = selectedTrip?.sTime === journey.sTime && selectedTrip.eTime === journey.eTime;
                  return (
                    <tr
                      onClick={() => {
                        setSelectedTrip(journey)
                      }}
                      className={`${styles.trip} ${selected ? styles.selected : ""} `}
                    >
                      <td>
                        <Typography variant="body1">{journey.startStreet || ""}</Typography>
                        <Typography variant="body2">{getLocalizedDateFormat(country, journey.sTime, DATE_TYPE.EXPANDED)}</Typography>
                      </td>
                      <td>
                        <Typography variant="body1">{journey.endStreet || ""}</Typography>
                        <Typography variant="body2">{getLocalizedDateFormat(country, journey.eTime, DATE_TYPE.EXPANDED)}</Typography></td>
                      <td>
                        <Typography variant="body2">{formatSecondsToHour(journey.duration - journey.idleTime)}</Typography></td>
                      <td>
                        <Typography variant="body2">{formatSecondsToHour(journey.idleTime)}</Typography></td>
                      <td>
                        <Typography variant="body2">{journey.maxSpeed}mph</Typography></td>
                    </tr>
                  )
                })}
              </table>
                : <div className={`${styles.result} `}>
                  <Typography variant="body2">No trips available for selected vehicle on selected time period</Typography>
                </div>
              }
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default VehicleTrips

