import { gql } from "@apollo/client";

export const GET_ASSETS = gql`
  query assets($limit: Int!, $offset: Int!) {
    assets(limit: $limit, offset: $offset) {
      id
      registrationNumber
      serialNumber
      identificationNumber
      assetTag
      make
      model
      color
      frogDiagram
      assetType
      isActive
    }
  }
`;

export const GET_ASSET_COUNT = gql`
  {
    getAssetCount
  }
`;

export const GET_ASSET = gql`
  query getAsset($id: String!) {
    getAsset(id: $id) {
      id
      registrationNumber
      serialNumber
      identificationNumber
      assetTag
      make
      model
      color
      frogDiagram
      assetType
      isActive
      images
      vehicle{
        id
        licencePlate
        branchDetails{
          id
          name
        }
      }
      services{
        id
        serviceType
        status
        appointmentDate
        completionDate
        totalExpense
      }
      damages{
        id
        circumstance
        referenceNumber
        incidentDate
        totalExpense
        status
      }
      documents{
        title
        documentName
        expiryDate
        documentType
        reminderBefore
        url
      }
      size
      deFleetParameter{
        byDate
        condition
      }
      dimension{
        length
        width
        height
      }
      capacity
      capacityUnit
      yearOfManufacture
      yearOfRegistration
      issueDate
      grossWeight{
        maximumPermittedWeight
        maximumCountryPermittedWeight
        designWeight
      }
      trainWeight{
        maximumPermittedWeight
        maximumCountryPermittedWeight
        designWeight
      }
      axleWeights{
        maximumPermittedWeight
        maximumCountryPermittedWeight
        designWeight
      }
      description
      purchaseCost
    }
  }
`;

export const UPLOAD_ASSET = gql`
  mutation uploadAssets($assets: [AssetUploadInput]) {
    uploadAssets(assets: $assets) {
      errors {
        index
        message
      }
      assets {
      id
      registrationNumber
      serialNumber
      assetTag
      assetType
      }
    }
  }
`;




// export const GET_ASSETS = gql`
//   query assets($limit: Int!, $offset: Int!) {
//     assets(limit: $limit, offset: $offset) {
//       id
//       assetTag
//       assetType
//       dateCreated
//       vehicle{
//         id
//         licencePlate
//       }
//       isActive
//     }
//   }
// `;

// export const GET_ASSET_COUNT = gql`
//   {
//     getAssetCount
//   }
// `;

// export const GET_ASSET = gql`
//   query getAsset($id: String!) {
//     getAsset(id: $id) {
//       id
//       assetTag
//       assetType
//       isActive
//       images
//       customFields{
//         label
//         value
//       }
//       vehicle{
//         id
//         licencePlate
//         branchDetails{
//           id
//           name
//         }
//       }
//       services{
//         id
//         serviceType
//         status
//         appointmentDate
//         completionDate
//         totalExpense
//       }
//       documents{
//         title
//         documentName
//         expiryDate
//         documentType
//         reminderBefore
//         url
//       }
//     }
//   }
// `;