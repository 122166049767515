import { gql } from "@apollo/client";

export const GET_VEHICLE_AVAILABILITY = gql`
  query getTestDriveVehicleAvailability(
    $queryDate: String!
    $vehicleIds: [String]!
    $branchId: String!
    $extendedTestDriveDuration: Int
  ) {
    getTestDriveVehicleAvailability(
      queryDate: $queryDate
      vehicleIds: $vehicleIds
      branchId: $branchId
      extendedTestDriveDuration: $extendedTestDriveDuration
    ) {
      queryDate
      isExtended
      timeSlots {
        timeSlot
        vehicleId
        slotId
        driverId
      }
    }
  }
`;
