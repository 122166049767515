import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from "react";
import { FleetMovementSource, FleetMovementStatus, IConfirmedMovementUpdateArgs, IFleetMovement } from './types';
import styles from "./index.module.css";
import withStyles from '@mui/styles/withStyles';
import { Autocomplete, AutocompleteChangeReason, Box, CircularProgress, createStyles, FormControl, InputAdornment, MenuItem, TextField, Theme } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DateTime as d } from 'luxon';
import { UPDATE_FLEET_MOVEMENT } from '../../../graphql/vehicleMovement/mutations';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';
import { formatGraphQLErrorMessage } from '../../common/utils';
import { ApolloError } from '@apollo/client';
import * as Yup from "yup";
import { SimpleDateTimePicker } from '../../common/SimpleDateTimePicker';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { GET_SERVICE_LOCATIONS_IN_ORG, GET_USER_DRIVERS } from '../../../graphql/vehicleMovement/queries';
import _ from 'lodash';
import { IBranch, IServiceLocation, IUserState } from '../../../reducers/user/types';
import { UserJobTypes } from '../JobSchedular/JobUtils';
import { GET_AVAILABLE_PARTNERS_FOR_JOB, GET_PARTNER_USER_IN_PARTNERSHIP } from '../../../graphql/jobSchedular/getJobsQuery';

interface IProps {
  open: boolean;
  handleClose: (confirmed: boolean, args?: IConfirmedMovementUpdateArgs) => void;
  movement?: IFleetMovement
}

interface IDateTime {
  date: string;
  time: number
}

export const defaultDriver: any = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  userRole: {
    id: "",
    name: "",
    key: ""
  },
};

interface IMovementServiceLocation extends IServiceLocation {
  branch: Partial<IBranch>
}

const StyledInputBase = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
    },
    error: {
      '& input': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
      },
      '& textarea': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
      },
      '& .MuiSelect-select': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        backgroundColor: '#fff !important',
      }
    },
    input: {
      borderRadius: "4px !important",
      position: 'relative',
      backgroundColor: theme.palette.common.white + "!important",
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '12px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      minHeight: 'initial'
    },
  }),
)(InputBase);

const ConfirmMovementDialog: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [estimatedCheckoutDate, setestimatedCheckoutDate] = useState<string>();
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState<string>();
  const [source, setSource] = useState<string>("");
  const [selectedSource, setSelectedSource] = useState<string>("");
  const [destination, setDestination] = useState<string>("");
  const [selectedDestination, setSelectedDestination] = useState<string>("");
  const [serviceLocationsInOrg, setServiceLocationsInOrg] = useState<IMovementServiceLocation[]>([])
  const [drivers, setDrivers] = useState<IUserState[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<IUserState>(defaultDriver)
  const [taskDate, setTaskDate] = useState<string>();
  const [jobtype, setJobType] = useState<string>();
  const isSupplierPortalEnabled = userState.currentOrganisation.supplierPortalEnabled;
  const [partnerDrivers, setPartnerDrivers] = useState<any[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<string>("");
  const [selectedPartnerDriver, setSelectedPartnerDriver] = useState<string>("");

  const [getServiceLocationsInOrg, { loading: loadingServiceLocations, data: serviceLocationsInOrgData }] = useLazyQuery(
    GET_SERVICE_LOCATIONS_IN_ORG,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [getDriverUsers, { loading: driversLoading }] = useLazyQuery(GET_USER_DRIVERS, {
    fetchPolicy: "network-only",
    onCompleted: (data: any) => {
      if (data && data.getDriverUsersInBranch) {
        setDrivers(data.getDriverUsersInBranch)
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
  })

  const [updateMovement, { loading: movementUpdating }] = useMutation(UPDATE_FLEET_MOVEMENT, {
    onCompleted: (data) => {
      const updatedFleetMovement = data.updateFleetMovement;
      props.handleClose(true, {
        driver: updatedFleetMovement.driver,
        estimatedCheckout: updatedFleetMovement.estimatedCheckout,
        source: updatedFleetMovement.source,
        destination: updatedFleetMovement.destination,
        estimatedDelivery: updatedFleetMovement.estimatedDelivery,
        taskDate,
        jobType: jobtype,
        ...(isSupplierPortalEnabled && { partner: updatedFleetMovement.partner })
      });
      snackbar({
        message: "Movement Confirmed successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
  });

  useEffect(() => {
    if (props.movement) {
      setestimatedCheckoutDate(props.movement.estimatedCheckout);
      setEstimatedDeliveryDate(props.movement.estimatedDelivery);
      if (props.movement.source) {
        setSource(props.movement.source.id || "");
      }
      if (props.movement.destination) {
        setDestination(props.movement.destination.id || "");
      }
      setTaskDate(props.movement.taskDate || "")
      setJobType(props.movement.jobType || "")
      setSelectedDriver(props.movement.driver || defaultDriver)
      getServiceLocationsInOrg({
        variables: {
          organisationId: userState.currentOrganisation.id
        }
      })
      if (isSupplierPortalEnabled) {
        getavailablePartners({
          variables: {
            partnerType: "THIRD_PARTY_DRIVER_AGENCY"
          }
        });
        setSelectedPartner(props.movement.partner?.id || "")
        setSelectedPartnerDriver(props.movement.driver?.id || "")
        if (props.movement.partner?.id) {
          getAllPartnerUsers(props.movement.partner.id)
        }
      } else {
        getDriverUsers()
      }
    }
  }, [props.movement])

  useEffect(() => {
    if (serviceLocationsInOrgData && serviceLocationsInOrgData.getServiceLocations) {
      setServiceLocationsInOrg(serviceLocationsInOrgData.getServiceLocations)
    }
  }, [serviceLocationsInOrgData]);


  useEffect(() => {
    if (props.movement && serviceLocationsInOrg && serviceLocationsInOrg.length) {
      if (props.movement.source) {
        const _selectedLoc = serviceLocationsInOrg.find((loc) => loc.id === props.movement?.source.id);
        if (_selectedLoc) {
          setSelectedSource(`${_selectedLoc.name}(${_selectedLoc.branch.name})`);
        }
      }
      if (props.movement.destination) {
        const _selectedLoc = serviceLocationsInOrg.find((loc) => loc.id === props.movement?.destination.id);
        if (_selectedLoc) {
          setSelectedDestination(`${_selectedLoc.name}(${_selectedLoc.branch.name})`);
        }
      }
    }
  }, [props.movement, serviceLocationsInOrg]);


  const handleSourceAutoCompleteInputChange = _.debounce((value: string, reason: any) => {
    if (reason === "input" || reason === "reset") {
      const resourcesToFilter = [...serviceLocationsInOrg];
      if (value) {
        const matchedLocation = resourcesToFilter.find((serviceLocation) => `${serviceLocation.name}(${serviceLocation.branch.name})` === value);
        if (matchedLocation) {
          setSource(matchedLocation.id)
        }
      }
    }
    if (reason === 'clear') {
      setSource("");
      setSelectedSource("")
    }
  }, 200);

  const handleDestinationAutoCompleteInputChange = _.debounce((value: string, reason: any) => {
    if (reason === "input" || reason === "reset") {
      const resourcesToFilter = [...serviceLocationsInOrg];
      if (value) {
        const matchedLocation = resourcesToFilter.find((serviceLocation) => `${serviceLocation.name}(${serviceLocation.branch.name})` === value);
        if (matchedLocation) {
          setDestination(matchedLocation.id)
        }
      }
    }
    if (reason === 'clear') {
      setDestination("");
      setSelectedDestination("")
    }
  }, 200);

  const validationSchema = Yup.object().shape({
    driver: Yup.string().required("Driver's name is required *"),
  });

  const [
    getavailablePartners,
    { loading: availablePartnersLoading, data: availablePartnersData }
  ] = useLazyQuery(GET_AVAILABLE_PARTNERS_FOR_JOB, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    getPartnerUsers,
    { loading: partnerUsersLoading, data: partnerUsersData }
  ] = useLazyQuery(GET_PARTNER_USER_IN_PARTNERSHIP, {
    fetchPolicy: "network-only",
    onCompleted: (partnerUsersData) => {
      setPartnerDrivers(partnerUsersData.getPartnerUsersInPartnership?.filter((user: any) => user.branch?.id === userState.currentBranch.id));
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  function getAllPartnerUsers(partnerId: string) {
    getPartnerUsers({
      context: {
        headers: {
          partnerId
        }
      },
    });
  }

  return (
    <>
      {props.movement ? <Dialog
        open={props.open}
        onClose={() => {
          props.handleClose(false)
        }}
        scroll="paper"
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Formik
          enableReinitialize
          initialValues={props.movement}
          onSubmit={(values) => {
            if (d.fromISO(estimatedCheckoutDate || "").toUTC().toISO() >= d.fromISO(estimatedDeliveryDate || "").toUTC().toISO()) {
              return snackbar({
                message: "Arrival date should be greater than checkout date",
                variant: SnackBarVariant.ERROR
              });
            }
            if (!selectedDriver?.id) {
              return snackbar({
                message: "Driver is not selected.",
                variant: SnackBarVariant.ERROR
              })
            }
            if (!taskDate) {
              return snackbar({
                message: "Select task date",
                variant: SnackBarVariant.ERROR
              })
            }
            if (!jobtype) {
              return snackbar({
                message: "Task type not selected.",
                variant: SnackBarVariant.ERROR
              })
            }

            updateMovement({
              variables: {
                fleetMovementId: props.movement?.id,
                fleetMovementInput: {
                  transitTime: values.transitTime,
                  status: FleetMovementStatus.CONFIRMED,
                  estimatedDelivery: estimatedDeliveryDate,
                  estimatedCheckout: estimatedCheckoutDate,
                  driver: isSupplierPortalEnabled ? selectedPartnerDriver : selectedDriver?.id || "",
                  source,
                  destination,
                  taskDate: taskDate || "",
                  jobType: jobtype || "",
                  ...(isSupplierPortalEnabled && { partner: selectedPartner || "" })
                }
              }
            })
          }}
        >
          {dialogFormprops => (
            <Form>
              <DialogTitle style={{ padding: '16px 24px', width: 400 }}>
                <Typography variant="h4" style={{ fontWeight: 700, fontSize: 16 }}>
                  Confirm Movement
                </Typography>
              </DialogTitle>
              <DialogContent dividers>
                <div className="padding-bottom margin-bottom">
                  <Typography>Vehicle: {props.movement?.vehicle.licencePlate}</Typography>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <SimpleDateTimePicker
                    date={estimatedCheckoutDate || ""}
                    handleChange={(date: IDateTime) => {
                      setestimatedCheckoutDate(d.fromISO(date.date).toUTC().toISO());
                    }}
                    required={true}
                    disabled={false}
                    name={"estimatedCheckout"}
                    dateTitle={"Checkout Date"}
                    timeTitle={"Checkout Time"}
                    minDate={props?.movement?.estimatedCheckout ? props.movement.estimatedCheckout : d.now().toISO()}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <SimpleDateTimePicker
                    date={estimatedDeliveryDate || ""}
                    handleChange={(date: IDateTime) => {
                      setEstimatedDeliveryDate(d.fromISO(date.date).toUTC().toISO());
                    }}
                    required={true}
                    disabled={false}
                    name={"estimatedDelivery"}
                    dateTitle={"Arrival Date"}
                    timeTitle={"Arrival Time"}
                    minDate={estimatedCheckoutDate ? estimatedCheckoutDate : d.now().toISO()}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={serviceLocationsInOrg}
                      getOptionLabel={(option: any) =>
                        `${option.name}(${option.branch.name})`
                      }
                      onInputChange={(event: any, value: string, reason: string) => {
                        handleSourceAutoCompleteInputChange(value, reason);
                        setSelectedSource(value);
                      }}
                      inputValue={selectedSource}
                      loading={loadingServiceLocations}
                      renderInput={(params) => (
                        <TextField {...params}
                          label="Source Location"
                          placeholder="Source Location name"
                          required
                          margin="normal"
                          variant="outlined"
                          style={{ backgroundColor: "white" }}
                          disabled={loadingServiceLocations}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingServiceLocations ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={serviceLocationsInOrg}
                      getOptionLabel={(option: any) =>
                        `${option.name}(${option.branch.name})`
                      }
                      onInputChange={(event: any, value: string, reason: string) => {
                        handleDestinationAutoCompleteInputChange(value, reason);
                        setSelectedDestination(value);
                      }}
                      inputValue={selectedDestination}
                      renderInput={(params) => (
                        <TextField {...params}
                          label="Destination Location"
                          placeholder="Destination Location name"
                          required
                          margin="normal"
                          variant="outlined"
                          style={{ backgroundColor: "white" }}
                          disabled={loadingServiceLocations}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingServiceLocations ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Field
                    component={TextField}
                    placeholder="Task Type"
                    label="Task Type"
                    name={"jobType"}
                    onChange={(e: any) => {
                      setJobType(e.target.value)
                    }}
                    fullWidth
                    required
                    select
                    value={jobtype}
                  >
                    {UserJobTypes.map((key: any, index: number) => {
                      return (
                        <MenuItem key={index} value={key.value}>
                          {key.label}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <FormControl variant="outlined" fullWidth>
                    {isSupplierPortalEnabled ? (
                      <Box display="flex" flexDirection="row" gap={2}>
                        <Field
                          component={TextField}
                          name="selectedPartner"
                          label="Select Agency"
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={selectedPartner}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSelectedPartner(e.target.value)
                            setSelectedPartnerDriver("")
                            setPartnerDrivers([])
                            getAllPartnerUsers(e.target.value)
                          }}
                          select
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" sx={{ mr: 2 }}>
                                {availablePartnersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              </InputAdornment>
                            ),
                          }}
                        >
                          {availablePartnersData?.getAvailablePartnersForJob?.map(
                            (partner: any) => (
                              <MenuItem key={partner.id} value={partner.id}>
                                {partner.partnerName}
                              </MenuItem>
                            )
                          )}
                        </Field>
                        <Field
                          component={TextField}
                          name="selectedPartnerDriver"
                          label="Select Driver"
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={selectedPartnerDriver}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedPartnerDriver(e.target.value)}
                          select
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" sx={{ mr: 2 }}>
                                {partnerUsersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              </InputAdornment>
                            ),
                          }}
                        >
                          {partnerDrivers?.map(
                            (driver: any) => (
                              <MenuItem key={driver.id} value={driver.id}>
                                {`${driver.firstName} ${driver.lastName}`}
                              </MenuItem>
                            )
                          )}
                        </Field>
                      </Box>
                    ) : (
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={drivers}
                        getOptionLabel={(option: any) =>
                          `${option.firstName} ${option.lastName}`
                        }
                        value={selectedDriver}
                        onChange={(
                          event: React.SyntheticEvent<Element, Event>,
                          value: IUserState | string | null,
                          reason: AutocompleteChangeReason
                        ) => {
                          if (typeof value === "object" && value !== null) {
                            const selectedDriverData = drivers.find((driver => driver.id === value.id))
                            if (selectedDriverData) {
                              setSelectedDriver(selectedDriverData)
                              dialogFormprops.setFieldValue("driver", value.id);
                            }
                          } else {
                            setSelectedDriver(defaultDriver)
                            dialogFormprops.setFieldValue("driver", "");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params}
                            label="Driver"
                            placeholder="Driver name"
                            required
                            margin="normal"
                            variant="outlined"
                            style={{ backgroundColor: "white" }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {driversLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  </FormControl>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <SimpleDateTimePicker
                    date={taskDate || ""}
                    handleChange={(date: IDateTime) => {
                      if (date && date.date) {
                        setTaskDate(d.fromISO(date.date).toUTC().toISO());
                      }
                    }}
                    required={true}
                    name={"taskDate"}
                    dateTitle={"Task Date"}
                    timeTitle={"Task Time"}
                    timeToShow={"before"}
                    minDate={d.now().toISO()}
                  />
                </div>
                <div style={{ textAlign: "right", marginTop: 20 }}>
                  <Fab
                    variant="extended"
                    size="small"
                    type="submit"
                    disabled={movementUpdating}
                  >
                    <strong>Confirm</strong> {movementUpdating ? <CircularProgress size={14} /> : ""}
                  </Fab>
                </div>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog> : null}
    </>
  )
}

export default ConfirmMovementDialog