import { gql } from "@apollo/client";

export const GET_JOBS = gql`
  query userJobs($limit: Int!, $offset: Int!) {
    userJobs(limit: $limit, offset: $offset) {
      id
      subCategory
      jobDate
      status
      dateCreated
      description
      assignedTo {
        id
        firstName
        lastName
      }
      otherTaskItems
      vehicle {
        licencePlate
        id
      }
      booking {
        id
        referenceNumber
      }
    }
  }
`;

export const SEARCH_USER_JOBS = gql`
  query searchUserJobs($q: String!) {
    searchUserJobs(q: $q) {
      id
      subCategory
      jobDate
      status
      dateCreated
      description
      assignedTo {
        id
        firstName
        lastName
      }
      otherTaskItems
      vehicle {
        licencePlate
        id
      }
      booking {
        id
        referenceNumber
      }
    }
  }
`;

export const GET_JOB = gql`
  query userJobById($id: String!) {
    userJobById(id: $id) {
      id
      subCategory
      jobDate
      status
      description
      assignedTo {
        id
        firstName
        lastName
        email
        phoneNumber {
          phone
          country
        }
        partnerProfile {
        id
        firstName
        lastName
        email
        phoneNumber {
          phone
          country
        }
        license {
        licenseNumber
        dateOfExpiry
        dateOfIssue
        authority
        endorsement
        images
        offences
        points
        dvlaVerificationStatus
      }
      }
      }
      actionsLinked {
        id
      }
      otherTaskItems
      notes {
        note
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
      vehicle {
        id
        licencePlate
        make
        model
        colour
        transmission
      }
      booking {
        id
        referenceNumber
        customer {
          id
          firstName
          lastName
          email
          phoneNumber {
            phone
          }
        }
        businessCustomer {
          id
          businessName
          contact {
            firstName
            lastName
            phoneNumber {
              phone
              country
            }
            email
          }
        }
        pickupServiceLocation {
          address {
            fullAddress
            street
            city
            state
            country
            zipcode
            geometry {
              type
              coordinates
              latitude
              longitude
            }
          }
        }
        dropoffServiceLocation {
          address {
            fullAddress
            street
            city
            state
            country
            zipcode
            geometry {
              type
              coordinates
              latitude
              longitude
            }
          }
        }
      }
      driverType
    }
  }
`;

export const GET_USERS_IN_BRANCH = gql`
  query getUsersInBranch {
    getUsersInBranch {
      id
      firstName
      lastName
      email
      phoneNumber {
        phone
        country
      }
    }
  }
`;
export const GET_VEHICLE_FOR_REPLACEMENT = gql`
  query getVehiclesForReplacement($make: String) {
    getVehiclesForReplacement(make: $make) {
      make
      model
      colour
      transmission
      licencePlate
    }
  }
`;

export const GET_ACTION_BY_ID = gql`
  query getActionById($id: String!) {
    getActionById(id: $id) {
      id
      category
      subCategory
      status
      dateCreated
      actionDate
      dateCompleted
      contentId
      description
      vehicle {
        licencePlate
        make
        model
        vin
        id
        colour
        transmission
      }
      booking {
        id
        businessCustomer {
          id
          businessName
          contact {
            firstName
            lastName
            phoneNumber {
              phone
              country
            }
            email
          }
        }
        referenceNumber
        customer {
          id
          firstName
          lastName
          email
          phoneNumber {
            phone
            country
          }
        }
        pickupServiceLocation {
          address {
            fullAddress
            street
            city
            state
            country
            zipcode
            geometry {
              type
              coordinates
              latitude
              longitude
            }
          }
        }
        dropoffServiceLocation {
          address {
            fullAddress
            street
            city
            state
            country
            zipcode
            geometry {
              type
              coordinates
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;

export const GET_JOB_COUNT = gql`
  {
    jobCount
  }
`;

export const GET_ASSIGNED_USER_JOBS = gql`
  query userJobDashboardData {
    userJobDashboardData {
      id
      subCategory
      jobDate
      status
      dateCreated
      description
      assignedTo {
        id
        firstName
        lastName
      }
      otherTaskItems
      vehicle {
        licencePlate
        id
      }
    }
  }
`;

export const GET_ALL_JOBS_FOR_PARTNER = gql`
  query getAllJobsForPartner($partnerId: String!, $limit: Int!, $offset: Int!) {
    getAllJobsForPartner(
      partnerId: $partnerId
      limit: $limit
      offset: $offset
    ) {
      id
      dateCreated
      jobDate
      status
      subCategory
      description
      assignedTo {
        id
        firstName
        lastName
        email
      }
      booking {
        id
        referenceNumber
        status
        bookingType
        pickupDateTime
        dropoffDateTime
        customer {
          firstName
          lastName
        }
        businessCustomer {
          businessName
        }
        branch {
          id
          timeZone
        }
        pickupOtherLocation {
          street
          city
          fullAddress
          state
          country
          zipcode
          location {
            type
            coordinates
            latitude
            longitude
          }
        }
        dropoffOtherLocation {
          city
          country
          fullAddress
          location {
            coordinates
            latitude
            longitude
            type
          }
          state
          street
          zipcode
        }
        dropoffServiceLocation {
          address {
            street
            city
            fullAddress
            state
            country
            zipcode
            geometry {
              type
              coordinates
              latitude
              longitude
            }
          }
        }
      }
      rating {
        knowledge
        behavior
        punctuality
      }
      vehicle {
        id
        licencePlate
      }
    }
  }
`;

export const GET_PARTNER_DRIVERS_IN_PARTNERSHIP = gql`
  query getPartnerDriversInPartnership {
    getPartnerDriversInPartnership {
      id
      partnerId
      firstName
      lastName
      email
      phoneNumber {
        phone
        country
      }
      license {
        licenseNumber
        dateOfExpiry
        dateOfIssue
        authority
        endorsement
        images
        offences
        points
        dvlaVerificationStatus
      }
      operatingAreas {
        id
        name
      }
      status
      skills
    }
  }
`;

export const GET_PARTNER_USER_IN_PARTNERSHIP = gql`
  query getPartnerUsersInPartnership {
    getPartnerUsersInPartnership {
      id
      partnerId
      firstName
      lastName
      email
      phoneNumber {
        phone
        country
      }
      partnerProfile {
        id
        branchId
        license {
        licenseNumber
        dateOfExpiry
        dateOfIssue
        authority
        endorsement
        images
        offences
        points
        dvlaVerificationStatus
      }
      }
    }
  }
`;

export const GET_AVAILABLE_PARTNERS_FOR_JOB = gql`
  query getAvailablePartnersForJob($partnerType: String!) {
    getAvailablePartnersForJob(partnerType: $partnerType) {
      id
      isActive
      partnerId
      partnerName
      partnerType
      address {
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      email
      VAT
      phoneNumber {
        phone
        country
      }
      companyRegistrationNo
      partnerContact {
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      billingContact {
        phoneNumber {
          phone
          country
        }
        email
      }
      documents {
        id
        documentKey
        documentName
        expiryDate
        documentType
        createdAt
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
