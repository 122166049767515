import { gql } from "@apollo/client";

export const GET_INSURANCE_POLICIES = gql`
  query insurancePolicies($organisationId: String!, $fetchActiveOnly: Boolean) {
    insurancePolicies(organisationId: $organisationId, fetchActiveOnly: $fetchActiveOnly) {
      id
      name
      excess
      organisationId
      insuranceRate {
        rateName
        rateDuration
        rate
        longTerm
      }
      isActivated
      description
    }
  }
`;
