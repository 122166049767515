import "mapbox-gl/dist/mapbox-gl.css";

import "./index.scss";

import { ApolloLink, ApolloProvider, HttpLink, from } from "@apollo/client";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { AppWithAuth } from "./AppWithAuth";
import HttpHeader from "./header";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import configStore from "./store"
import { updateAuthStateAction } from './actions/auth/actions';
import { AuthStates } from './reducers/auth/types';
import { captureErrorException, captureErrorMessage } from "./utils/sentry";
import { createBrowserHistory } from "history";

const store = configStore();

// Note Sentry cannot detect errors when adblock is being use without a tunnel https://docs.sentry.io/platforms/javascript/guides/react/troubleshooting/
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "develop",
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

const httpHeader = HttpHeader.getInstance();

const errorLink = onError(({ networkError, graphQLErrors }) => {

  if (graphQLErrors) {
    graphQLErrors.forEach(async ({ message, path, locations }) => {
      Sentry.captureMessage(message)
    })
  }

  if (networkError) {
    Sentry.captureException(networkError)
  }

  const unAuthenticated = graphQLErrors?.find((err) => err.extensions && err.extensions.code === "UNAUTHENTICATED");
  if (unAuthenticated) {
    httpHeader.resetHeaders();
    localStorage.clear();
    store.dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT, message: "Session expired, please sign in again" }))
    createBrowserHistory().push('/');
    window.location.reload();
  }
})
const httpLink = new HttpLink({ uri: process.env.REACT_APP_APOLLO_SERVER })

const client: any = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: from([errorLink, new ApolloLink((operation, forward) => {
    const context = operation.getContext();
    operation.setContext({
      ...context,
      headers: {
        ...context.headers,
        ...httpHeader.headers,
        authorization: `Bearer ${httpHeader.headers.authorization}`
      }
    });
    return forward(operation);
  }), httpLink]),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterLuxon} >
        <AppWithAuth />
      </LocalizationProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
